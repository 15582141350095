import React, { Component } from "react";
import {
  activeStepDataSelector
} from "../selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import "./styles.scss";

class AlreadyCompletedStep extends Component {
  render() { 
    const { activeStepData } = this.props

    return (
    <div className="already-completed-step">
      <p className="text-center">{activeStepData.app.specific_provider_name} transaction already completed</p>
    </div>);
  }
}

const mapStateToProps = createStructuredSelector({
  activeStepData: activeStepDataSelector(),
});

AlreadyCompletedStep = connect(mapStateToProps)(AlreadyCompletedStep);
export default AlreadyCompletedStep;

