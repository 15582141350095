import React, { Component, useState } from "react";
import { SortBtn } from "components/Controls";
import { ToggleSwitch } from "components/Controls";

import "./styles.scss";

class ModalSortBtns extends Component {
  state = {
    btn1: false,
    btn2: false,
    btn3: false,
  }

  constructor(props) {
    super(props);
  }

  togleActive = (key) => {
    let _btn = 'btn' + key;
    if (this.state[_btn] === false) {
      switch (key) {
        case 1:
          this.setState({ [_btn]: !this.state[_btn], btn2: false, btn3: false });
          break;
        case 2:
          this.setState({ [_btn]: !this.state[_btn], btn1: false, btn3: false });
          break;
        case 3:
          this.setState({ [_btn]: !this.state[_btn], btn1: false, btn2: false });
          break;
      }
    }
  }

  mapOrder(array, order, key) {
    array.sort(function (a, b) {
      let A = a[key], B = b[key];
      return ((order.indexOf(A) > order.indexOf(B)) ? 1 : -1)
    });
    return array;
  };

  dateConverting = (offer) => {
    if (offer.provider === 'flexshopper') {
      return offer.payment_period * 0.230137
    }
    return offer.payment_period
  }

  sortFunction(a, b, key) {
    if (key == 'payment_period') {
      return this.dateConverting(a) - this.dateConverting(b)
    }
    return a[key] - b[key]
  }

  sortByKey = (key, asc) => {
    const { offers, offersWithOptions } = this.props
    if (asc === true) {
      offersWithOptions.sort((a,b) => this.sortFunction(a, b, key));
    } else {
      offersWithOptions.sort((a, b) => this.sortFunction(b, a, key));
    }

    let sortedNames = offersWithOptions.map(a => a.specific_provider_name)
    this.mapOrder(offers, sortedNames, 'specific_provider_name')
    this.props.callbackOrderedOffers(offers);
  }

  compareOn = () => {
    this.props.callbackCompareToggle();
  }

  componentDidMount = () => {
    const el = document.querySelector(".step-sorting")
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(el);
    // observer to change background-color when the element becomes sticky to the top of the viewport.
  }

  render() {
    const { isCompareMode, logEvent } = this.props
    return (
      <div className="step-sorting">
        <div className="step-sorting__row">
          <div className="step-sorting__item">
            <div className="step-sorting-title">
              <div className="step-sorting-title__icon">
                <div className="line-top"></div>
                <div className="line-midle"></div>
                <div className="line-bottom"></div>
              </div>
            </div>
          </div>

          <div className="step-sorting__item">
            <SortBtn
              disabled={isCompareMode}
              onClick={() => { this.ascDt = !this.ascDt; this.sortByKey('initial_payment', this.ascDt); this.togleActive(1); logEvent({name: 'due today'})}}
              activate={this.state.btn1}
              asc={this.ascDt}>
              Due Today
            </SortBtn>
          </div>

          <div className="step-sorting__item">
            <SortBtn
              disabled={isCompareMode}
              onClick={() => { this.ascPp = !this.ascPp; this.sortByKey('payment_period', this.ascPp); this.togleActive(2); logEvent({name: 'term length'}) }}
              activate={this.state.btn2}
              asc={this.ascPp}>
              Term Length
            </SortBtn>
          </div>

          <div className="step-sorting__item">
            <SortBtn
              disabled={isCompareMode}
              onClick={() => { this.ascPpr = !this.ascPpr; this.sortByKey('payment_period_rate', this.ascPpr); this.togleActive(3); logEvent({name: 'recurring amount'}) }}
              activate={this.state.btn3}
              asc={this.ascPpr}>
              Recurring Amount
            </SortBtn>
          </div>
        </div>

        {/* TODO: uncomment this for comapre mode in new design */}
        {/* <div className="step-sorting__item step-sorting__item_last">
          <ToggleSwitch isOn={isCompareMode} onChange={this.compareOn} />
        </div> */}
      </div>
    )
  }
}

export default ModalSortBtns;
