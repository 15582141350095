import React from "react";
import { Helmet } from 'react-helmet';

const Submitted = () => {
  return (
    <Helmet>
      <script>{`gtag('event', 'conversion', {'send_to': 'AW-844077454/xFqyCKHZoHQQjrO-kgM'});`}</script>
      <script>{`fbq('track', 'Lead');`}</script>
    </Helmet>
  );
};

const Denial = () => {
  return (
    <Helmet>
      <script>{`gtag("event", "conversion", {"send_to": "AW-844077454/d5pqCO7luHQQjrO-kgM"});`}</script>
      <script>{`fbq("trackCustom", "Denial");`}</script>
    </Helmet>
  );
};

const Approval = ({spendingLimit}) => {
  return (
    <Helmet>
      <script>{`gtag('event', 'conversion', {'send_to': 'AW-844077454/mZWoCMGus3QQjrO-kgM'});`}</script>
      <script>{`fbq('trackCustom', 'Approval', {value: ${spendingLimit}, currency: 'USD'});`}</script>
    </Helmet>
  );
};

const CompletePurchase = ({orderNumber, orderTotal}) => {
  return (
    <Helmet>
      <script>
        {`
          gtag('event', 'conversion', {
            'send_to': 'AW-844077454/6OzjCPbXoHQQjrO-kgM',
            'value': ${orderTotal},
            'currency': 'USD',
            'transaction_id': '${orderNumber}'
          });
        `}
      </script>
      <script>
        {`
          gtag('event', 'conversion', {
            'send_to': 'AW-844077454/1IkCCM7Tv-sBEI6zvpID',
            'value': ${orderTotal},
            'currency': 'USD',
            'transaction_id': '${orderNumber}'
          });
        `}
      </script>
      <script>
        {`
          fbq('track', 'Purchase',
            {
              value: ${orderTotal},
              currency: 'USD'
            }
          );
        `}
      </script>
      <script>{`fbq('trackCustom', 'Acquisition');`}</script>
    </Helmet>
  )
}

export {
  Submitted,
  Denial,
  Approval,
  CompletePurchase
};
