import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import { loadingSelector, activeStepDataSelector } from "containers/MultistepForm/selectors";
import { selectOffer } from "containers/MultistepForm/actions";

import AdditionalInfoBlock from "components/DataDisplay/AdditionalInfoBlock"; 
import { selectedOfferSelector } from '../../../selectors';

import "./styles.scss";

class BankDataForm extends Component {

  state = {
    additionalData: {},
    valid: false
  }

  buildBodyToInitApplication = () => {
    const { additionalData } = this.state;  

    const applicationRequest = this.props.offer
    applicationRequest.has_additional_info = true;
    applicationRequest.additional_info_data = {};
    if(additionalData.routingNum && additionalData.accountNum ){
      applicationRequest.bank_data_is_required = false;
      applicationRequest.additional_info_data.bankAccount = {
        routing: additionalData.routingNum,
        account: additionalData.accountNum,
        type: 'checking'
      }
    }

    return applicationRequest;
  }
  
  submitForm = (e) => {
    const { valid } = this.state;
    e.preventDefault();
    if (valid) {
      this.props.selectOffer({selectedOffer: this.buildBodyToInitApplication()});
    }
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      valid: data.valid
    });
  }

  render() {
    const { loading, selectedOffer } = this.props;
    const { valid } = this.state;

    return (
      <div className="additional-info-step flex flex--column flex__align--center ">
        <div className="paypair-step-content">
          <div className="additional-info-step__form-block">
            <div className="additional-info-step__form-block-title">
              BANK ACCOUNT INFORMATION
            </div>
            <div className="additional-info-step__form-block-content">
            
              <AdditionalInfoBlock
                onDataChange={this.setAdditionalData}
                providerName={selectedOffer.specific_provider_name}
              />
            </div>
          </div>
       
          <BlueBtn  customClass="additional-info-step__submit-btn"
            disabled={!valid}
            loading={loading}
            onClick={this.submitForm}
          >
            {selectedOffer.specific_provider_name === 'snap' ? 'CONTINUE' : 'SUBMIT'}
          </BlueBtn>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: loadingSelector()(state),
  offer: activeStepDataSelector()(state),
  selectedOffer: selectedOfferSelector()(state)
});


const mapDistpatchToProps = dispatch => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data))
  };
};

BankDataForm = connect(
  mapStateToProps,
  mapDistpatchToProps
)(BankDataForm);

export default BankDataForm;
