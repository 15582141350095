import React from "react";

export const details = () => {
  return (
      {
        lease_to_own: {
          expandedValue: leaseToOwn()
        },
        payment: {
          expandedValue: payment()
        }
      }
  );
}

const leaseToOwn = () => {
  return (
    <div className="step-tooltip-block">
      <span className="font-bold">What Is Lease-to-Own Financing?</span>
      <br/>
      <br/>
      <span>Lease-to-own is an alternative method of financing. Snap buys the merchandise and leases it to you. Once you've fulfilled the
        terms of your agreement, the merchandise is yours.
      </span>
    </div>
  )
};

const payment = () => {
  return (
    <div className="step-tooltip-block">
      <span className="font-bold">Standard Lease Costs and Payments</span>
      <br/>
      <ul>
        <li>Our standard lease is a maximum of 12-18 months</li>
        <li>The lease includes a cost added to the cash price of the merchandise being leased, and your total
          cost could be more than double the cash price of the merchandise
        </li>
        <li>Your lease payments will be automatically deducted from the checking account provided in the lease
          application
        </li>
      </ul>
      <br/>
      <span className="font-bold">You Have Options With Snap</span>
      <br/>
      <ul>
        <li>You have options to gain ownership early and save on lease costs</li>
        <li>You may cancel your lease at any time by notifying and returning the merchandise to Snap</li>
      </ul>
    </div>
  );
};
