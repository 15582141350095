import { call } from 'api/base';

export const ApplicationRequestAPI = {
  init(token, data) {
    return call(`application_requests`, 'POST', token, data);
  },

  continue(token, request_id, data) {
    return call(`application_requests/${request_id}/continue`, 'POST', token, data);
  },

  update(token, request_id, data) {
    return call(`application_requests/${request_id}`, 'PATCH', token, data);
  },

  updateCompletedApplication(token, request_id, data) {
    return call(`application_requests/${request_id}/balance`, 'POST', token, data);
  },

  updateWithDiscount(token, request_id, data) {
    return call(`application_requests/${request_id}/update_with_discount`, 'POST', token, data);
  },

  koalafiCreate(token, data) {
    return call (`application_requests/koalafi_create`, 'POST', token, data)
  },

  discount(token, orderId, percentage) {
    return call (`application_requests/discount?order_id=${orderId}&discount=${percentage}`, 'POST', token, {})
  },

  update_cart(token, request_id, data) {
    return call (`application_requests/${request_id}/update_with_new_cart`, 'POST', token, data)
  },

  recommendations(token, orderId, approvalAmount) {
    return call (`application_requests/recommendations`, 'GET', token, {order_id: orderId, approved_amount: approvalAmount})
  },
  editCart(token, orderId, items) {
    return call (`application_requests/update_cart?order_id=${orderId}`, 'POST', token, items)
  }
}
