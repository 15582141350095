import React from "react";
import {
  TextFieldWithTooltip,
  DateField,
  SelectField,
  TextField,
} from "components/Fields";
import { STEP2__FEILDS } from "./constants";
import {
  ADDITIONAL_INFO_STEP__FEILDS,
  PAYOUT_FREQUENCY_TOOLTIP_TEXT,
  PAYOUT_FREQUENCY,
  PAYOUT_DAY,
  ID_STATE,
  MONTH,
  generateYearOptions,
} from "../constants/collections";
import moment from "moment";

export const renderField = ({ input, meta, label }) => {
  const field = input.name;
  return (
    <TextFieldWithTooltip
      tooltipType={STEP2__FEILDS[field].tooltipType}
      label={STEP2__FEILDS[field].label}
      placeholder={STEP2__FEILDS[field].placeholder}
      meta={meta}
      type="tel"
      {...input}
      tooltipText={STEP2__FEILDS[field].tooltipText}
      tooltipTitle={STEP2__FEILDS[field].tooltipTitle}
    />
  );
};

const handleChange = () => {
  if (this.props) {
    const { additionalData, onDataChange, valid } = this.props;
    onDataChange({
      data: additionalData,
      valid: valid,
    });
  }
};

export const renderYearField = ({ input, meta }) => {
  return <TextField placeholder={"YYYY"} meta={meta} type="tel" {...input} />;
};

export const renderYearSelectField = ({ input, meta, onChange }) => {
  const yearOptions = generateYearOptions();

  return (
    <SelectField
      skipBlur
      meta={meta}
      {...input}
      options={yearOptions}
      placeholder={"YYYY"}
    />
  );
};

export const renderMonthField = ({ input, meta, onChange }) => {
  return (
    <SelectField
      skipBlur
      meta={meta}
      {...input}
      options={MONTH}
      placeholder={"Month"}
    />
  );
};

export const renderDayField = ({ input, meta, onChange, ...rest }) => {
  const amountOfDays = moment(
    rest.year ? `${rest.year}-${rest.month}` : rest.month,
    rest.year ? "YYYY-MM" : "MM"
  ).daysInMonth();
  const daysData = new Array(amountOfDays || 0)
    .fill()
    .map((el, index, arr, dayNumber = index + 1) => ({
      label: dayNumber,
      value: dayNumber < 10 ? `0${dayNumber}` : String(dayNumber),
    }));
  return (
    <SelectField
      skipBlur
      meta={meta}
      {...input}
      options={daysData}
      placeholder={"Day"}
    />
  );
};

export const renderTextField = ({ input, meta, inputMode, autoComplete }) => {
  const field = input.name;
  return (
    <TextField
      type={"text"}
      label={ADDITIONAL_INFO_STEP__FEILDS[field].label}
      placeholder={ADDITIONAL_INFO_STEP__FEILDS[field].placeholder}
      meta={meta}
      customChange={handleChange}
      inputMode={inputMode}
      autoComplete={autoComplete}
      {...input}
    />
  );
};

export const getMaxDobDate = () => {
  const date = new Date();
  return date.setFullYear(date.getFullYear() - 18);
};

export const renderPayFrequencyField = ({ input, meta, label }) => {
  return (
    <SelectField
      meta={meta}
      {...input}
      options={PAYOUT_FREQUENCY}
      placeholder={"Please select"}
      tooltipType="icon"
      label={label}
      tooltipText={PAYOUT_FREQUENCY_TOOLTIP_TEXT}
    />
  );
};

export const renderStatesField = ({ input, meta }) => {
  return (
    <SelectField
      meta={meta}
      {...input}
      placeholder={""}
      options={ID_STATE}
      label="* State"
    />
  );
};

export const renderPayoutDayField = ({ input, meta, label }) => {
  return (
    <SelectField
      meta={meta}
      {...input}
      placeholder={"Please select"}
      options={PAYOUT_DAY}
      tooltipType="icon"
      label={label}
    />
  );
};

export const getLastPaycheckDateInt = (payoutFrequency, payoutDay) => {
  const date = moment();
  const maxDate = moment().valueOf();

  switch (payoutFrequency) {
    case "weekly":
      return {
        min: date.add(-6, "days").valueOf(),
        max: maxDate,
      };
    case "bi_weekly":
      return {
        min: date.add(-14, "days").valueOf(),
        max: maxDate,
      };
    case "semi_monthly":
      let minMaxDate = new Date().setDate(15);
      if (date.date() < 15) {
        if (payoutDay === "first") {
          minMaxDate = date.date(1).valueOf();
        } else {
          minMaxDate = date.add(-1, "months").endOf("month").valueOf();
        }
      }
      if (
        date.format("YYYY-MM-DD") ===
          date.endOf("month").format("YYYY-MM-DD") &&
        payoutDay === "last"
      ) {
        minMaxDate = date.valueOf();
      }
      return {
        min: minMaxDate,
        max: minMaxDate,
      };
    case "monthly":
      return {
        min: date.add(-1, "month").valueOf(),
        max: maxDate,
      };
    default:
      return {
        min: date.add(-1, "month").valueOf(),
        max: maxDate,
      };
  }
};

export const renderLastPaycheckField = ({
  input,
  meta,
  payoutFrequency,
  reset,
  payoutDay,
  label,
}) => {
  const interval = getLastPaycheckDateInt(payoutFrequency, payoutDay);
  return (
    <DateField
      label={label || "Last paycheck date"}
      {...input}
      meta={meta}
      minDate={interval.min}
      maxDate={interval.max}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      reset={reset}
    />
  );
};

export const getNextPaycheckDate = (
  payoutFrequency,
  lastPaycheck,
  payoutDay
) => {
  if (
    !payoutFrequency ||
    !lastPaycheck ||
    (payoutFrequency === "semi_monthly" && !payoutDay)
  ) {
    return;
  }

  const date = moment(lastPaycheck);
  const isLastDayOfMonth =
    date.format("YYYY-MM-DD") ===
    moment(lastPaycheck).endOf("month").format("YYYY-MM-DD");

  switch (payoutFrequency) {
    case "weekly":
      date.add(7, "days");
      break;
    case "bi_weekly":
      date.add(14, "days");
      break;
    case "semi_monthly":
      if (date.date() < 15) {
        date.date(15);
      } else {
        payoutDay === "first"
          ? date.add(1, "months").date(1)
          : date.endOf("month");
        if (isLastDayOfMonth) {
          date.add(1, "months").date(15);
        }
      }
      break;
    case "monthly":
      const endOfMonth = moment(lastPaycheck)
        .endOf("month")
        .format("YYYY-MM-DD");

      date.add(1, "months");
      if (date.format("YYYY-MM-DD") === endOfMonth) {
        date.endOf("month");
      }
      break;
    default:
      return null;
  }
  console.log(`Next paycheck date: ${date.format("YYYY-MM-DD")}`);
  return date.format("YYYY-MM-DD");
};

export const renderBirthDateField = ({ input, meta, label }) => {
  const maxDate = getMaxDobDate();
  const monthYearProps = birthDateFieldSetting();
  return (
    <DateField
      maxDate={maxDate}
      {...monthYearProps}
      label={label ? label : ADDITIONAL_INFO_STEP__FEILDS[input.name].label}
      {...input}
      meta={meta}
    />
  );
};

const birthDateFieldSetting = () => {
  const [isYearSelected, setIsYearSelected] = React.useState(false);
  const [isMonthSelected, setIsMonthSelected] = React.useState(false);

  if (!isYearSelected) {
    return {
      onChange: undefined,
      showYearPicker: true,
      scrollableYearDropdown: true,
      onSelect: () => {
        setIsYearSelected(true);
      },
      onCalendarClose: () => {
        setIsYearSelected(false);
        setIsMonthSelected(false);
      },
      shouldCloseOnSelect: false,
    };
  } else if (!isMonthSelected) {
    return {
      showMonthYearPicker: true,
      onChange: undefined,
      customClass: "hide-date-nav",
      onSelect: () => {
        setIsMonthSelected(true);
      },
      onCalendarClose: () => {
        setIsYearSelected(false);
        setIsMonthSelected(false);
      },
      shouldCloseOnSelect: false,
    };
  } else {
    return {
      onChange: undefined,
      showYearDropdown: true,
      showMonthDropdown: true,
      customClass: "hide-date-nav",
      onSelect: () => {
        setIsYearSelected(false);
        setIsMonthSelected(false);
      },
      onCalendarClose: () => {
        setIsYearSelected(false);
        setIsMonthSelected(false);
      },
    };
  }
};
