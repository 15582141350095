import React from "react";
import './styles.scss'
import { IconClose, IconValid } from "components/Svgs";
import { Spinner } from "components/DataDisplay";

const ValidationIcon = ({  touched, asyncValidating = false, ...props }) => {
  if (asyncValidating) {
    return <Spinner  className="validation-icon"/>
  }
  if (touched && props.valid === true) {
    return (<IconValid
      className="validation-icon"
      style={{ width: 18, height: 16 }}
    />)
  }
  if (touched && props.valid === false) {
    return (
      <IconClose
        className="validation-icon validation-icon--invalid "
        style={{ width: 14, height: 14 }}
      />
    );
  } 
  return null;
}



export default ValidationIcon;
