import React, { Component } from "react";
import { change, Field, reduxForm } from "redux-form";
import { TextFieldWithTooltip } from "components/Fields";
import { CustomTooltip } from "components/DataDisplay";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import {
  SCORE_TOOLTIP_TEXT,
  PHONE_FIELD_TOOLTIP_TEXT,
  FAST_ANSWER_TOOLTIP_TEXT,
} from "./constants";
import { required, isPhoneNumEqual10 } from "utils/validations";
import { normalizePhone, onlyNumbers } from "utils/normalize";
import { connect } from "react-redux";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import {
  subscribingDataSelector,
  affiliatedProviderSelector,
  trendingsVisitID,
  currentMerchant,
} from "containers/MultistepForm/selectors";

import { UsersAPI } from "api/users";
import {processAuthUserResponse, setIovation} from '../actions';
import PayTomorrowMetrix from "./PayTomorrowMetrix";
import FlexshopperMetrix from "./FlexshopperMetrix";
import SnapSessionId from "./SnapSessionId";
import UownMetric from "./UownMetric";
import Iovation from "./Iovation";
import { formValueSelector } from "redux-form";
import { runIovationScript } from "utils/blackbox";
import { AnalyticAPI } from "api/analytic";
import "./styles.scss";
import CardsIcon from "assets/img/cards.svg";
import ClockIcon from "assets/img/clock.svg";
import LockIcon from "assets/img/lock.svg";
import CodeVerification from "../CodeVerification";
import RightArrow from "assets/img/icon-arrow-right.svg";
import {logEvent} from '../../EmbeddableWidget/actions';

const selector = formValueSelector("form");

class Step1 extends Component {
  state = {
    loading: false,
    showPasscodeField: false,
    passcodeError: false,
    phoneError: false,
    phone: "",
  };

  initUser = (params) => {
    const { token, visitID } = this.props;

    AnalyticAPI.send_analytic_event(token, "phone_number", visitID);

    return UsersAPI.send_code(token, params).then((data) => {
      this.setState({ loading: false });
      if (data.status !== "ok") {
        this.setState({
          phoneError: "Please specify a valid phone number",
        });
        return;
      }

      this.setState({ showPasscodeField: true });
      if (
        process.env.REACT_APP_PAYPAIR_ENV === "production" ||
        process.env.REACT_APP_PAYPAIR_ENV === "sandbox"
      ) {
        this.setUserVarsAndLog({"phoneNumber_str": params.phone})
      }
    });
  };

  setUserVarsAndLog = (data) => {
    if (window.FS) {
      FS.setUserVars(data);
      FS.log(data);
    } else {
      console.error('FullStory is not loaded yet.');
    }
  }

  showPasscodeField = () => {
    this.setState({ showPasscodeField: !this.state.showPasscodeField });
  };

  renderPhoneField = ({ input, meta, merchant }) => {
    return (
      <TextFieldWithTooltip
        tooltipType="icon"
        label="* Mobile Phone Number"
        placeholder="(123) 456-7890"
        meta={meta}
        type="tel"
        {...input}
        tooltipText={PHONE_FIELD_TOOLTIP_TEXT(merchant)}
      />
    );
  };

  getBlackBoxId = () => {
    runIovationScript().then((blackboxId) => {
      const { setIovation } = this.props;
      setIovation(blackboxId);
    });
  };

  submitForm = (values) => {
    const { affiliatedProvider, logEvent } = this.props;

    this.setState({ loading: true });
    this.setState({ phone: values.phone });

    this.getBlackBoxId();
    logEvent({name: 'submit phone num'})
    return this.initUser({
      phone: values.phone && onlyNumbers(values.phone),
      provider_name: affiliatedProvider,
    });
  };

  resendCode = () => {
    const { affiliatedProvider } = this.props;
    this.setState({ loading: true });

    return this.initUser({
      phone: onlyNumbers(this.state.phone),
      provider_name: affiliatedProvider,
    });
  };

  renderAdvantages = () => {
    return (
      <div className="tree-items_block">
        <div className="advantages-item">
          <div className="advantages-item__icon">
            <img src={ClockIcon} />
          </div>
          <div className="advantages-item__content flex flex--column">
            <div className="advantages-item__title">Fast Answer</div>
            <div className="advantages-item__text">
              Provide a few details and get a decision quickly.
              <span className="score-icon-additionals">
                <CustomTooltip text={FAST_ANSWER_TOOLTIP_TEXT} />
              </span>
            </div>
          </div>
        </div>

        <div className="advantages-item advantages-item--score">
          <div className="advantages-item__icon lock-icon">
            <img src={LockIcon} />
          </div>
          <div className="advantages-item__content flex flex--column">
            <div className="advantages-item__title">
              No Credit Score Requirement
            </div>
            <div className="advantages-item__text">
              Some of our partners look at other factors beyond credit score to
              make approval determinations.
              <span className="score-icon-additionals">
                <CustomTooltip text={SCORE_TOOLTIP_TEXT} />
              </span>
            </div>
          </div>
        </div>

        <div className="advantages-item">
          <div className="advantages-item__icon">
            <img src={CardsIcon} />
          </div>
          <div className="advantages-item__content flex flex--column">
            <div className="advantages-item__title">Multiple Options</div>
            <div className="advantages-item__text">
              With multiple payment providers, we’ll find a solution for you.
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAgreedCheckbox = ({ input, meta }) => {
    return <CustomCheckbox meta={meta} {...input} />;
  };

  changePhoneEvent = () => {
    this.setState({ phoneError: false });
  };
  changePasscodeEvent = () => {
    this.setState({ passcodeError: false });
  };

  renderForm = () => {
    const { valid, handleSubmit, merchant } = this.props;
    const { loading, phoneError } = this.state;

    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        className="step1__form paypair-form"
      >
        <div className="phone-item">
          <Field
            name="phone"
            validate={[required, isPhoneNumEqual10]}
            component={this.renderPhoneField}
            normalize={normalizePhone}
            onChange={this.changePhoneEvent}
            merchant={merchant}
          />
          {phoneError && (
            <div className="field-error phone-error">{phoneError}</div>
          )}
        </div>

        {/* <div className="agreemnet-block">

            <Field
              name="isAgreed"
              component={this.renderAgreedCheckbox}
            />
            <div
              className="agreemnet-block__label"
            >
              <span>
                {"By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) as well as automated finance and lease approval information from Tire Agent at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency leties. Msg & data rates may apply. "}
                View <a href="http://attn.tv/tireagent/terms.html" target="_blank" >Terms</a> & <a href="https://www.tireagent.com/privacy" target="_blank" >Privacy</a>.</span>
            </div>

        </div> */}

        <BlueBtn
          loading={loading}
          disabled={!valid}
          customClass="custom-height-button"
          type="submit"
        >
          Continue{" "}
          <img
            src={RightArrow}
            className="icon-arrow-right"
            alt="right-arrow"
          />
        </BlueBtn>
        <div className="sms-description">
          We'll send you a verification code via SMS
        </div>
      </form>
    );
  };

  render() {
    const { showPasscodeField, phone } = this.state;

    return (
      <div className="step1">
        {!showPasscodeField ? (
          <div>
            <div className="step1-title-payment">Purchase Options, Made Easy</div>
            <h2 className="title2 step1-title2_text">
              Simply enter your mobile phone number below to start your
              application.
            </h2>
            <div className="step1-form-advantages">{this.renderForm()}</div>
            <div>{this.renderAdvantages()}</div>
          </div>
        ) : (
          <CodeVerification
            phone={phone}
            resendCode={() => {
              this.resendCode();
            }}
            showPasscodeField={() => {
              this.showPasscodeField();
            }}
          />
        )}
        <PayTomorrowMetrix />
        <FlexshopperMetrix />
        <SnapSessionId />
        <UownMetric />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state),
    visitID: trendingsVisitID()(state),
    isAgreed: selector(state, "isAgreed"),
    subscribingData: subscribingDataSelector()(state),
    affiliatedProvider: affiliatedProviderSelector()(state),
    merchant: currentMerchant()(state),
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    processAuthUserResponse: (value) =>
      dispatch(processAuthUserResponse(value)),
    setIovation: (value) => dispatch(setIovation(value)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

Step1 = connect(mapStateToProps, mapDistpatchToProps)(Step1);

export default reduxForm({
  form: "form",
  initialValues: { isAgreed: false },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Step1);
