/* eslint-disable no-unused-expressions */
import React from "react";

const IconValid = props => (
  <svg {...props} viewBox="0 0 18 13">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#64C57D"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 1L6 12 1 7"
    />
  </svg>
);

export default IconValid;
