import React, { Component} from "react";
import { Field, formValueSelector  } from "redux-form";
import PersonalInfoIcon from 'assets/img/personal-info-icon.png'
import { required, isNumEqual9, validate, validateEmail} from "utils/validations";
import { renderField, renderStatesField, renderTextField} from "containers/MultistepForm/Step2/input_helpers"
import { normalizeSNN } from "utils/normalize";
import { PERSONAL_INFO_TITLE, REQUIRED_FIELD_TITLE } from 'containers/MultistepForm/Step2/constants'
import { formValuesSelector } from "containers/MultistepForm/selectors.js";
import { BlueBtn } from "components/Controls";
import { connect } from 'react-redux'
import { reduxForm } from "redux-form";
import DateOfBirth from "components/Fields/DateOfBirth";
const selector = formValueSelector('form')
import RightArrow from 'assets/img/icon-arrow-right.svg';

class PersonalInfoForm extends Component {

  onSubmit = () => {
    const { handleBoxClose, financialInfoBoxHandlerOpen } = this.props
    financialInfoBoxHandlerOpen();
    handleBoxClose();
  }

  render() {
    const { valid, month, day, year } = this.props
    return (
      <div>
        <div className="personal-info-string">
          <div className="personal-info-2">
            <img className="personal-info-icon" src={PersonalInfoIcon}/>
          </div>
          <div className="personal-info"><strong>{PERSONAL_INFO_TITLE}</strong></div>
        </div>
        <div className="require-text">{REQUIRED_FIELD_TITLE}</div>
        <div className="step2__form paypair-form">
          <div className="form-item-group">
            <div className="form-item form-item--first-name">
              <Field
                name="firstName"
                normalize={null}
                validate={[required]}
                component={renderTextField}
              />
            </div>
            <div className="form-item form-item--last-name">
              <Field
                name="lastName"
                normalize={null}
                validate={[required]}
                component={renderTextField}
              />
            </div>
          </div>
          <div className="form-item-group">
            <div className="form-item form-item--ssn">
              <Field
                name="ssn"
                validate={[required, isNumEqual9]}
                component={renderField}
                normalize={normalizeSNN}
                label={"* SSN / ITIN"}
              />
            </div>
            <div className="form-item form-item--last-name">
              <Field
                name="email"
                normalize={null}
                validate={[required, validateEmail]}           
                component={renderTextField}
              />
            </div>
          </div>
          <div className="form-item-birthday">
            <div className="prequlification form-item date-of-birthday">
              <DateOfBirth
                month={month}
                day={day}
                year={year}
                resetDay={()=>this.props.change("day", '')}
              />
            </div>
          </div>
          <div className="form-item form-item--street-address">
            <Field
              name='streetAddress'
              normilize={null}
              validate={[required]}
              component={renderTextField}
            />
          </div>
          <div className="form-item-group">
            <div className="form-item form-item--city">
              <Field
                name='city'
                normilize={null}
                validate={[required]}
                component={renderTextField}
              />
            </div>
            <div className="form-item-group triple">
              <div className="form-item form-item--state">
                <Field
                  name='state'
                  normilize={null}
                  validate={[required]}
                  component={renderStatesField}
                />
              </div>
              <div className="form-item form-item--zip-code">
                <Field
                  name='zipCode'
                  normilize={null}
                  validate={[required]}
                  component={renderTextField}
                />
              </div>
            </div>
          </div>
        </div>
          <div className="paypair-form">
            <div className="submit-btn form-item-group">
              <div className="form-item form-item--state">
                <div className="personal-info-button">
                  <BlueBtn
                    customClass="step2__submit-btn"
                    type="submit"
                    disabled={!valid}
                    onClick={this.onSubmit}
                  >
                    Continue <img src={RightArrow} className="right-arrow"/>
                  </BlueBtn>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  values: formValuesSelector()(state),
  month: selector(state, 'month'),
  day: selector(state, 'day'),
  year: selector(state, 'year')
});

PersonalInfoForm = connect(mapStateToProps, null)(PersonalInfoForm)

PersonalInfoForm = reduxForm({
  validate,
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PersonalInfoForm);

export default PersonalInfoForm;
