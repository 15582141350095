import React, { Component } from "react";
import { connect } from "react-redux";
import { finishApplication, cancelApplication } from "../../actions";
import PropTypes from "prop-types";
import { closeModal } from "containers/MultistepModal/actions";
import "./styles.scss";

class OkinusContract extends Component {
  componentDidMount() {
    const { selectedOffer, finishApplication } = this.props;

    const okinus = Okinus({
      baseUri: process.env.OKINUS_BASE_URL,
      retailerSlug: process.env.OKINUS_STORE_SLUG,
      storeSlug: process.env.OKINUS_RETAILER_SLUG,
    });

    okinus.checkout(selectedOffer.contractUrl, function (payload) {
      if (payload.status == "success") {
        finishApplication();
      }
    });

    const iframe = document.getElementById("OkinusModal").outerHTML;
    document.getElementById("OkinusModal").remove();

    const div = document.getElementById("okinus-iframe");
    div.insertAdjacentHTML("afterbegin", iframe);
  }

  render() {
    const { hidden } = this.props;

    return (
      <div className="iframe-container" style={{display: hidden ? 'none' : 'inherit'}}>
        <div className="scrolldown_request">Please scroll down to continue</div>
        <div id="okinus-iframe"></div>
      </div>
    );
  }
}

OkinusContract.propTypes = {
  contractUrl: PropTypes.string,
};

OkinusContract.defaultProps = {
  contractUrl: "",
};

const mapDistpatchToProps = (dispatch) => {
  return {
    finishApplication: (values) => dispatch(finishApplication(values)),
    cancelApplication: (values) => dispatch(cancelApplication(values)),
    closeModal: () => dispatch(closeModal()),
  };
};

OkinusContract = connect(null, mapDistpatchToProps)(OkinusContract);

export default OkinusContract;
