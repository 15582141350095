import { ofType, combineEpics } from "redux-observable";
import { withLatestFrom, flatMap, catchError } from "rxjs/operators";
import { AnalyticAPI } from "api/analytic";
import * as EventTypes from "./eventTypes";

import * as modalActions from "containers/MultistepModal/actions";
import * as formActions from "containers/MultistepForm/actions";
import { MerchantsAPI } from "../../api/merchants";
import { from, of, merge } from "rxjs";
import * as ApplicationRequestActions from "../MultistepForm/actions";
import { ProvidersApi } from "../../api/providers";

const initWidget = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.INIT_WIDGET),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      if (state.widget.inIframe) {
        window.parent.postMessage({ type: EventTypes.INIT_WIDGET_DONE }, "*");
      }
      return [];
    })
  );

const openWidget = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.OPEN_WIDGET),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      return from(MerchantsAPI.current(state.widget.key)).pipe(
        flatMap((data) => {
          AnalyticAPI.send_analytic_event(
            state.widget.key,
            "applications",
            action.payload.visitId
          );

          return from(ProvidersApi.active(state.widget.key)).pipe(
            flatMap((activeProviders) => {
              const actions = [
                formActions.initUserData({
                  ...action.payload,
                  merchant: data.merchant_name || "Tire Agent",
                  activeProviders: activeProviders || [],
                  multipleIframeEnabled: !!data.multiple_iframes,
                }),
                modalActions.openModal(),
              ];

              if (activeProviders.includes("aff")) {
                const affAgreementsObservable = from(
                  ProvidersApi.affAgreements(state.widget.key)
                ).pipe(
                  flatMap((affAgreements) =>
                    of(
                      ApplicationRequestActions.addAffAgreements(affAgreements && Object.keys(affAgreements).length ? affAgreements : null)
                    )
                  ),
                  catchError((error) => {
                    console.error("Error fetching affAgreements:", error);
                    return of();
                  })
                );

                return merge(of(...actions), affAgreementsObservable);
              }

              return of(...actions);
            }),
            catchError(() =>
              of(
                formActions.initUserData({
                  ...action.payload,
                  merchant: data.merchant_name || "Tire Agent",
                  activeProviders: [],
                }),
                modalActions.openModal()
              )
            )
          );
        }),
        catchError((err) => {
          console.error(err);
          return [];
        })
      );
    })
  );

const finishFlow = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.FINISH_FLOW),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      if (state.widget.inIframe) {
        window.parent.postMessage(
          {
            type: EventTypes.FINISH_FLOW,
            data: {
              provider: state.leaseForm.selectedOffer.specific_provider_name,
              app_id: state.leaseForm.selectedOffer.app_id,
              state: state.leaseForm.applicationRequest.residenceInfo.state,
              zip_code: state.leaseForm.applicationRequest.residenceInfo.zip,
            },
          },
          "*"
        );
      }
      return [];
    })
  );

const logEvent = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.LOG_EVENT),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      if (state.widget.inIframe) {
        window.parent.postMessage(
          {
            type: EventTypes.LOG_EVENT,
            data: {
              eventName: action.payload.name,
              payload: action.payload.payload || null
            },
          },
          "*"
        );
      }
      return [];
    })
  );

const cancelFlow = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.CANCEL_FLOW),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      if (state.widget.inIframe) {
        window.parent.postMessage(
          { type: EventTypes.CANCEL_FLOW, data: {} },
          "*"
        );
      }
      return [];
    })
  );

const closeWidget = (action$, state$) =>
  action$.pipe(
    ofType(EventTypes.CLOSE_WIDGET),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      return of(modalActions.closeModal());
    })
  );

const sendMessage = (action$, state$) =>
  action$.pipe(
    ofType("SEND_MESSAGE"),
    withLatestFrom(state$),
    flatMap(([action, state]) => {
      if (state.widget.inIframe) {
        window.parent.postMessage({ type: action.payload.type }, "*");
      }
      return [];
    })
  );

export default combineEpics(
  initWidget,
  openWidget,
  finishFlow,
  cancelFlow,
  closeWidget,
  sendMessage,
  logEvent
);
