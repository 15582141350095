import React from "react";
import PropTypes from "prop-types";
import { CustomRadioGroup } from "components/Controls";
import "./styles.scss";

const RadioField = ({ label, ...props }) => {
  return (
    <div className="radio-field-component flex flex--column">
      <label className="flex flex__align--baseline font-uppercased radio-field-label">
        <span> {label}</span>
      </label>
      <div className="radio-field-wrapper">
        <CustomRadioGroup 
          {...props}
        />
      </div>
    </div>
  );
};

RadioField.propTypes = {
  label: PropTypes.string
};

RadioField.defaultProps = {
  label: ''
};

export default RadioField;
