import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

import { IconInfo } from "components/Svgs";

const ExpandBtn = ({ disabled, isExpanded, customClass, ...props }) => (
  <button
    disabled={disabled}
    type="button"
    className={classNames(
      "button flex-inline flex__justify--center",
      "button-expand",
      `${customClass}`,
      {
        expanded: isExpanded
      }
    )}
    {...props}
  >
    {props.children}
    <IconInfo className="icon" />
  </button>
);

ExpandBtn.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired
};

export default ExpandBtn;
