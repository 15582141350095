/* eslint-disable no-unused-expressions */
import React from "react";

const IconDocument = props => (
  <svg {...props} width="24" height="33" viewBox="0 0 24 33">
    <path
      fill="currentColor"
      d="M18 18.903v1.616c0 .38-.337.692-.75.692H6.75c-.412 0-.75-.312-.75-.692v-1.616c0-.38.338-.692.75-.692h10.5c.413 0 .75.311.75.692zm-.75 3.808H6.75c-.412 0-.75.311-.75.692v1.616c0 .38.338.692.75.692h10.5c.413 0 .75-.312.75-.692v-1.616c0-.38-.337-.692-.75-.692zM24 8.5v21.405C24 31.614 22.656 33 21 33H3c-1.656 0-3-1.386-3-3.094V3.094C0 1.386 1.344 0 3 0h12.756c.794 0 1.556.329 2.119.909l5.244 5.407c.562.574.881 1.367.881 2.185zm-8.503-6.213v6.724h6.372l-6.372-6.724zm6.242 28.517V11.344H14.5c-.831 0-1.5-.69-1.5-1.547V2.229H2.356v28.576h19.383z"
    />
  </svg>
);

export default IconDocument;
