import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ADDITIONAL_INFO_STEP__FEILDS } from 'containers/MultistepForm/constants/collections';
import { onlyNumbers } from "utils/normalize";
import { Field, reduxForm } from "redux-form";
import { CustomCheckbox } from "components/Controls";
import  ArbitrationAgreement  from './ArbitrationAgreement';
import Modal from "@mui/material/Modal";
import { IconBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import {
  isLessThan20digits,
  required,
  accountNumMustMatch
} from "utils/validations";
import { TextField } from "components/Fields";
import AccountNumberTooltip from "../AccountNumberTooltip";
import { formValueSelector } from 'redux-form'
const selector = formValueSelector('additional_info_form')
import { CustomTooltip } from "components/DataDisplay";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import URLS from "utils/import_urls";

import "./styles.scss";

import asyncValidate from './asyncValidate'
import {affiliatedProviderSelector, selectedOfferSelector} from "../../../containers/MultistepForm/selectors";

class AdditionalInfoBlock extends PureComponent {

  state = {
    isAgreementOpen: false,
  };

  reviewAgreement = (e) => {
    e.preventDefault();
    this.setState({ isAgreementOpen: true });
  };

  handleCloseAgreement = () => {
    this.setState({
      isAgreementOpen: false,
    });

  }


  handleChange = () => {
    const { additionalData, onDataChange, valid } = this.props || {}
    onDataChange({
      data: additionalData,
      valid: valid
    });
  }

  renderField = ({ input, meta }) => {
    const field = input.name;
    return (
      <TextField
        type={"text"}
        label={ADDITIONAL_INFO_STEP__FEILDS[field].label}
        placeholder={ADDITIONAL_INFO_STEP__FEILDS[field].placeholder}
        meta={meta}
        customChange={this.handleChange}
        {...input}
      />
    );
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        customChange={this.handleChange}
        {...input}
      />
    );
  };



  renderPrivacyPolicy = () => {
    return (
      <div className="privacy-policy additional-info-block">
        <div className="privacy-policy-text">
          By checking here and clicking "Submit", you agree that FlexShopper may
          obtain information about you, including information from your bank and/or a consumer
          reporting agency, and you agree to be legally bound by the terms of our&nbsp;
          <a href="#" className="link"  onClick={this.reviewAgreement}>
            Arbitration Agreement
          </a>,&nbsp;
          <a href={URLS.DISCLOSURE_FS} className="link" target="_blank">
            ESIGN Disclosure
          </a>,&nbsp;
          <a href={URLS.TERMS_FS} className="link" target="_blank">
            Terms of Use
          </a> and&nbsp;
          <a href={URLS.PRIVACY_FS} className="link" target="_blank">
            Privacy Policy
          </a>.
          Even if you do not check the box, by installing, accessing, or using any
          part of this website or our mobile application, you agree to be legally
          bound by our Terms of Use.


        </div>
        <div className="privacy-policy-agreement flex flex__align--center">
          {/* <CustomCheckbox handleChange={this.checkedAgreement} /> */}
          <Field
            name="isAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />

          <div className="privacy-policy-agreement__label">
            I have read and agree to the disclosure above.
          </div>
        </div>
      </div>
    );
  };


  renderModalContent = () => {

    return (
      <React.Fragment>
        <IconBtn
          customClass="multistep-modal__close-btn"
          onClick={this.handleCloseAgreement}
        >
          <IconClose style={{ width: 14, height: 14 }} />
        </IconBtn>

        <ArbitrationAgreement/>

        <div className="multistep-modal-footer flex flex__align--baseline flex__justify--end">
          <div className="multistep-modal-footer__icon">

          </div>

          <span className="font-italic multistep-modal-footer__text">
          </span>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { isAgreementOpen } = this.state;
    const { providerName } = this.props

    return (
        <div className="paypair-form paypair-form-padding additional-info-block">
          <div className="form-item-group">
            <div className="form-item ">
              <Field
                name="routingNum"
                normalize={onlyNumbers}
                validate={[required]}
                component={this.renderField}
              />
            </div>
            <div className="form-item">
              <Field
                name="accountNum"
                normalize={onlyNumbers}
                validate={[isLessThan20digits,required]}
                component={this.renderField}
              />
            </div>
          </div>
          <div className="form-item-group">
            <div className="form-item account-num-item">
              <Field
                name="confirmAccountNum"
                normalize={onlyNumbers}
                validate={[isLessThan20digits,required, accountNumMustMatch]}
                component={this.renderField}
              />
              <CustomTooltip
                title="Where can I find my account numbers?"
                text={<AccountNumberTooltip />}
              />
            </div>
          </div>
          <Modal
            className="multistep-modal"
            aria-labelledby="multistep-modal-title"
            aria-describedby="multistep-modal-description"
            open={isAgreementOpen}
            onClose={this.handleCloseAgreement}
          >
            <div className="multistep-modal__wrapper">
              {this.renderModalContent()}
            </div>
          </Modal>

          {providerName !== 'snap' && this.renderPrivacyPolicy()}
        </div>
    );
  };
}

AdditionalInfoBlock.propTypes = {
  onDataChange: PropTypes.func
};

AdditionalInfoBlock.defaultProps = {
  onDataChange: () => {}
};

AdditionalInfoBlock = reduxForm({
  form: "additional_info_form",
  initialValues: { isAgreementChecked: false },
  asyncValidate,
  asyncChangeFields: ['routingNum'],
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(AdditionalInfoBlock);

AdditionalInfoBlock = connect(
  state => ({
    additionalData: selector(state, 'routingNum', 'accountNum', 'isAgreementChecked'),
    token: tokenSelector()(state),
    selectedOffer: selectedOfferSelector()(state),
  })
)(AdditionalInfoBlock);



export default AdditionalInfoBlock;
