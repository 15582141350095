
import * as ModalActionTypes from '../MultistepModal/actionTypes';
import { ActionTypes } from './actionTypes';
import { mergeDeepRight } from 'ramda';

const cleanOnClose = (state, action) => {
  switch(action.type) {
    case ModalActionTypes.CLOSE_MODAL:
      return undefined;
    default:
      return state;
  }
}

const formReducer = (state, action) => {
  switch(action.type) {
    case ModalActionTypes.CLOSE_MODAL:
      return undefined;
    case ActionTypes.authUserFailed:
      return mergeDeepRight(state, {
        values: { phone: '' }
      });
    case ActionTypes.initUser:
      return mergeDeepRight(state, {
        values: { otp_code: '' }
      });
    default:
      return state;
  }
};


export default {
  form: formReducer,
  additional_info_form: cleanOnClose,
  confirmation_step_form: cleanOnClose,
  verification_question_form: cleanOnClose,
  flexshopper_contract_form: cleanOnClose,
  flexshopper_offer_form: cleanOnClose,
  flexshopper_auth_form: cleanOnClose,
  snap_contract_form: cleanOnClose,
  snap_offer_form: cleanOnClose,
  koalafi_contract_form: cleanOnClose,
  ownlease_offer_form: cleanOnClose,
  bank_data_form: cleanOnClose,
  card_data_form: cleanOnClose,
  agreement_data_form: cleanOnClose,
}
