import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { confirmOffer, nextStep } from "../../actions";
import { required } from "utils/validations";
import { Field, reduxForm } from 'redux-form';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { ProvidersApi } from 'api/providers';
import {activeStepDataSelector, currentMerchant, userInfoSelector} from '../../selectors';
import URLS from "utils/import_urls";
import "./styles.scss";

class SnapConfirmation extends Component {
  state = {
    loading: false,
    phoneOptIn: false,
    acceptance: false
  }

  submitForm = () => {
    const { nextStep, token } = this.props;

    this.setState({ loading: true });
    return ProvidersApi.updateAgreement(token, this.buildUpdateApplicationData()).then(agreement => {
      this.setState({loading: false});
      return nextStep({step: 'snapTotalInfo'});
    })
  };

  buildUpdateApplicationData = () => {
    const { data } = this.props

    return {
      lease_app_id: data.lease_app_id,
      request_id: data.app.request_id,
      customerInformation: {
        phoneOptIn: this.state.phoneOptIn
      },
      termsConditions: {
        acceptance: this.state.acceptance
      }
    };
  }

  changePhoneEvent = (e) => {
    this.setState({ [e]: !this.state[e] })
  }

  renderTermsAndConditions = () => {
    const { merchant, userInfo } = this.props;
    const formattedPhone = `(${userInfo.phone.substring(0, 3)}) ${userInfo.phone.substring(3, 6)}-${userInfo.phone.substring(6, 10)}`;

    return (
      <div className="agreemnet-block snap-color">
        <div className="flex flex__align--bottom">
        <Field
            name="isSnapAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
            onChange={() => this.changePhoneEvent('acceptance')}
          />
          <div className="agreemnet-block__label ">
            <span>
              I have read, understand and agree to the Snap <a href={URLS.PRIVACY_POLICY_SNAP} target="_blank">Privacy Policy</a> and
              the <a href={URLS.TERMS_AND_CONDITIONS_SNAP} target="_blank">Application Terms and Conditions</a>, including the
              <a href={URLS.CONSENT_TO_ELECTRONIC_COMMUNICATION_SNAP} target="_blank">Consent to Electronic Transactions</a>, as well as
              the <a href={URLS.JURY_TRIAL_SNAP} target="_blank">Jury Trial Waiver and Arbitration Clause</a>,if applicable.
              I authorize Snap Finance LLC and/or it's affiliates ("Snap") to obtain consumer reports and other information
              about me from consumer reporting agencies and third parties and to use that information for any lawful purpose
            </span>
          </div>
        </div>
        <br/>

        <div className="flex flex__align--center">
          <Field
            name="isGenesisAgreementChecked"
            component={this.renderAgreementCheckbox}
            onChange={() => this.changePhoneEvent('phoneOptIn')}
          />
          <div className="agreemnet-block__label ">
            <span>
              {`I would like to receive text messages containing offers and updates about Snap's financial products at the
              telephone number provided ${formattedPhone}. Message frequency will vary. Message and data rates may apply.
              Reply HELP for help or STOP to cancel. Snap and ${merchant} may use an automated system to send text messages 
              and make marketing calls to the telephone number provided and that I am not required to agree to these terms
              in order to obtain any services from Snap. `}
              <a href={URLS.SMS_TERMS_SNAP} target="_blank">Snap SMS Terms of Service</a>
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        {...input}
      />
    );
  };

  render() {
    const {valid} = this.props;
    const { loading } = this.state

    return (
      <div className="genesis_confirmation_step step5__content flex--column ">
        <div className="flex flex--column">
          <h2 className="subtitle font-left snap-align">
            Greate job! You're almost done.
          </h2>
          <br/>
          {this.renderTermsAndConditions()}
        </div>
        <BlueBtn
          customClass="step5__submit-btn"
          disabled={!valid}
          loading={loading}
          onClick={this.submitForm}
        >
          SUBMIT
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: activeStepDataSelector()(state),
  token: tokenSelector()(state),
  merchant: currentMerchant()(state),
  userInfo: userInfoSelector()(state),
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    finishApplication: values => dispatch(finishApplication(values)),
    nextStep: value => dispatch(nextStep(value))
  };
};

SnapConfirmation = connect(mapStateToProps, mapDistpatchToProps)(SnapConfirmation);
SnapConfirmation = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(SnapConfirmation);

export default SnapConfirmation;
