import React from "react";
import { Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { IconInfo } from "components/Svgs";
import Fade from "@mui/material/Fade";

import "./styles.scss";

const CustomTooltip = ({ text, title, titleBold }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderTitle = () => {
    if (title) {
      return titleBold ? (
        <strong className="title">{title}</strong>
      ) : (
        <span className="title font-bold">{title}</span>
      );
    } else {
      return <IconInfo className="icon" />;
    }
  };

  return (
    <div className="custom-tooltip">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          arrow
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={text}
        >
          <div className="icon-correct" onClick={handleTooltipOpen}>
            {renderTitle()}
          </div>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
};

export default CustomTooltip;
