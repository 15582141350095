import { mergeDeepRight, merge } from "ramda";
import { ActionTypes } from "./actionTypes";
import {
  applicationResponseStub,
  appRequest,
  greenWaveSelectedOffer,
  genesisSelectedOffer,
  flexshopperSelectedOffer,
  paytomorrowSelectedOffer,
  snapSelectedOffer,
} from "utils/stubData";
import * as ModalActionTypes from "../MultistepModal/actionTypes";
import { addAffAgreements } from "./actions";

const applicationResponseDs = {
  request_id: null,
  amount: 0,
  offers: [],
  recommendations: [],
};

const defaultState = {
  // CHANGE FOR DESIGN
  activeStep: "form1",
  // activeStep: 'form3',
  merchant: "",
  activeProviders: [],
  affAgreements: null,
  activeStepData: {},
  applicationRequest: {
    personalInfo: {
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      dob: null,
      ssn: null,
    },
    consents: {
      agreeTcpa: false,
    },
    residenceInfo: {
      street: null,
      country: null,
      city: null,
      state: null,
      zip: null,
    },
    visitId: null,
    orderId: null,
    promoApplied: false,
    maxApprove: 0,
    shipping: 0,
    provider_name: "anybody",
    items: [],
    has_additional_info: false,
    additional_info_data: {},
    employmentInformation: {
      payFrequency: null,
      monthlyIncome: 0,
      last_payday_on: null,
      next_payday_on: null,
      direct_deposit: true,
    },
  },
  requestId: null,
  metrixSessionId: null,
  blackboxId: null,
  // CHANGE FOR DESIGN
  applicationResponse: applicationResponseDs,
  // applicationResponse: applicationResponseStub,
  selectedOffer: null,
  selectedOfferSigned: false,
  loading: false,
  closing: false,
  discountProviders: [],
  cartModalOpen: false,
  cartModalLoading: false,
  recommendationsApplied: false,
  allSelectedOffers: [],
};

export default (state = defaultState, action) => {
  let activeStep = null;
  switch (action.type) {
    case ModalActionTypes.CLOSE_MODAL:
      return mergeDeepRight(state, {
        loading: false,
        selectedOffer: null,
        applicationResponse: null,
        requestId: null,
        applicationRequest: action.payload,
        selectedOfferSigned: false,
        activeStep: "form1",
        allSelectedOffers: [],
      });
    case ActionTypes.setMetrixSession:
      return mergeDeepRight(state, {
        metrixSessionId: action.payload,
      });
    case ActionTypes.setPayTomorrowMetrix:
      return mergeDeepRight(state, {
        threatMetrixPayTomorrowSessionId: action.payload,
      });
    case ActionTypes.setSnapSessionId:
      return mergeDeepRight(state, {
        snapSessionId: action.payload,
      });
    case ActionTypes.setUownSession:
      return mergeDeepRight(state, {
        uownSessionId: action.payload,
      });
    case ActionTypes.setIovation:
      return mergeDeepRight(state, {
        blackboxId: action.payload,
      });
    case ActionTypes.addAffAgreements:
      return { ...state, affAgreements: action.payload };
    case ActionTypes.initUserData:
      return mergeDeepRight(state, {
        loading: false,
        // CHANGE FOR DESIGN
        merchant: action.payload.merchant,
        // merchant: 'TireAgent',
        activeProviders: action.payload.activeProviders,
        // activeProviders: ['aff'],
        selectedOffer: null,
        // CHANGE FOR DESIGN
        applicationResponse: applicationResponseDs,
        // applicationResponse: applicationResponseStub,
        requestId: null,
        applicationRequest: mergeDeepRight(
          defaultState.applicationRequest,
          action.payload
        ), //appRequest
        selectedOfferSigned: false,
        // CHANGE FOR DESIGN
        activeStep: "form1",
        // activeStep: 'form3',
        recommendationsApplied: false,
      });

    case ActionTypes.initApplication:
    case ActionTypes.continueInitApplication:
      return mergeDeepRight(state, {
        loading: true,
        applicationRequest: action.payload.values,
        activeStep: "loader",
      });
    case ActionTypes.initApplicationFailed:
      return mergeDeepRight(state, {
        loading: false,
        activeStep: "nobodyApprovedStep",
        applicationResponse: null,
      });

    case ActionTypes.initApplicationSuccess:
      return mergeDeepRight(state, {
        loading: false,
        applicationResponse: action.payload.data,
        requestId: action.payload.data.request_id,
        selectedOffer: null,
      });
    case ActionTypes.setMaxApprove:
      return mergeDeepRight(state, {
        maxApprove: action.payload,
      });
    case ActionTypes.applyDiscount:
      return mergeDeepRight(state, {
        applicationResponse: action.payload.data,
        requestId: action.payload.data.request_id,
      });
    case ActionTypes.updateWithDiscount:
      return mergeDeepRight(state, {
        applicationRequest: { promoApplied: true },
        discountProviders: action.payload.data,
      });
    case ActionTypes.updateCart:
      return mergeDeepRight(state, {
        loading: true,
        activeStep: "loader",
        applicationRequest: {
          items: action.payload,
        },
      });
    case ActionTypes.editCart:
      return mergeDeepRight(state, {
        cartModalLoading: true,
      });
    case ActionTypes.toggleCartModal:
      return mergeDeepRight(state, {
        cartModalLoading: false,
        cartModalOpen:
          action.payload !== undefined ? action.payload : !state.cartModalOpen,
      });
    case ActionTypes.continueInitApplicationSuccess:
      const providersToUpdate = action.payload.data.offers.map(
        (offer) => offer.provider
      );
      const keepPrevOffers = state.applicationResponse.offers.filter(
        (offer) => providersToUpdate.indexOf(offer.provider) == -1
      );
      const mergedOffers = keepPrevOffers.concat(action.payload.data.offers);
      return mergeDeepRight(state, {
        loading: false,
        applicationResponse: {
          additionals_provided: action.payload.data.additionals_provided,
          offers: mergedOffers,
        },
        selectedOffer: null,
      });
    case ActionTypes.setSelectedOffer:
      return mergeDeepRight(state, {
        selectedOffer: action.payload,
      });
    case ActionTypes.selectOffer:
      if (action.payload.selectedOffer) {
        return mergeDeepRight(state, {
          selectedOffer: action.payload.selectedOffer,
          loading: true,
        });
      }
    case ActionTypes.confirmOffer:
      return mergeDeepRight(state, {
        loading: true,
      });
    case ActionTypes.startClosingModalWithLoader:
      return mergeDeepRight(state, {
        closing: true,
      });
    case ActionTypes.finishClosingModalAndRemoveLoader:
      return mergeDeepRight(state, {
        closing: false,
      });
    // contract can come(flexshopper), so merge it into selectedOffer
    case ActionTypes.selectOfferSuccess:
      return mergeDeepRight(state, {
        selectedOffer: action.payload.selectedOffer,
        loading: false,
      });

    case ActionTypes.confirmOfferSuccess:
      return mergeDeepRight(state, {
        selectedOffer: action.payload,
        loading: false,
      });

    case ActionTypes.cancelApplication:
      return mergeDeepRight(state, {});
    case ActionTypes.backToOffers:
      return mergeDeepRight(state, {
        activeStep: "form3",
        selectedOffer: null,
        activeStepData: null,
      });

    case ActionTypes.finishApplicationSuccess:
      return mergeDeepRight(state, {
        selectedOfferSigned: true,
        loading: false,
      });
    case ActionTypes.selectOfferFailed:
      if (
        action.payload &&
        action.payload.error_form &&
        action.payload.error_form === "wrong_data"
      ) {
        return mergeDeepRight(state, {
          activeStep: "errorStep",
          activeStepData: {
            message:
              "There was an error submitting your shopping cart. Please try again or contact customer service.",
          },
          loading: false,
        });
      }
      if (state.selectedOffer.provider === "flexshopper") {
        return mergeDeepRight(state, {
          activeStep: "errorStep",
          activeStepData: action.payload || {
            message:
              "There was an error creating your FlexShopper lease agreement, please try to check out with PayPair again",
          },
          loading: false,
        });
      } else {
        return mergeDeepRight(state, {
          activeStep: "nobodyApprovedStep",
          loading: false,
        });
      }
    case ActionTypes.confirmOfferFailed:
    case ActionTypes.finishApplicationFailed:
    case ActionTypes.signApplicationFailed:
      return mergeDeepRight(state, {
        activeStep: "nobodyApprovedStep",
        loading: false,
      });
    case ActionTypes.processAuthUserResponse:
      return mergeDeepRight(state, {
        requestId: action.payload.response.request
          ? action.payload.response.request.id
          : null,
        applicationRequest: {
          personalInfo: {
            phone: action.payload.request.phone,
          },
        },
      });
    case ActionTypes.nextStep:
      const isContractStep = action.payload.step === "contractStep";
      const isSuccessStep = action.payload.step === "successStep";

      let updatedOffers = isSuccessStep ? [] : state.allSelectedOffers;
      if (state.applicationRequest.multipleIframeEnabled && isContractStep) {
        const offerExists = state.allSelectedOffers.some(
          (offer) => offer.app_id === state.selectedOffer.app_id
        );
        if (!offerExists) {
          updatedOffers = [...state.allSelectedOffers, state.selectedOffer];
        }
      }

      return mergeDeepRight(state, {
        activeStep: action.payload.step,
        activeStepData: action.payload.data || {},
        loading: false,
        allSelectedOffers: updatedOffers,
      });
    case ActionTypes.updateApplication:
      return mergeDeepRight(state, {
        applicationResponse: { updated: true },
      });
    case ActionTypes.getRecommendations:
      return mergeDeepRight(state, {
        recommendationsApplied: true,
      });
    case ActionTypes.setRecommendations:
      // CHANGE FOR DESIGN
      return mergeDeepRight(state, {
        applicationResponse: { recommendations: action.payload },
      });
    // return mergeDeepRight(state, {
    //   loading: false,
    // });
    case ActionTypes.providerFailed:
      const offer = action.offer;
      const applicationResponse = state.applicationResponse;
      const index = applicationResponse.offers.indexOf(offer);
      if (index !== -1) {
        applicationResponse.offers.splice(index, 1);
      }
      let step = state.activeStep;
      if (applicationResponse.offers.length === 0) {
        step = "nobodyApprovedStep";
      }
      return mergeDeepRight(state, {
        applicationResponse: applicationResponse,
        activeStep: step,
      });
    default:
      return mergeDeepRight(state, {});
  }
};
