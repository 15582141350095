import React, { Component } from "react";
import PropTypes from "prop-types";
import Offer from "./Offer";

class SnapOffer extends Component {
  render() {
    const { offer, available, isExpanded, logContinueEvent } = this.props;
    return <Offer logContinueEvent={logContinueEvent} offer={offer} available={available} isExpanded={isExpanded} getSelectedOffer={this.props.getSelectedOffer}/>;
  }
}

SnapOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

SnapOffer.defaultProps = {
  available: true
};

export default SnapOffer;
