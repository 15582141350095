import { mergeDeepRight } from 'ramda';
import * as ActionTypes from './actionTypes';

const defaultState = {
  modalOpened: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: 
      return mergeDeepRight(state, { modalOpened: true });
    case ActionTypes.CLOSE_MODAL: 
      return mergeDeepRight(state, { modalOpened: false });
    default:
      return mergeDeepRight(state, {});
  }
}
