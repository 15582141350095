import React from "react";
import { formatDate, parseDate } from "utils/helpers";
import { Field } from "redux-form";

const CustomDateField = ({
  ...props
}) => { 
  return (
    <Field 
    {...props}  
    format={formatDate}
    parse={parseDate}
  />
  )
}

export default CustomDateField;
