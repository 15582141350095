import React, { Component } from "react";
import "./styles.scss";

class ConfirmationInfo extends Component {

  render() {
    return (
      <div className="confirmation_info">
        By clicking the “Submit” below, you confirm you are applying for a Genesis Credit®Account issued by First Electronic Bank  
        (“FEB”) and you acknowledge, agree to, and/or represent the following:
        <li>The information you have provided as part of this application is true and correct;</li>
        <li>
          FEB may gather information about you, including from credit reporting agencies and other 
          sources, to verify your identity and determine your eligibility for credit, renewal of credit, and 
          future extensions of credit. If you ask, we will tell you whether or not we requested a credit 
          report and the names and addresses of any credit reporting agency that provided us with such credit report.
        </li>
        <li>
          FEB may share your information, except for social security number, even if you are declined, 
          with participating retailers that accept this Account. If you are approved, FEB may also share 
          experience and transaction information with them about your Account. To stop FEB from 
          sharing information about you or your Account with the retailer for marketing purposes, 
          please call Genesis FS Card Services at 1-800-303-8341; and
        </li>
        <li>
          You have read and agree to the credit terms and other disclosures with this application, and 
          you understand that if your application is approved, the complete Account Agreement 
          (“Agreement”) will be sent to you and will govern your Account. Among other things, the 
          Agreement includes an arbitration provision that limits your rights unless you reject <strong><u>the 
          provision by following the provision’s instructions or if you are a Covered Borrower 
          entitled to protection under the Federal Military Lending Act.</u></strong> Please see the “Military 
          Lending Act” section of the Terms and Conditions and Important Disclosures.
        </li>
      </div>
    );
  }
}


export default ConfirmationInfo;
