import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BlueBtn } from 'components/Controls';
import { loadingSelector, activeStepDataSelector } from 'containers/MultistepForm/selectors';
import { selectOffer } from 'containers/MultistepForm/actions';

import { selectedOfferSelector } from '../../../selectors';
import ProgressiveLogo from 'assets/img/progressive-logo.png';

import Form from './Form';

class BankDataForm extends Component {

  state = {
    additionalData: {},
    valid: false
  }

  buildBodyToInitApplication = () => {
    const { additionalData } = this.state;

    const applicationRequest = this.props.offer
    applicationRequest.has_additional_info = true;
    applicationRequest.additional_info_data = {};
    if(additionalData.routingNum && additionalData.accountNum ){
      applicationRequest.bank_data_is_required = false;
      applicationRequest.additional_info_data.bankAccount = {
        routing: additionalData.routingNum,
        account: additionalData.accountNum,
        type: 'checking'
      }
    }

    return applicationRequest;
  }

  submitForm = (e) => {
    const { valid } = this.state;
    e.preventDefault();
    if (valid) {
      this.props.selectOffer({selectedOffer: this.buildBodyToInitApplication()});
    }
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      valid: data.valid
    });
  }

  render() {
    const { loading, selectedOffer } = this.props;
    const { valid } = this.state;

    return (
      <div className="expanded-block-component bank-data-form-container">
        <div className="bank-data-form-header">
          <img src={ProgressiveLogo} alt="Progressive Logo" />
          <h1>Apply for Lease-to-Own</h1>
          <h2>Bank Account Information</h2>
        </div>
        <p>We will use your checking account information to help determine your eligibility for a lease.</p>
        <Form
          onDataChange={this.setAdditionalData}
          providerName={selectedOffer.specific_provider_name}
        />
        <BlueBtn
          customClass="bank-data-form-submit-btn"
          disabled={!valid}
          loading={loading}
          onClick={this.submitForm}
        >
          Continue
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: loadingSelector()(state),
  offer: activeStepDataSelector()(state),
  selectedOffer: selectedOfferSelector()(state)
});

const mapDispatchToProps = dispatch => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDataForm);
