import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import CompareUnselected from "assets/img/compare.svg";
import CompareSelected from "assets/img/compare-selected.svg";
import "./styles.scss";

class Compare extends Component {

  render() {
    const { selectedForCompare } = this.props;

    return (
      <div
        className={classNames("compare-button",
          {
            selectedForCompare: selectedForCompare
          })}
      >
        <div className="compare-button__text font-bold">
          COMPARE
        </div>

        <img src={selectedForCompare ? CompareSelected : CompareUnselected} alt={`Logo`} />
      </div>
    );
  }
}

Compare.propTypes = {
  selectedForCompare: PropTypes.object.isRequired
};

Compare.defaultProps = {
  selectedForCompare: false
};

export default Compare;
