/* eslint-disable no-unused-expressions */
import React from "react";

const IconCheck = props => (
  <svg {...props} viewBox="0 0 512 512" fill="currentColor">
    <path d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path>
  </svg>
);

export default IconCheck;
