import React, { Component } from "react";
import { connect } from "react-redux";
import { selectOffer } from "containers/MultistepForm/actions";
import { mergeRight } from "ramda";
import PropTypes from "prop-types";
import { BlueBtn } from "components/Controls";
import { reduxForm } from "redux-form";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import { nextStep } from "../../../actions";
import { affiliatedProviderSelector, trendingsVisitID } from '../../../selectors';
import { createStructuredSelector } from 'reselect';
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import OfferHeader from "../../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { addThousandsSeparator, paymentFrequency } from "utils/helpers";
import { snapDetails } from "./snap_details";
import "./styles.scss";
import Moment from 'moment'

class Offer extends Component {
  state = {
    loading: false,
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: offer.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
      bank_data_is_required: offer.bank_data_is_required
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (e) => {
    const { selectOffer, offer, token, visitID, logContinueEvent } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', offer.specific_provider_name, visitID);
    logContinueEvent()
    this.setState({ loading: true });
    selectOffer({ selectedOffer: offer });
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        {...input}
      />
    );
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__justify--center flex__align--center">
          <div className="agreemnet-block__label">
            <span>Once you're received the merchandise, you can exercise one of these options, please go to customer.snapfinance.com or call (877) 557-3769</span>
          </div>
        </div>
      </div>
    );
  };

  renderEarlyOptions = (data) => {
    const { cpoAmount, cpoDate } = data?.paymentPlanSummary || {};
    return (
      <div>
        <span class='options-header'>Early Ownership Options</span>
        <BuyoutInfo
          title={'100-Day Option'}
          text={`Pay off your account by ${ Moment(cpoDate).format('LL') } and only pay $${ cpoAmount }.`}
        />
        <div className='second-buyoutinfo'>
          <BuyoutInfo
            title={'Early Buyout Option'}
            text={`Pay off your account early any time after ${ Moment(cpoDate).format('LL') } and save.`}
          />
        </div>
      </div>
    )
  }

  render() {
    const { loading, selectedOption } = this.state;
    const { available, isExpanded, offer } = this.props;
    if (!selectedOption) {
      return '';
    }
    const paymentPeriod = available ? `${selectedOption.payment_period} Months` : ''
    const dueToday = selectedOption.initial_payment ? addThousandsSeparator(selectedOption.initial_payment) : 0
    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={dueToday}
          specificProviderName={selectedOption.provider}
          approvedAmount={offer.approved_amount}
          paymentFrequency={paymentFrequency(selectedOption)}
          paymentPeriod={paymentPeriod}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form
            className="snap-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption, snapDetails())} />
            </div>

            {this.renderEarlyOptions(selectedOption.row_data)}

            {this.renderLeaseAgreement()}
            <BlueBtn
              onClick={this.submitForm}
              customClass="step3__recommended-content-btn"
              disabled={!(available)}
              loading={loading}
              type="submit"
            >
              Continue with Snap
            </BlueBtn>
          </form>)}
      </div>
    );
  }
}


Offer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool
};

Offer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  providerName: affiliatedProviderSelector(),
  visitID: trendingsVisitID()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value))
  };
};

Offer = connect(mapStateToProps, mapDistpatchToProps)(Offer);


Offer = reduxForm({
  form: "snap_offer_form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Offer);

export default Offer;
