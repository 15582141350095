import React, { PureComponent } from "react";
import { BlueBtn } from "components/Controls";
import CompareIcon from './compare_icon'
import "./styles.scss";

class Row extends PureComponent {
  renderHeader = () => {
    const { data } = this.props

    return (
      <div className="table-row table-row_header">
        <div className="table-title">
        </div>
        {data["content"].map((row) => (
          <div className="table-value">
            <div className="table-logo">
              <CompareIcon
                provider={row.provider}
              />
            </div>
            <BlueBtn
              onClick={() => this.props.selectOffer(row.provider)}
            >
              SELECT
            </BlueBtn>
            <div className="table-text">{row.title}</div>
            <div className="table-price font-bold">{row.value}</div>
          </div>
        ))}
      </div>
    )
  }

  renderTable = () => {
    const { data } = this.props

    if (data.key == 'header') {
      return (
        this.renderHeader()
      )
    }
    return (
      <div className="table-row">
        <div className="table-title">
          {data["content"][0].title}
        </div>
        {data["content"].map((row, index) => (
          <div key={index} className="table-value">
            {row.value}
          </div>
        ))}
      </div>
    )
  }

  render() {
    return (
      this.renderTable()
    );
  }
}

export default Row;
