import React, { Component, Fragment } from "react";
import IconInfoRecommendations from "assets/img/IconInfoRecommendations.svg";
import { BlueBtn, IconBtn, SelectQuantity } from "components/Controls";
import { addThousandsSeparator } from "utils/helpers";
import MileWarranty from "assets/img/mile_warranty.svg";
import FreeAssistance from "assets/img/free_assistance.svg";
import FreeProtection from "assets/img/free_protection.svg";
import FuelEfficient from "assets/img/fuel_efficient.svg";
import FlatTire from "assets/img/flat_tire.svg";

import "./styles.scss";
import { IconClose } from "../../Svgs";
import Modal from "@mui/material/Modal";
import { Spinner } from "../index";

const icons = {
  mile_warranty: MileWarranty,
  free_assistance: FreeAssistance,
  free_protection: FreeProtection,
  fuel_efficient: FuelEfficient,
  flat_tire: FlatTire,
};

class Recommendations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllRecommendations: false,
      lineItems: this.props.lineItems,
      isModalOpen: false,
      selectedRecommendation: null,
      loading: false,
    };
  }

  toggleModal = (item = null) => {
    const { logEvent } = this.props;

    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      selectedRecommendation: item,
    }));
    logEvent({name: 'add_replace product'})
  };

  confirmAddAndReplace = () => {
    const { selectedRecommendation } = this.state;
    const { logEvent } = this.props;

    if (selectedRecommendation) {
      this.addItem(selectedRecommendation);
      this.setState({ loading: true });
    }
    logEvent({name: 'confirm replacement'})
  };

  addItem = (recItem) => {
    const { editCart, itemId, quantity, discountRequired } = this.props || {};
    const { lineItems } = this.state;

    const updatedItems = lineItems.reduce((acc, item) => {
      if (item.id && item.quantity) {
        acc.push({
          id: item.id,
          quantity: item.quantity,
          ...(itemId === item.id && { _destroy: true }),
        });
      }
      return acc;
    }, []);

    const updated = [
      ...updatedItems,
      { variant_id: recItem.variant_id, quantity: quantity },
    ];

    editCart({ items: updated, discount_required: discountRequired});
  };

  toggleRecommendationsVisibility = () => {
    this.setState({
      showAllRecommendations: !this.state.showAllRecommendations,
    });
  };

  taxSummary = () => {
    const { lineItems } = this.props;

    if (lineItems.length > 0) {
      return lineItems
        .map((i) => i.taxAmount * i.quantity)
        .reduce((a, b) => a + b);
    }
  };

  recommendationsInfo = () => {
    const { totalPrice, maxApprovalAmount } = this.props;
    return (
      <div className="recommendations-info-block">
        <div>
          <div className="recommendations-info-icon inline-block">
            <img className="icon" src={IconInfoRecommendations} />
          </div>
          <div className="recommendations-info-text inline-block">
            <div className="mob-info-text">
              <strong>
                Your cart total of $
                {addThousandsSeparator(totalPrice + this.taxSummary())} is not
                fully covered under a payment plan.
              </strong>
            </div>
            <br />
            <div>
              Your max approval amount is:{" "}
              <strong>${addThousandsSeparator(maxApprovalAmount)}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  goodNews = () => {
    return (
      <div>
        <div className="text-center good-news-title">
          <strong className="title3">But, Good News!</strong>
        </div>
        <div className="text-center good-news-text">
          <span>
            We found similar tires that fit within your max approval amount. See
            below for recommended tire options or remove items from your cart to
            complete your order.
          </span>
        </div>
      </div>
    );
  };

  additionalBenefits = (item) => {
    return (
      <div className="additional-benefits-container">
        {item?.additional_benefits &&
          Object.keys(item?.additional_benefits).length > 0 &&
          Object.entries(item?.additional_benefits).map(([key, label]) => {
            const IconComponent = icons[key];
            return (
              label && (
                <div key={key} className="additional-benefits">
                  {IconComponent && (
                    <img
                      className="additional-benefits-icon"
                      src={IconComponent}
                      alt={`${label} icon`}
                    />
                  )}
                  <p className="additional-benefits-label">{label}</p>
                </div>
              )
            );
          })}
      </div>
    );
  };

  render() {
    const { recommendations, quantity = 4 } = this.props || {};
    const { showAllRecommendations, isModalOpen, loading } = this.state;

    // const changeItemQuantity = (item, quantity) => {
    //   item.quantity = quantity;
    //   console.log("updated quantity: ", quantity);
    // };

    return (
      <div className="recommendations-block">
        {this.recommendationsInfo()}
        {this.goodNews()}

        <div className="block-component">
          <div className="text-center full-cart-title">
            <strong>Approved for Full Cart Total</strong>
          </div>
          {recommendations
            ?.slice(0, showAllRecommendations ? recommendations?.length : 3)
            .map((item) => (
              <div
                key={item?.model}
                className="expanded-block-component expanded"
              >
                <div className="flex-row">
                  <div className="_inline-block">
                    <div className="tire-image">
                      <img className="tire-img" src={item?.image} />
                    </div>
                  </div>
                  <div className="mobile">
                    <div className="inline-block">
                      <div className="tire-info">
                        <div className="tire-name">{item?.brand}</div>
                        <div className="tire-model">{item?.model}</div>
                        <div className="tire-performance">
                          {`${item?.specifications?.tread_type} ${item?.specifications?.road_condition}`}
                        </div>
                        <div className="tire-size">
                          {item?.specifications?.size_desc}
                        </div>
                      </div>
                      <div className="tire-total">
                        <div className="select-qnt">
                          <SelectQuantity
                            item={item || {}}
                            name="quantity"
                            isStaggered
                            selectedQuantity={`Qty: ${quantity}`}
                            // onSelect={changeItemQuantity}
                            disabled
                          />
                        </div>
                        <div className="recommendation-total-lable">
                          New Cart Total:
                        </div>
                        <div className="recommendation-total-price">
                          ${addThousandsSeparator(quantity * +item?.price)}
                        </div>
                        {this.additionalBenefits(item)}
                      </div>
                      {/*<div className="tire-link">*/}
                      {/*  <a href="#">Why Buy This Tire?</a>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <div className="desktop">
                    <div className="flex-column">
                      <div className="tire-info">
                        <div className="tire-name">{item?.brand}</div>
                        <div className="tire-model">{item?.model}</div>
                        <div className="tire-performance">
                          {`${item?.specifications?.tread_type} ${item?.specifications?.road_condition}`}
                        </div>
                        <div className="tire-size">
                          {item?.specifications?.size_desc}
                        </div>
                      </div>
                      <div className="line-info">
                        <div>
                          <div className="recommendation-total-lable">
                            New Cart Total:
                          </div>
                          <div className="recommendation-total-price">
                            ${addThousandsSeparator(quantity * +item?.price)}
                          </div>
                        </div>
                      </div>
                      {/*<div className="tire-link">*/}
                      {/*  <a href="#">Why Buy This Tire?</a>*/}
                      {/*</div>*/}
                    </div>
                    <div className="flex-column">
                      <div className="tire-total">
                        <div className="select-qnt">
                          <SelectQuantity
                            item={item}
                            name="quantity"
                            isStaggered
                            selectedQuantity={`Qty: ${quantity}`}
                            // onSelect={changeItemQuantity}
                            disabled
                          />
                        </div>
                        {this.additionalBenefits(item)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center grey-info">
                  <span className="text-grey">
                    By clicking the 'ADD AND REPLACE' button below, you will
                    replace your current tire selection with ({quantity}){" "}
                    {item?.brand + " " + item?.model} tires.
                  </span>
                  <br />
                </div>
                <div>
                  <BlueBtn
                    customClass={"btn-green"}
                    isExpanded={true}
                    onClick={() => this.toggleModal(item)}
                  >
                    ADD AND REPLACE
                  </BlueBtn>
                </div>
              </div>
            ))}
        </div>
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={isModalOpen}
          onClose={this.toggleModal}
        >
          <div className="multistep-modal__wrapper confirm-wrapper">
            <IconBtn
              customClass="multistep-modal__close-btn"
              onClick={this.toggleModal}
            >
              <IconClose style={{ width: 14, height: 14 }} />
            </IconBtn>

            <p className="multistep-modal__title">
              Are you sure you want to replace your current selection?
            </p>
            {loading && <Spinner className={"loader"} />}

            <div className="multistep-modal-footer flex flex__align--center flex__justify--around">
              <BlueBtn
                customClass="multistep-modal__button"
                disabled={loading}
                onClick={this.toggleModal}
              >
                Cancel
              </BlueBtn>
              <BlueBtn
                customClass="multistep-modal__button"
                disabled={loading}
                onClick={this.confirmAddAndReplace}
              >
                Add and Replace
              </BlueBtn>
            </div>
          </div>
        </Modal>

        {recommendations?.length > 3 && (
          <div className="flex-row flex__justify--center">
            <BlueBtn
              onClick={this.toggleRecommendationsVisibility}
              isExpanded={true}
            >
              {showAllRecommendations
                ? "SHOW LESS"
                : "SHOW ALL RECOMMENDATIONS"}
            </BlueBtn>
          </div>
        )}
      </div>
    );
  }
}

export default Recommendations;
