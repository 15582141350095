import { UsersAPI } from "api/users";
import { BlueBtn } from "components/Controls";
import VerificationCode from "components/CustomFields/VerificationCode";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { onlyNumbers } from "utils/normalize";
import { isCodeEqual6, required } from "utils/validations";
import { processAuthUserResponse } from "../actions";
import RightArrow from 'assets/img/icon-arrow-right.svg';
import {logEvent} from '../../EmbeddableWidget/actions';

class CodeVerification extends Component {
  state = {
    loading: false,
    passcodeError: false,
    phoneError: false,
    phone: ''
  };

  authUser = (params) => {
    const { token, processAuthUserResponse} = this.props;
    return UsersAPI.auth(token, params).then(data => {
      if (data.status !== 'ok') {
        this.setState({
          loading: false,
          passcodeError: this.formatOtpError(data.error)
        });
        return
      }

      processAuthUserResponse({request: params, response: data});
    })
  }

  submitForm = (values) => {
    const { affiliatedProvider, logEvent } = this.props;
    this.setState({loading: true});
    this.setState({phone: values.phone});

    logEvent({name: 'submit verification'})
    return this.authUser({
      phone:  values.phone && onlyNumbers(values.phone),
      otp_code: values.passcode && onlyNumbers(values.passcode),
      provider_name: affiliatedProvider
    });
  };

  formatOtpError = (error) => {
    switch (error) {
      case "Incorrect OTP!":
        return "Incorrect code. Try again";
      default:
        return error;
    }
  };

  renderCodeField = ({ input, meta }) => {
    return (
      <VerificationCode
        resend={false}
        meta={meta}
        {...input}
        trigger={true}
      />
    );
  };

  changePasscodeEvent = () => {
    this.setState({passcodeError: false})
  }


  renderForm = () => {
    const { valid, handleSubmit } = this.props;
    const { loading, passcodeError} = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitForm)} className="step1__form paypair-form">
        <div>
          <Field
            name="passcode"
            validate={[required, isCodeEqual6]}
            component={this.renderCodeField}
            onChange={this.changePasscodeEvent}
          />
          {passcodeError && <div className="field-error passcode-error">{passcodeError}</div>}
        </div>

        <BlueBtn
          loading={loading}
          disabled={!valid}
          customClass="custom-height-button"
          type="submit">
          Continue <img src={RightArrow} className="icon-arrow-right" alt="right-arrow" />
        </BlueBtn>
      </form>
    );
  };

  render() {
    const { phone, resendCode, showPasscodeField } = this.props;
    return (
      <div>
        <div className="phone-confirmation_block">
            <div className="phone-confirmation_title">
              We just sent you a text.
            </div>
            <div className="phone-confirmation_text">
              Please enter the <span className="bold-text">6-digit verification code</span> sent to <span>{phone}</span>.
            </div>
          </div>

          <div className="step1-form-advantages">
            {this.renderForm()}
          </div>

          <div className="code-confiramtion_container">
            <button onClick={() => {resendCode()}} className="code-confiramtion_button">
              Resend code
            </button>
            <button onClick={() => {showPasscodeField()}} className="code-confiramtion_button">
              Edit phone number
            </button>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state)
  }
};

const mapDistpatchToProps = (dispatch) => {
  return {
    processAuthUserResponse: (value) => dispatch(processAuthUserResponse(value)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

CodeVerification = connect(mapStateToProps, mapDistpatchToProps)(CodeVerification);

export default reduxForm({
  form: "form"
})(CodeVerification);
