import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { userInfoSelector, activeStepDataSelector } from '../../selectors';
import { confirmOffer, nextStep } from "../../actions";
import Frame from "containers/MultistepForm/ContractStep/Frame";
import { reduxForm, Field, formValueSelector } from 'redux-form';
import "./styles.scss";
import { ApplicationAPI } from 'api/application';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { addThousandsSeparator } from "utils/helpers";
import { required } from "utils/validations";
import ConfirmationInfo from "./confirmation_info"

const selector = formValueSelector('confirmation_step_form');

class GenesisConfirmation1 extends Component {
  state = {
    loading: false
  };
   
  handleContinueButton = () => {
    const { token, nextStep, data} = this.props
    this.setState({loading: true})
    ApplicationAPI.accept_the_offer(
      token,
      data.app_id, 
      {}
    ).then(data => {
      if ( data.errors ) {
        return nextStep({step: 'nobodyApprovedStep'});
      }
      nextStep({step: 'genesisConfirmation2', data: data});
    }).catch(err => {

    })
  };
  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <Field
            name="isGenesisAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />
          <div className="agreemnet-block__label">
            <span>I have read and agree to the Consent to Electronic Communications</span>
          </div>
        </div>
      </div>
    )
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        {...input}
      />
    );
  };

  render() {
    const { data, valid } = this.props;
    const { loading } = this.state;

    return (
      <div className="genesis_confirmation_step step5__content flex--column">
        <a href={data.disclosure_link} target="_blank">
          Download
        </a>
        <Frame 
          contractUrl={data.disclosure_link}
        />
        <ConfirmationInfo/>
        {this.renderLeaseAgreement()}
        <BlueBtn
          customClass="step5__submit-btn"
          disabled={!valid}
          onClick={this.handleContinueButton}
          loading={loading}
        >
          ACCEPT OFFER AND PURCHASE
        </BlueBtn>

        <div className="post_info">If approved, the remaining available credit of <b>${addThousandsSeparator(data.approval_amount - data.requested_amount)}</b> may be used towards future purchases at Tire Agent, subject to credit available</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isGenesisAgreementChecked: selector(state, 'isGenesisAgreementChecked'),
  token: tokenSelector()(state),
  userInfo: userInfoSelector()(state),
  data: activeStepDataSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    nextStep: value => dispatch(nextStep(value))
  };
};

GenesisConfirmation1 = connect(mapStateToProps, mapDistpatchToProps)(GenesisConfirmation1);
GenesisConfirmation1 = reduxForm({
  form: "form",
  initialValues: { isGenesisAgreementChecked: false },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GenesisConfirmation1);

export default GenesisConfirmation1;
