import React from "react";
import { addThousandsSeparator } from "utils/helpers";
import "./styles.scss";

const OrderSummary = ({
  lineItems,
  totalPrice,
  taxSummary,
  shipping,
  discount,
}) => {
  return (
    <div className="item-content">
      <div className="items-row">
        ({lineItems.length}) Item Sub-Total:
        <span>${addThousandsSeparator(totalPrice)}</span>
      </div>
      {discount?.amount > 0 && (
        <div className="items-row">
          Exclusive One-Time Discount:
          <span>-${addThousandsSeparator(discount.amount)}</span>
        </div>
      )}
      <div className="items-row">
        Tax: <span>${addThousandsSeparator(taxSummary)}</span>
      </div>
      <div className="items-row">
        Shipping (Preferred Installer):
        <span>
          {shipping > 0 ? "$" + addThousandsSeparator(shipping) : "FREE"}
        </span>
      </div>
      <div className="items-row">
        Sub-Total:
        <span>
          $
          {addThousandsSeparator(
            totalPrice + taxSummary - (discount.amount || 0)
          )}
        </span>
      </div>
    </div>
  );
};

export default OrderSummary;
