import React from "react";

export const ID_TYPE = [
  { label: "Passport", value: "passport" },
  { label: "Driver's License", value: "drivers_license" },
  { label: "State ID Card", value: "state_id" },
  { label: "Military ID", value: "military_id" },
  { label: "Permanent Resident Card", value: "resident_alien_card" },
  { label: "Mexico Matricula Consular Card", value: "matricula_card" },
];


export const ID_STATE = [
  { value: 'AK', label: 'AK'},
  { value: 'AL', label: 'AL'},
  { value: 'AR', label: 'AR'},
  { value: 'AZ', label: 'AZ'},
  { value: 'CA', label: 'CA'},
  { value: 'CO', label: 'CO'},
  { value: 'CT', label: 'CT'},
  { value: 'DE', label: 'DE'},
  { value: 'FL', label: 'FL'},
  { value: 'GA', label: 'GA'},

  { value: 'HI', label: 'HI'},
  { value: 'IA', label: 'IA'},
  { value: 'ID', label: 'ID'},
  { value: 'IL', label: 'IL'},
  { value: 'IN', label: 'IN'},
  { value: 'KS', label: 'KS'},
  { value: 'KY', label: 'KY'},
  { value: 'LA', label: 'LA'},
  { value: 'MA', label: 'MA'},
  { value: 'MD', label: 'MD'},

  { value: 'ME', label: 'ME'},
  { value: 'MI', label: 'MI'},
  { value: 'MN', label: 'MN'},
  { value: 'MO', label: 'MO'},
  { value: 'MS', label: 'MS'},
  { value: 'MT', label: 'MT'},
  { value: 'NC', label: 'NC'},
  { value: 'ND', label: 'ND'},
  { value: 'NE', label: 'NE'},
  { value: 'NH', label: 'NH'},

  { value: 'NJ', label: 'NJ'},
  { value: 'NM', label: 'NM'},
  { value: 'NV', label: 'NV'},
  { value: 'NY', label: 'NY'},
  { value: 'OH', label: 'OH'},
  { value: 'OK', label: 'OK'},
  { value: 'OR', label: 'OR'},
  { value: 'PA', label: 'PA'},
  { value: 'RI', label: 'RI'},
  { value: 'SC', label: 'SC'},

  { value: 'SD', label: 'SD'},
  { value: 'TN', label: 'TN'},
  { value: 'TX', label: 'TX'},
  { value: 'UT', label: 'UT'},
  { value: 'VA', label: 'VA'},
  { value: 'VT', label: 'VT'},
  { value: 'WA', label: 'WA'},
  { value: 'WI', label: 'WI'},
  { value: 'WV', label: 'WV'},
  { value: 'WY', label: 'WY'}
];

export const AGREEMENT = [
  { value: "Yes", key: "true" },
  { value: "No", key: "false" }
];

export const PAYOUT_FREQUENCY = [
  { label: "Once per month", value: "monthly" },
  { label: "Every 2 weeks", value: "bi_weekly" },
  { label: "Twice a month", value: "semi_monthly" },
  { label: "Weekly", value: "weekly" }
];

export const MONTH = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" }
];

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 20 }, (v, i) => ({
    label: `${currentYear + i}`,
    value: `${currentYear + i}`,
  }));
};

export const PAYOUT_DAY = [
  { label: "First day of the month", value: "first" },
  { label: "Last day of the month", value: "last" },
];

export const PAYMENT_OPTIONS_TOOLTIP_TEXT =
  "Some finance companies require additional information from applicants in order to better verify their identity and better tailor their payment plans.";

export const ADDITIONAL_INFO_STEP__FEILDS = {
  additionalInfo: {
    options: AGREEMENT,
    tooltipType: "icon",
    label:
      "WOULD YOU LIKE TO INPUT ADDITIONAL INFORMATION TO APPLY FOR MORE PAYMENT OPTIONS?",
    tooltipText: PAYMENT_OPTIONS_TOOLTIP_TEXT
  },

  routingNum: {
    label: "Checking Routing Number",
    placeholder: "123454321"
  },

  accountNum: {
    label: "Checking Account Number",
    placeholder: "123454321"
  },

  confirmAccountNum: {
    label: "Confirm Account Number",
    placeholder: "123454321"
  },

  idType: {
    label: "ID TYPE",
    options: ID_TYPE
  },

  state: {
    label: "ID STATE",
    options: ID_STATE
  },

  idNum: {
    label: "id number",
    placeholder: ""
  },

  firstName: {
    label: '* First Name',
    placeholder: ''
  },

  lastName: {
    label: '* Last Name',
    placeholder: ''
  },

  email: {
    label: '* Email Address',
    placeholder: ''
  },

  streetAddress: {
    label: '* Street Adress',
    placeholder: ''
  },

  city: {
    label: '* City',
    placeholder: ''
  },

  zipCode: {
    label: '* Zip Code',
    placeholder: ''
  },

  dob: {
    label: 'Date of birth'
  },

  dob_p: {
    label: '* What is your date of birth?',
    placeholder: ''
  },

  passcode: {
    placeholder: '000000'
  }
};

export const PROGRESSIVE_STEP__FIELDS = {
  routingNum: {
    label: "Bank Routing Number *",
    placeholder: "123454321"
  },
  accountNum: {
    label: "Checking Account Number *",
    placeholder: "123454321"
  },
  firstName: {
    label: "First Name (On Card) *",
    placeholder: ""
  },
  lastName: {
    label: "Last Name (On Card) *",
    placeholder: ""
  },
  creditCardNumber: {
    label: "Credit Card Number *",
    placeholder: ""
  },
  expDate: {
    label: "Exp. Date *",
    placeholder: ""
  },
  securityCode: {
    label: "Security Code *",
    placeholder: ""
  },
};

export const PAYOUT_FREQUENCY_TOOLTIP_TEXT = (
  <div className="frequency-payment-tooltip">
    <div style={{ margin: 0 }}>
      <span className="font-semibold font-italic"> Once per month:</span>
      Jan 1st, Feb 1st, etc
    </div>

    <div>
      <span className="font-semibold font-italic"> Every 2 weeks:</span>
      Jan 3rd, Jan 17th, Jan 31st, etc
    </div>

    <div>
      <span className="font-semibold font-italic"> Twice a month:</span>
      Jan 15th, Jan 31st, etc
    </div>

    <div>
      <span className="font-semibold font-italic">Weekly:</span>
      Jan 1st, Jun 8th, Jun 15th, etc
    </div>
  </div>
);
