export const checkDiscount = (response, state) => {
  const MERCHANTS_WITH_DISCOUNT = ['tireagent'];

  const cartAmount = parseFloat(response.amount)
  const offers = response.offers
  const approvedOffers = offers.filter(e => e.approve_status !== "need_additionals")
  const availableOffers = approvedOffers.filter(e => e.approved_amount >= cartAmount - parseFloat(e.full_taxes) + parseFloat(e.taxes));
  const currentMerchant = state.leaseForm && state.leaseForm.merchant;
  const merchantRejectDiscount = currentMerchant && MERCHANTS_WITH_DISCOUNT.indexOf(currentMerchant.toLowerCase()) === -1;
  const prequalification = state.leaseForm && state.leaseForm.applicationRequest && state.leaseForm.applicationRequest.prequalification;
  const isPrequalification = (/true/).test(prequalification);

  if (availableOffers.length > 0 || merchantRejectDiscount || isPrequalification) {
    return { offers: []}
  }

  const percentages = [0.99, 0.98 , 0.97, 0.96, 0.95, 0.94, 0.93, 0.92, 0.91, 0.90]
  var finPercentage = 0
  var availableWithDiscountOffers = []

  percentages.every(
    p => {
      const percentage = p
      availableWithDiscountOffers = approvedOffers.filter(e => e.approved_amount >= ( cartAmount - parseFloat(e.full_taxes) + parseFloat(e.taxes )) * p)
      if (availableWithDiscountOffers.length > 0) {
        finPercentage = percentage
        return false
      }
      return true
    }
  )

  if (availableWithDiscountOffers.length > 0) {
    return { offers: availableWithDiscountOffers.map(e => e.provider), percentage: 100 - (finPercentage * 100) }
  }
  return { offers: [] }
}


export const maxApprove = (response) => {
  const offers = response.offers
  const approvedOffers = offers.filter(e => e.approve_status !== "need_additionals")
  return Math.max(...approvedOffers.map(o => o.approved_amount))
}
