import React, { useState } from "react";
import './styles.scss'

const AcimaTooltip = () => {
  const [show, setShow] = useState(false)
  return (
    <div className="step-tooltip-block">
      <h2 className="tooltip-title bold">
        Shop without credit*
      </h2>
      <p>
        Tire Agent has partnered with Acima to offer a flexible, lease-to-own solution to help you
        take home the products you need without using credit or increasing your debt.
      </p>
      { show && (
          <div>
            <div className="acima-text">
              <ul>
                <li className="tooltip-title">Get Approved for Up to $4,000 in merchandise**</li>
                <p>Applying is fast and easy.</p>
                <li className="tooltip-title">Easy Payment Options</li>
                <p>Make flexible payments that are conveniently scheduled with your payday.</p>
                <li className="tooltip-title">Early Purchase Savings</li>
                <p>You can choose to purchase your item at any point during the lease at a discount.</p>
              </ul>
            </div>
            <div className="additional-info">
              The advertised transaction is a rental purchase agreement (rent-to-own agreement, consumer rental-purchase agreement or a lease/lease-purchase
              agreement depending on your state) provided by Acima. You will not own the merchandise until the total amount necessary to acquire ownership is paid
              in full or you exercise your early purchase option. Ownership is optional. Approval subject to review and verification of your application. Not all applicants
              are approved. Approval amount based on retail price of host retailer. The Acima cash price may include a markup over invoice price. Acquiring
              ownership by leasing costs more than the retailer’s cash price. See your lease for payment amounts and rental terms. *“Shop without credit” means that
              this is not a credit, loan, or financing transaction. We consider multiple data points in reviewing your application and regularly approve customers with
              less than perfect credit history. **&quot;Get approved for up to $4,000” refers to the maximum amount Acima will spend to acquire goods from a retailer to
              provide to you, subject to a lease purchase transaction.
            </div>
          </div>
      )}
      <div>
        <div className="text-right">
          <div className='read-more' onClick={() => setShow(prev => !prev)}>Read More</div>
        </div>
      </div>
    </div>
  );
};

export default AcimaTooltip;
