import React, { Component } from "react";
import { IconLockFooter } from "components/Svgs";
import "./styles.scss";

class ModalFooter extends Component {
  render() {
    const tooltipText = "All data is encrypted and stored securely<br/> using the industry’s best standards.";
    return (
      <div className="multistep-modal-footer">
        <div className="flex flex__align--baseline flex__justify--center">
          <div>
            <IconLockFooter
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          </div>
        </div>
        <div className="multistep-modal-footer__text step1-notification">
          Powered by PayPair
        </div>
      </div>
    );
  }
}

export default ModalFooter;
