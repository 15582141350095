import { capitalizeFirstletter, addThousandsSeparator } from "utils/helpers";
import {paymentScheduleOptionsMapper} from "containers/MultistepForm/helpers";

const buildUserFriendlyPaymentSchedule = (payment_schedule) => {
  if (!payment_schedule) {
    return '';
  }
  switch(payment_schedule.toLowerCase().replace(/-|_/g, '')) {
    case 'monthly':
      return 'Monthly'
    case 'weekly':
      return 'Weekly'
    case 'biweekly':
      return 'Every two weeks'
    case 'semimonthly':
      return 'Semi-monthly'
    default:
      return '';
  }
}

export const selectCompareData = (option, state) => {
  switch(option.provider) {
    case 'paytomorrow':
      return paytomorrowCompareData(option)
    case 'flexshopper':
      return flexshopperCompareData(option)
    case 'genesis':
      return genesisCompareData(option)
    case 'okinus':
      return okinusCompareData(option)
    case 'uown':
      return uownCompareData(option)
    case 'aff':
      return affCompareData(option, state)
    case 'acima':
      return acimaCompareData(option)
    case 'snap':
      return snapCompareData(option)
    case 'ownlease':
      return ownleaseCompareData(option)
    case 'kornerstone':
      return kornerstoneCompareData(option)
    case 'progressive':
      return progressiveCompareData(option)
  }
}

const paytomorrowCompareData = (selectedOffer) => {
  const recurringPayment = (selectedOffer) => {
    if (selectedOffer.first_period_rate && selectedOffer.last_period_rate) {
      return selectedOffer.first_period_rate
    }
    return selectedOffer.payment_period_rate
  }

  return {
    specificProviderName: selectedOffer.specific_provider_name,
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.maxLoan)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${recurringPayment(selectedOffer)}`
    },{
      title: 'Payment Frequency',
      value:  capitalizeFirstletter(selectedOffer.payment_schedule)
    },{
      title: 'Early Buyout Term',
      value: `${selectedOffer.specific_provider_name === 'paytomorrow' ?
              'Payoff in 90 days and only pay a 11% fee' :
              'Simply pay the cash price within 100 days after the lease is completed.'}`
    },{
      title: 'Required To Complete',
      value: 'Credit / Debit Card'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
};

export const flexshopperCompareData = (selectedOffer) => {
  return {
    specificProviderName: "flexshopper",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$0`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value:  capitalizeFirstletter(selectedOffer.payment_schedule)
    },{
      title: 'Early Buyout Term',
      value: `This option lets you purchase the leased merchandise within 90 days of delivery for the Cash Price.`
    },{
      title: 'Required To Complete',
      value: 'Checking Account / Routing Number'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
};

export const genesisCompareData = (selectedOffer) => {
  return {
    specificProviderName: "genesis",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value: `${selectedOffer.payment_schedule}`
    },{
      title: 'Early Buyout Term',
      value: `None`
    },{
      title: 'Required To Complete',
      value: 'Credit / Debit Card'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
}

export const okinusCompareData = (selectedOffer) => {
  return {
    specificProviderName: "okinus",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value: `${selectedOffer.payment_schedule}`
    },{
      title: 'Early Buyout Term',
      value: `None`
    },{
      title: 'Required To Complete',
      value: 'Credit / Debit Card'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
}

export const uownCompareData = (selectedOffer) => {
  return {
    specificProviderName: "uown",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$0`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value:  `${paymentScheduleOptionsMapper(selectedOffer.payment_schedule)}`
    },{
      title: 'Early Buyout Term',
      value: 'If paid in full within 90 days the total price owed is only the order amount + application fee + sales tax.'
    },{
      title: 'Required To Complete',
      value: 'Credit / Debit Card'
    },{
      title: 'Payment Credit Reporting',
      value: 'Yes'
    }]}
}

export const affCompareData = (selectedOffer, state) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(
    selectedOffer.payment_schedule
  );
  const aprPresent = state === 'PA' && selectedOffer.apr >= 0

  return {
    specificProviderName: "aff",
    dataForApprouve: selectedOffer,
    tableData: [
      {
        title: "Due today",
        value: `$0`,
      },{
        title: "Approval Amount",
        value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
      },{
        title: "Type of Plan",
        value: `${aprPresent ? "Retail Installment Loan" : selectedOffer.type}`,
      },{
        title: "Recurring Payment",
        value: `$${selectedOffer.payment_period_rate}`,
      },{
        title: "Payment Frequency",
        value: capitalizeFirstletter(formattedPaymentSchedule),
      },{
        title: "Early Buyout Term",
        value: `90 days`,
      },{
        title: "Required To Complete",
        value: "Debit Card",
      },
      aprPresent && {
        title: "Estimated APR",
        value: `${selectedOffer.apr}%`,
      },{
        title: "Payment Credit Reporting",
        value: `${aprPresent ? "Yes" : "No"}`,
      },
    ].filter((item) => !!item),
  };
};

export const acimaCompareData = (selectedOffer) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  return {
    specificProviderName: "acima",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value:  capitalizeFirstletter(formattedPaymentSchedule)
    },{
      title: 'Early Buyout Term',
      value: `If paid in full within 90 days the total price owed is only the order amount + application fee + sales tax.`
    },{
      title: 'Required To Complete',
      value: 'Credit / Debit Card \n Drivers License / State ID  \n Checking / Routing Numbers'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
}

export const snapCompareData = (selectedOffer) => {
  return {
    specificProviderName: "snap",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'Prequalified Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    },{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    },{
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    },{
      title: 'Payment Frequency',
      value:  capitalizeFirstletter(selectedOffer.payment_schedule)
    },{
      title: 'Early Buyout Term',
      value: `This option lets you purchase the leased merchandise within 90 days of delivery for the Cash Price.`
    },{
      title: 'Required To Complete',
      value: 'Checking / Routing Number'
    },{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]}
};

export const ownleaseCompareData = (selectedOffer) => {
  return {
    specificProviderName: "ownlease",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    }, {
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }, {
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    }, {
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    }, {
      title: 'Payment Frequency',
      value: capitalizeFirstletter(selectedOffer.payment_schedule)
    }, {
      title: 'Early Buyout Term',
      value: `This option lets you purchase the leased merchandise within 90 days of delivery for the Cash Price.`
    }, {
      title: 'Required To Complete',
      value: 'Checking / Routing Number'
    }, {
      title: 'Payment Credit Reporting',
      value: 'No'
    }]
  }
};

export const kornerstoneCompareData = (selectedOffer) => {
  return {
    specificProviderName: "kornerstone",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    }, {
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }, {
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    }, {
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    }, {
      title: 'Payment Frequency',
      value: capitalizeFirstletter(selectedOffer.payment_schedule)
    }, {
      title: 'Early Buyout Term',
      value: `None`
    }, {
      title: 'Required To Complete',
      value: 'Checking / Routing Number'
    }, {
      title: 'Payment Credit Reporting',
      value: 'No'
    }]
  }
};

export const progressiveCompareData = (selectedOffer) => {
  return {
    specificProviderName: "progressive",
    dataForApprouve: selectedOffer,
    tableData: [{
      title: 'Due today',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    }, {
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }, {
      title: 'Type of Plan',
      value: `${selectedOffer.type}`
    }, {
      title: 'Recurring Payment',
      value: `$${selectedOffer.payment_period_rate}`
    }, {
      title: 'Payment Frequency',
      value: capitalizeFirstletter(selectedOffer.payment_schedule)
    }, {
      title: 'Early Buyout Term',
      value: `None`
    }, {
      title: 'Required To Complete',
      value: 'Checking / Routing Number'
    }, {
      title: 'Payment Credit Reporting',
      value: 'No'
    }]
  }
};
