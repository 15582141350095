/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { IconDocument } from "components/Svgs";
import "./styles.scss";

const AgreementLink = ({ linkText, isAgreementReviewed, action }) => {
  return (
    <a
      href="#"
      onClick={action}
      className={classNames(
        "agreement-link-component flex flex__align--center",
        {
          visited: isAgreementReviewed
        }
      )}
    >
      <div className="agreement-link-component__icon">
        <IconDocument />
      </div>
      <div className="agreement-link-component__content">
        <div className="font-uppercased">REQUIRED:</div>
        <div className="agreement-link-component__text">{linkText}</div>
      </div>
    </a>
  );
};

AgreementLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  isAgreementReviewed: PropTypes.bool,
  action: PropTypes.func
};

AgreementLink.defaultProps = {
  linkText: ""
};

export default AgreementLink;
