import React from "react";
import { HEADER_TITLE } from "./Step2/constants";


export const renderHeader = () => {
  return (
    <div className="step2-header">
      <div className="step2-header__top">
        <div className="step2-header__text">
          <div className="subtitle font-left title2">Application Details</div>
          <h2 className="title2 step2-header_text">
            {HEADER_TITLE}
          </h2>
        </div>
      </div>
    </div>
  );
}
