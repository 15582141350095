/* eslint-disable arrow-body-style, no-shadow */

import _ from "lodash";
import moment from 'moment';

const isPhoneNumEqual = num => value => {
  value = value.replace(/[^\d]/g, "");
  if (value && value.length !== num) {
    return `Phone number must be ${num} digits long`;
  }

  return undefined;
};

const isNumEqual = (num, message) => value => {
  if (!value) {
    return undefined
  }

  value = value.replace(/[^\d]/g, "");
  if (value && value.length !== num) {
    return message;
  }

  return undefined;
};

const isLessThan = (num, message) => value => {
  if (value && value.length >= num) {
    return message;
  }

  return undefined;
};

export const validate = values => {
  const errors = {}
  const date = `${values.year}-${values.month}-${values.day}`
  const currentDate = moment(new Date(), "YYYY.MM.DD")
  const dateOfBirth = moment(date, "YYYY.MM.DD");
  const diff = moment.duration(currentDate.diff(dateOfBirth)).asYears();
  if (diff <= 18) {
    errors.year = 'You should be older'
  }
  if (diff >= 100) {
    errors.year = 'Year is incorrect'
  }
  return errors
}

export const required = value =>
  value || typeof value === "number" ? undefined : "required";

export const isPhoneNumEqual10 = isPhoneNumEqual(10);

export const isYearEqual4 = isNumEqual(4, "Year should be 4 digits long");

export const isCodeEqual6 = isNumEqual(6, "Code should be 6 digits long");

export const isNumEqual9 = isNumEqual(9, "Number must be 9 digits long");

export const isNumEqual14 = isNumEqual(14, "Number must be 9 digits long");

export const isLessThan20digits = isLessThan(
  20,
  "Should not exceed 20 digits long."
);

export const isLessThan20characters = isLessThan(
  20,
  "Should not exceed 20 characters long."
);

export const onlyNumber = value => {
  // value = value.replace(/[^\d]/g, "");
  if (value && isNaN(Number(value))) {
    return "Must be a number";
  }
  return undefined;
};

export const onlyPositiveInteger = value => {
  value = value && Number(value) ? Number(value) : null;

  if (_.isNull(value) || !Number.isInteger(value) || value <= 0) {
    return "only positive";
  }

  return undefined;
};

export const greaterOrEqualZero = value => {
  if (!value) {
    return undefined;
  }
  if (value >= 0) {
    return undefined;
  }

  return "only numbers";
};

export const greaterOrEqualZeroIncome = value => {
  value = value.replace(/[$]/g, "");

  if (!Number(value)) {
    return undefined;
  }
  if (Number(value) >= 0) {
    return undefined;
  }
  return "only numbers";
};

const isNumGreaterThan = (num, message) => value => {
  if (!value) {
    return undefined
  }

  value =  value.replace(/[^0-9 ]/g, "")

  if (value && Number(value) > num) {
    return message;
  }

  return undefined;
};

export const isNumGreaterThan50000 = isNumGreaterThan(50000, "Max amount is $50,000");


export const onlyNumberAndLetters = value => {
  const rexExp = /^[a-zA-Z0-9]+$/;

  if (value && !rexExp.test(value)) {
    return "Can be letters and digits";
  }

  return undefined;
};

export const accountNumMustMatch = (value, allValues) => {
  return value !== allValues.accountNum ? 'Account numbers do not match' : undefined;
};

export const validateEmail = (email) => {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (email && !emailRegExp.test(email)) {
    return "Valid Email Address is required.";
  }

  return undefined;
};

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength15 = maxLength(15);
export const minLength2 = minLength(2);
export const maxLength4 = maxLength(4);
export const minLength3 = minLength(3);

export const isNumber = value => value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const validateCreditCard = value =>
  value && !/^\d{16}$/.test(value.replace(/\s/g, "")) ? "Invalid credit card number, must be 16 digits" : undefined;

export const validateExpDate = value =>
  value && !/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(value) ? "Invalid date, format MM/YY" : undefined;

export const validateCVV = value =>
  value && !/^\d{3,4}$/.test(value) ? "Invalid CVV, must be 3 or 4 digits" : undefined;
