import Step1 from "../Step1";
import PrequalificationStep from "../PrequalificationStep";
import Step2 from "../Step2";
import Step3 from "../Step3";
import Step3Prequalification from "../Step3Prequalification";
import ConfirmationStep from "../ConfirmationStep";
import NobodyApprovedStep from "../NobodyApprovedStep";
import ProviderHaveNotApprovedStep from "../ProviderHaveNotApprovedStep";
import ErrorStep from "../ErrorStep";
import AdditionalInfoStep from "../AdditionalInfoStep";
import ContractStep from "../ContractStep";
import SuccessStep from "../SuccessStep";
import AlreadyCompletedStep from "../AlreadyCompletedStep";
import VerificationQuestionsStep from "../VerificationQuestionsStep";
import BankDataForm from "../Step3/FlexshopperOffer/BankDataForm";
import ProgressiveBankDataForm from "../Step3/ProgressiveOffer/BankDataForm";
import ProgressiveCardDataForm from "../Step3/ProgressiveOffer/CardDataForm";
import ProgressiveDeniedAppScreen from "../Step3/ProgressiveOffer/DeniedScreen";
import ProgressiveAgreement from "../Step3/ProgressiveOffer/Agreement";
import ProgressivePendingAppScreen from '../Step3/ProgressiveOffer/PendingScreen';
import ProgressiveApprovedAppScreen from '../Step3/ProgressiveOffer/ApprovedScreen';

import GenesisConfirmation1 from "../Genesis/ConfirmationStep1";
import GenesisConfirmation2 from "../Genesis/ConfirmationStep2";

import SnapConfirmation from "../Snap/SnapConfirmation";
import SnapTotalInfo from "../Snap/SnapTotalInfo";
import SnapLeasePayment from "../Snap/SnapLeasePayment";
import Loader from "../Loader";

export function getForms(multipleIframeEnabled = false) {
  return {
    form1: { breadcrumb: null, form: Step1 },
    prequalification: { breadcrumb: "Application", form: PrequalificationStep },
    form2: { breadcrumb: null, form: Step2 },
    loader: { breadcrumb: null, form: Loader },
    form3: { breadcrumb: "Decision", form: Step3 },
    step3Prequalification: { breadcrumb: null, form: Step3Prequalification },
    confirmationStep: { breadcrumb: "Confirmation", form: ConfirmationStep },
    nobodyApprovedStep: { breadcrumb: "Decision", form: NobodyApprovedStep },
    providerHaveNotApprovedStep: {
      breadcrumb: "Decision",
      form: ProviderHaveNotApprovedStep,
    },
    errorStep: { breadcrumb: "Decision", form: ErrorStep },
    additionalInfoStep: { breadcrumb: "Decision", form: AdditionalInfoStep },
    flexshopperBankData: { breadcrumb: "Confirmation", form: BankDataForm },
    progressiveBankData: { breadcrumb: "Confirmation", form: ProgressiveBankDataForm },
    progressiveDeniedAppScreen: { breadcrumb: "Confirmation", form: ProgressiveDeniedAppScreen },
    progressivePendingAppScreen: { breadcrumb: "Confirmation", form: ProgressivePendingAppScreen },
    progressiveApprovedAppScreen: { breadcrumb: "Confirmation", form: ProgressiveApprovedAppScreen },
    progressiveCardData: { breadcrumb: "Confirmation", form: ProgressiveCardDataForm },
    progressiveAgreement: { breadcrumb: "Confirmation", form: ProgressiveAgreement },
    ...(!multipleIframeEnabled && {
      contractStep: { breadcrumb: "Confirmation", form: ContractStep },
    }),
    successStep: { breadcrumb: "Confirmation", form: SuccessStep },
    alreadyCompletedStep: {
      breadcrumb: "Decision",
      form: AlreadyCompletedStep,
    },
    verificationQuestionsStep: {
      breadcrumb: "Decision",
      form: VerificationQuestionsStep,
    },
    genesisConfirmation1: {
      breadcrumb: "Confirmation",
      form: GenesisConfirmation1,
    },
    genesisConfirmation2: {
      breadcrumb: "Confirmation",
      form: GenesisConfirmation2,
    },
    snapConfirmation: { breadcrumb: "Confirmation", form: SnapConfirmation },
    snapTotalInfo: { breadcrumb: "Confirmation", form: SnapTotalInfo },
    snapLeasePayment: { breadcrumb: "Confirmation", form: SnapLeasePayment },
  };
}
