export default {
  DISCLOSURE_FS: 'https://www.flexshopper.com/page/electronic-communication',
  TERMS_FS: 'https://www.flexshopper.com/page/terms-of-use',
  PRIVACY_FS: 'https://www.flexshopper.com/page/privacy',
  MAIN_FS: 'https://WWW.FLEXSHOPPER.COM',
  TERMS_AND_CONDITIONS_AFF: 'https://api.paypair.com/Application_Terms_and_Conditions.pdf',
  ARDITATION_AFF: 'https://api.paypair.com/Arditation_Procision.pdf',
  PRIVACY_AFF: 'https://hcms2.americanfirstfinance.com/wp-content/uploads/2021/02/Privacy-Policy-English-AFF.pdf',
  CONSENT_TO_ELECTRONIC_COMMUNICATION_SNAP: 'https://snapfinance.com/legal/customer-tc#1_consent_to_electronic_transactions',
  PRIVACY_POLICY_SNAP: 'https://snapfinance.com/legal/privacy#privacy_notices',
  TERMS_AND_CONDITIONS_SNAP: 'https://snapfinance.com/legal/customer-tc',
  JURY_TRIAL_SNAP: 'https://snapfinance.com/legal/customer-tc#7_jury_trial_waiver_and_arbitration_clause',
  SMS_TERMS_SNAP: 'https://snapfinance.com/legal/sms-terms-and-conditions',
}
