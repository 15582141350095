import React, { Component } from "react";
import { connect } from "react-redux";
import { selectOffer } from "containers/MultistepForm/actions";
import { mergeRight } from "ramda";
import PropTypes from "prop-types";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { reduxForm } from "redux-form";
import { ExpandBtnWithText } from "components/Controls";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import { paymentFrequency } from "utils/helpers";
import { nextStep } from "../../../actions";
import { Approval } from "components/FlexshopperMetrics";
import {
  affiliatedProviderSelector,
  trendingsVisitID,
} from "../../../selectors";
import { createStructuredSelector } from "reselect";
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import OfferHeader from "../../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import PaymentDetails from "./payment_details";
import "./styles.scss";

class Offer extends Component {
  state = {
    detailsShown: false,
    loading: false,
    selectedOption: null,
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: offer.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
      bank_data_is_required: offer.bank_data_is_required,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (e) => {
    const { selectOffer, offer, nextStep, logContinueEvent } = this.props;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(
      token,
      "select_provider",
      offer.specific_provider_name,
      visitID
    );
    logContinueEvent()
    this.setState({ loading: true });
    selectOffer({ selectedOffer: offer });
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return <CustomCheckbox meta={meta} {...input} />;
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <div className="agreemnet-block__label">
            <span>Subject to additional identity verification questions.</span>
          </div>
        </div>
      </div>
    );
  };

  details = () => {
    const { selectedOption } = this.state;
    return {
      title: "View Details",
      expandedValue: <PaymentDetails selectedOption={selectedOption} />,
    };
  };
  render() {
    const { loading, selectedOption } = this.state;
    const { available, providerName, isExpanded, offer } = this.props;

    if (!selectedOption) {
      return "";
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={0}
          specificProviderName={selectedOption.provider}
          // check this when first, last or just amount
          paymentPeriod={`${selectedOption.payment_period} Weeks`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form className="flexshopper-offer application-offer">
            {providerName == "flexshopper" && (
              <Approval spendingLimit={selectedOption.spendingLimit} />
            )}
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView
                offerData={buildOfferData(selectedOption, this.details())}
              />
            </div>
            <BuyoutInfo
              titleTooltip={
                "You will own the item(s) and not have to pay any additional amount if you pay the full Cash Price (the Cash Price is the price we charge for a cash sale of the item (or $50 more than the price the merchant you select charges) plus sales taxes and may be higher than the price you would pay if you were to purchase the item(s) directly; if you rent multiple items at the same time, we reduce the Cash Price (and increase the Cost of Rental) by $50 for the second and each subsequent item you rent, which makes it less expensive to purchase the items before the end of the term of the Lease). After 90 days, you also have an Early Purchase Option that allows you to own your item(s) for less. Simply refer to the table in your Lease to look up the Early Purchase Option amount after each payment date. "
              }
              title={"90 Days Same As Cash Option"}
              text={`This option lets you purchase the leased merchandise within 90 days of delivery for the Cash Price.`}
            />
            {this.renderLeaseAgreement()}
            <BlueBtn
              onClick={this.submitForm}
              customClass="step3__recommended-content-btn"
              disabled={!available}
              loading={loading}
              type="submit"
            >
              Continue with Flexshopper
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

Offer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

Offer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  providerName: affiliatedProviderSelector(),
  visitID: trendingsVisitID(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
  };
};

Offer = connect(mapStateToProps, mapDistpatchToProps)(Offer);

Offer = reduxForm({
  form: "flexshopper_offer_form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Offer);

export default Offer;
