import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Loader from "../../Loader";
import { closingSelector } from "../../selectors";
import { connect } from "react-redux";

class Frame extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("message", this.props.onReceiveMessage);
  }

  render() {
    const { contractUrl, isClosing, hidden } = this.props;
    return isClosing ? (
      <Loader style={{display: hidden ? 'none' : 'inherit'}} customText="Closing ...." />
    ) : (
      <div className="iframe-container" style={{display: hidden ? 'none' : 'inherit'}}>
        <div className="scrolldown_request">Please scroll down to continue</div>
        <iframe
          id="pt-iframe"
          name="provider_flow"
          frameBorder="0"
          border="0"
          cellSpacing="0"
          src={contractUrl}
        ></iframe>
      </div>
    );
  }
}

Frame.propTypes = {
  contractUrl: PropTypes.string,
  // onReceiveMessage: PropTypes.func
};

Frame.defaultProps = {
  contractUrl: "",
  // onReceiveMessage: () => {}
};

const mapStateToProps = (state) => {
  return {
    isClosing: closingSelector()(state),
  };
};

Frame = connect(mapStateToProps)(Frame);

export default Frame;
