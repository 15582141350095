import React, { Component } from "react";
import { connect } from "react-redux";
import { prequalificationSelector } from "containers/MultistepForm/selectors.js";

import LoaderImage from "assets/img/loader.gif";
import "./styles.scss";
import PropTypes from "prop-types";

class Loader extends Component {
  textRender = () => {
    const { prequalification, customText } = this.props;
    return (
      <p className="loader-text">
        {customText
          ? customText
          : prequalification
            ? "Matching you with Options"
            : "Finding Payment Plans for You"}
      </p>
    );
  };

  render() {
    const {style} = this.props || {};

    return (
      <div className="loader-container" style={style}>
        {this.textRender()}
        <img src={LoaderImage} alt="loader" />
      </div>
    );
  }
}

Loader.propTypes = {
  customText: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    prequalification: prequalificationSelector()(state),
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {};
};

Loader = connect(mapStateToProps, mapDistpatchToProps)(Loader);

export default Loader;
