import { call } from 'api/base';

export const UsersAPI = { 
  send_code(token, data) {
    return call(`users/send_code`, 'POST', token, data);
  },

  auth(token, data) {
    return call(`users/one_time_auth`, 'POST', token, data);
  },

  subscribe(token, data) {
    return call(`users/subscribe`, 'POST', token, data);
  }
}