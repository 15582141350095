import { createSelector } from 'reselect';
import { getFormValues } from "redux-form";

const selectMerchant = state => state.leaseForm;
const selectFormValues = state => getFormValues('form')(state);

const formValuesSelector = () => createSelector(
  selectFormValues,
  (values) => values
);

const paymentPeriodSelector = () => createSelector(
  selectFormValues,
  (values) => values && values.paymentPeriod
);

const activeStepSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.activeStep
);

const allSelectedOffersSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.allSelectedOffers
);

const multipleIframeFlagSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest.multipleIframeEnabled
);

const appRequestSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest
);

const userInfoSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest.personalInfo
);

const trendingsVisitID = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest.visitId
);

const subscribingDataSelector = () => createSelector(
  selectMerchant,
  (merchant) => {
    let total_price = 0;
    const line_items = merchant.applicationRequest.items ? merchant.applicationRequest.items.map(item => {
      total_price += item.price * item.quantity;
      return {
        price: item.price,
        quantity: item.quantity,
        category: item.category,
        brand: item.brand,
        model: item.model,
        image: item.image,
        id: item.id,
        taxAmount: item.taxAmount,
        product: {
          title: `${item.brand} ${item.model}`,
          sku: item.sku
        }
      }
    }) : []
    return {
      customer: {
        phone: merchant.applicationRequest.personalInfo.phone
      },
      line_items: line_items,
      total_price: total_price,
      order_id: merchant.applicationRequest.orderId
    }
  }
);

const initApplicationResponseSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse
);

const approvedApplicationsSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.offers.filter(o => o.approve_status === 'approved')
);

const availableApplicationsSelector = () => createSelector(
  selectMerchant,
  (merchant) => {
    const offers = merchant.applicationResponse?.offers;
    if (!offers) {
      return [];
    }
    return offers.filter(o => o.approve_status === 'approved' && o.available);
  }
);

const notAvailableApplicationsSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.offers.filter(o => o.approve_status === 'approved' && !o.available)
);

const recommendationsSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.recommendations
);

const additionalsRequiredApplicationsSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.offers.filter(o => o.approve_status === 'need_additionals')
);

const additionalsProvidedSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.additionals_provided
);

const cartAmountSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationResponse.amount
);

const selectedOfferSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.selectedOffer
);

// const selectedOfferAcceptedSelector = () => createSelector(
//   selectMerchant,
//   (merchant) => merchant.selectedOffer.contract ? true : false
// );

const applicationHasContractSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.selectedOffer && (merchant.selectedOffer.contract || merchant.selectedOffer.contractUrl) ? true : false
);

const loadingSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.loading,
);

const closingSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.closing,
);

const selectedOfferSignedSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.selectedOfferSigned,
);

const step1Selector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.step1,
);


const activeStepDataSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.activeStepData
);

const metrixSessionIdSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.metrixSessionId
);

const threatMetrixPayTomorrowSessionIdSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.threatMetrixPayTomorrowSessionId
);

const snapSessionIdSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.snapSessionId
)

const uownSessionIdSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.uownSessionId
)

const blackboxIdSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.blackboxId
);

export const currentMerchant  = () => createSelector(
  selectMerchant,
  (merchant) => merchant.merchant
)

export const activeProviders  = () => createSelector(
  selectMerchant,
  (merchant) => merchant.activeProviders
)

const affiliatedProviderSelector  = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest.provider_name
)

const prequalificationSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.applicationRequest.prequalification === 'true'
)

const maxApproveSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.maxApprove
);

const affAgreements = () => createSelector(
  selectMerchant,
  (merchant) => merchant.affAgreements
);

const cartLoadingSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.cartModalLoading,
);

const cartModalOpenSelector = () => createSelector(
  selectMerchant,
  (merchant) => merchant.cartModalOpen
);

const discountDataSelector = () => createSelector(
  selectMerchant,
  (merchant) => {
    return {
      amount: merchant.discount,
      preDiscountTotal: merchant.preDiscountTotal,
    }
  }
);

export {
  selectMerchant,
  activeStepSelector,
  initApplicationResponseSelector,
  approvedApplicationsSelector,
  availableApplicationsSelector,
  notAvailableApplicationsSelector,
  recommendationsSelector,
  additionalsRequiredApplicationsSelector,
  additionalsProvidedSelector,
  cartAmountSelector,
  userInfoSelector,
  selectedOfferSelector,
  // selectedOfferAcceptedSelector,
  applicationHasContractSelector,
  loadingSelector,
  closingSelector,
  formValuesSelector,
  paymentPeriodSelector,
  selectedOfferSignedSelector,
  step1Selector,
  activeStepDataSelector,
  metrixSessionIdSelector,
  threatMetrixPayTomorrowSessionIdSelector,
  snapSessionIdSelector,
  uownSessionIdSelector,
  blackboxIdSelector,
  appRequestSelector,
  subscribingDataSelector,
  affiliatedProviderSelector,
  trendingsVisitID,
  prequalificationSelector,
  maxApproveSelector,
  affAgreements,
  cartLoadingSelector,
  cartModalOpenSelector,
  discountDataSelector,
  allSelectedOffersSelector,
  multipleIframeFlagSelector
};
