import React, { Component } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { cartModalOpenSelector } from "containers/MultistepForm/selectors";
import { IconCart, IconChevronSingle } from "components/Svgs";
import { addThousandsSeparator } from "utils/helpers";
import CartEditModal from "components/DataDisplay/CartEditModal";
import OrderSummary from "components/DataDisplay/OrderSummary";
import { toggleCartModal } from "containers/MultistepForm/actions";

class CartInfo extends Component {

  state = {
    isExpanded: false,
  };

  toggleExpandView = () => {
    const { isExpanded } = this.state;

    this.setState(() => ({
      isExpanded: !isExpanded,
    }));
  };

  toggleCartEditModal = () => {
    this.props.toggleCartModal()
  }

  taxSummary = () => {
    const { lineItems } = this.props;

    //TODO check taxes
    if (lineItems.length > 0) {
       return lineItems.map(i => i.taxAmount * i.quantity).reduce((a, b) => a + b);
    }
  }

  render() {
    const { isExpanded } = this.state;
    const { totalPrice, lineItems, discount, shipping, isOpen } = this.props;

    return (
      <div className="cart-info">
        <div className="expanded-block-component">
          <div className="flex-row flex__justify--between flex__align--center cart-total">
            <div className="flex-col">
              <span className="font-bold">Your Cart Total:</span> ${addThousandsSeparator(totalPrice + this.taxSummary())}
              <div className="expanded-block-component__expand-text expand-link">
                <div
                  onClick={this.toggleExpandView}
                  isExpanded={isExpanded}
                >
                  View Order Summary
                  <IconChevronSingle className={"svg-icon expand-icon " + (isExpanded ? 'icon-flip' : '')} />
                </div>
              </div>
            </div>
            {/*<div className="flex-col">*/}
            {/*  <IconCart className="svg-icon cart-icon" />*/}
            {/*  <div>*/}
            {/*    <a href="#" onClick={() => this.toggleCartEditModal()}>edit cart</a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          {isExpanded &&
            <OrderSummary
              lineItems={lineItems}
              totalPrice={totalPrice}
              taxSummary={this.taxSummary()}
              shipping={shipping}
              discount={discount}
            />
          }
        </div>
        {/*<CartEditModal*/}
        {/*  lineItems={lineItems}*/}
        {/*  totalPrice={totalPrice}*/}
        {/*  discount={discount}*/}
        {/*  shipping={shipping}*/}
        {/*  closeModal={this.toggleCartEditModal}*/}
        {/*  isOpen={isOpen}*/}
        {/*/>*/}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isOpen: cartModalOpenSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    toggleCartModal: (values) => dispatch(toggleCartModal(values))
  };
};

CartInfo = connect(mapStateToProps, mapDistpatchToProps)(CartInfo);

export default CartInfo;
