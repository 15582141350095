import *  as EventTypes from './eventTypes';

export const finishFlow = payload => ({
  type: EventTypes.FINISH_FLOW,
  payload
});

export const logEvent = payload => ({
  type: EventTypes.LOG_EVENT,
  payload
});

export const cancelFlow = payload => ({
  type: EventTypes.CANCEL_FLOW,
  payload
});

export const sendMessage = payload => ({
  type: 'SEND_MESSAGE',
  payload
});
