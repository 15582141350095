import PayTommorowLogo from "assets/img/paytomorrow-logo.png";
import ViaBillLogo from "assets/img/viabill-logo.png";
import AffirmLogo from "assets/img/Affirm_logo.png";
import SezzleLogo from "assets/img/sezzle-logo.png";
import FlexshopperLogo from "assets/img/FlexShopper-logo-blue.svg";
import OkinusLogo from "assets/img/okinus-logo.png";
import GreenWaveLogo from "assets/img/greenwave-logo.png";
import AffLogo from "assets/img/aff-logo.svg";
import GenesisLogo from "assets/img/genesis-logo.png";
import UownLogo from "assets/img/UownLogo.svg";
import AcimaLogo from "assets/img/acima-logo.svg";
import SnapLogo from "assets/img/snap-logo.png";
import KoalafiLogo from "assets/img/koalafi-logo.png";
import OwnleaseLogo from "assets/img/paypossible-own-logo.svg";
import KornerstoneLogo from "assets/img/kornerstone-logo.jpg";
import ProgressiveLogo from "assets/img/progressive-logo.png";
import KatapultLogo from "assets/img/katapult-logo.svg";
import BreadLogo from "assets/img/bread-logo.svg";

export const providerLogos = {
  paytomorrow: { img: PayTommorowLogo },
  flexshopper: { img: FlexshopperLogo },
  acima: { img: AcimaLogo },
  viabill: { img: ViaBillLogo },
  affirm: {img: AffirmLogo },
  sezzle: { img: SezzleLogo },
  okinus: { img: OkinusLogo },
  greenwave: { img: GreenWaveLogo },
  aff: { img: AffLogo },
  genesis: { img: GenesisLogo },
  uown: { img: UownLogo },
  snap: { img: SnapLogo },
  koalafi: { img: KoalafiLogo },
  ownlease: { img: OwnleaseLogo },
  kornerstone: { img: KornerstoneLogo },
  progressive: { img: ProgressiveLogo },
  katapult: { img: KatapultLogo },
  bread: { img: BreadLogo }
};
