import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import "./styles.scss";

const CustomRadioGroup = ({
  onChange,
  customChange,
  input,
  value,
  options,
  defaultValue,
  disabled,
  ...props
}) => {
  const combinedChange = (e) => {
    const value = e.target.value;
    onChange(value);
    setTimeout(() => customChange(value), 0);
  };

  const styles = {
    icon: {
      borderRadius: "50%",
      width: 19,
      height: 19,
      display: "block",
      boxShadow: "inset 0 0 0 1px #adadad",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      boxShadow: "none",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: "17px",
        height: "17px",
        left: "1px",
        top: "1px",
        position: "relative",
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
    },
  };

  return (
    <div className="custom-radio-group">
      <RadioGroup value={value} onChange={combinedChange}>
        {options.map((item) => (
          <FormControlLabel
            key={"item_" + item.key}
            control={
              <Radio
                disableRipple
                color="default"
                checkedIcon={
                  <Box
                    component="span"
                    sx={[styles.icon, styles.checkedIcon]}
                  />
                }
                icon={<Box component="span" sx={styles.icon} />}
                disabled={disabled}
                value={item.key}
                name={"item_" + item.key}
              />
            }
            {...input}
            label={item.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

CustomRadioGroup.propTypes = {
  customChange: PropTypes.func,
  options: PropTypes.array.isRequired,
};

CustomRadioGroup.defaultProps = {
  customChange: () => {},
  options: [],
};

export default CustomRadioGroup;
