import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer} from "../../actions";
import { mergeRight } from "ramda";
import { OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { Field, change } from 'redux-form';
import { RadioField } from "components/Fields";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { AnalyticAPI } from "api/analytic";
import {allSelectedOffersSelector, trendingsVisitID} from '../../selectors';
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { addThousandsSeparator, paymentFrequency } from "utils/helpers";

class OkinusOffer extends Component {
  state = {
    loading: false,
    options: [],
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({ options: offer.options });
    const defaultSelected = this.findMaxOffer(offer.options)
    this.setSelected(defaultSelected);
    this.props.dispatch(
      change(
        "form",
        "paymentPeriod",
        defaultSelected.optionName
      )
    );
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, offer);
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  findMaxOffer = (options) => {
    return options.reduce((prev, current) => (+prev.maxLoan > +current.maxLoan) ? prev : current)
  }

  submitForm = (values) => {
    const { allSelectedOffers, setSelectedOffer, nextStep, selectOffer, offer, logContinueEvent } = this.props;
    const { selectedOption } = this.state;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', 'okinus', visitID);
    logContinueEvent()
    // selectOffer({ selectedOffer: selectedOption });
    this.setState({loading: true})
    setSelectedOffer(selectedOption);

    const isContractOpened = allSelectedOffers.some(offer => offer.app_id === selectedOption.app_id)
    nextStep({ step: isContractOpened ? 'contractStep' : 'confirmationStep', data: selectedOption });
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `Option ${option.optionName.toString()}`,
        key: option.optionName.toString(),
      };
    });
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.optionName === value
    );

    this.setSelected(option);
  };

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
      className={'centered type_of_plan_block'}
      row={[{
        title: 'TYPE OF PLAN',
        value: `${selectedOption.type}`
      }]}
    />
    } else {
      return (<div className="offer-block offer-block--100">
          <div className="offer-item offer-item__column">
            <strong className="offer-item__title">SELECT YOUR PAYMENT PERIOD:</strong>
            <Field
              name="paymentPeriod"
              component={this.renderPaymentPeriodField}
            />
          </div>
        </div>)
    }
  }

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, available, isExpanded, offer } = this.props;

    if (!selectedOption) {
      return '';
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={addThousandsSeparator(selectedOption.initial_payment)}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        { isExpanded && (

        <form
          onSubmit={handleSubmit(this.submitForm)}
          className="tempoe-offer application-offer"
        >
          <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
            <OfferView offerData={buildOfferData(selectedOption)} />
          </div>
          <BlueBtn
            customClass="step3__recommended-content-btn"
            loading={loading}
            disabled={!available}
            type="submit"
          >
            Continue with Okinus
          </BlueBtn>
        </form>

        )}
      </div>
    );
  }
}

OkinusOffer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

OkinusOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  allSelectedOffers: allSelectedOffersSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

OkinusOffer = connect(mapStateToProps, mapDistpatchToProps)(OkinusOffer);

OkinusOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(OkinusOffer);

export default OkinusOffer;


