import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import "../ExpandBtn/styles.scss";

import { IconInfo } from "components/Svgs";

class ExpandBtnWithText extends Component {
  state = {
    detailsShown: false
  }

  constructor(props) {
    super(props);
    this.state.detailsShown = this.props.isExpanded;
  }

  toggleDetails = () => {
    this.setState({ detailsShown: !this.state.detailsShown })
  }

  render() {
    const { detailsShown } = this.state
    const { disabled, customClass, expandedInfo, ...props } = this.props
    return (
      <span>
        <button
          onClick={this.toggleDetails}
          disabled={disabled}
          type="button"
          className={classNames(
            "button flex-inline flex__justify--center",
            "button-expand",
            `${customClass}`,
            {
              expanded: detailsShown
            }
          )}
          {...props}
        >
          {props.children}
          <IconInfo className="icon" />
        </button>
        {detailsShown && expandedInfo}
      </span>
    );
  }
};

ExpandBtnWithText.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  expandedInfo: PropTypes.any.isRequired
};

ExpandBtnWithText.defaultProps = {
  disabled: false,
  isExpanded: false,
};

export default ExpandBtnWithText;
