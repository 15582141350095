export const ActionTypes = {
  init: 'INIT',
  initUserData: 'INIT_USER_DATA',
  initApplication: 'INIT_APPLICATION',
  initApplicationFailed: 'INIT_APPLICATION_FAILED',
  initApplicationSuccess: 'INIT_APPLICATION_SUCCESS',

  processApplicationResponse: 'PROCESS_APPLICATION_RESPONSE',
  processAuthUserResponse: 'PROCESS_AUTH_USER_RESPONSE',

  updateApplication: 'UPDATE_APPLICATION',
  updateApplicationFailed: 'UPDATE_APPLICATION_FAILED',
  updateApplicationSuccess: 'UPDATE_APPLICATION_SUCCESS',

  updateCompletedApplication:  'UPDATE_COMPLETED_APPLICATION',

  continueInitApplication: 'CONTINUE_INIT_APPLICATION',
  continueInitApplicationFailed: 'CONTINUE_INIT_APPLICATION_FAILED',
  continueInitApplicationSuccess: 'CONTINUE_INIT_APPLICATION_SUCCESS',

  backToOffers: 'BACK_TO_OFFERS',

  selectOffer: 'SELECT_OFFER',
  selectOfferFailed: 'SELECT_OFFER_FAILED',
  selectOfferSuccess: 'SELECT_OFFER_SUCCESS',

  cancelApplication: 'CANCEL_APPLICATION',

  finishApplication: 'FINISH_APPLICATION',
  finishApplicationFailed: 'FINISH_APPLICATION_FAILED',
  finishApplicationSuccess: 'FINISH_APPLICATION_SUCCESS',

  nextStep: 'NEXT_STEP',

  setSelectedOffer: 'SET_SELECTED_OFFER',
  setMetrixSession: 'SET_METRIX_SESSION',
  setPayTomorrowMetrix: 'SET_PAYTOMORROW_SESSION',
  setSnapSessionId: 'SET_SNAP_SESSION_ID',
  setUownSession: 'SET_UOWN_SESSION_ID',
  setIovation: 'SET_IOVATION',

  confirmOffer: 'CONFIRM_OFFER',
  confirmOfferFailed: 'CONFIRM_OFFER_FAILED',
  confirmOfferSuccess: 'CONFIRM_OFFER_SUCCESS',

  providerFailed: 'PROVIDER_FAILED',

  applyDiscount: 'APPLY_DISCOUNT',
  updateCart: 'UPDATE_CART',
  editCart: 'EDIT_CART',
  toggleCartModal: 'TOGGLE_CART_MODAL',
  updateWithDiscount: 'UPDATE_WITH_DISCOUNT',
  getRecommendations: 'GET_RECOMMENDATIONS',
  setRecommendations: 'SET_RECOMMENDATIONS',
  updateWithRecommendations: 'UPDATE_WITH_RECOMMENDATIONS',

  setMaxApprove: 'SET_MAX_APPROVE',
  startClosingModalWithLoader: 'START_CLOSING_WITH_LOADER',
  finishClosingModalAndRemoveLoader: 'FINISH_CLOSING_WITH_LOADER',
  addAffAgreements: 'ADD_AFF_AGREEMENTS'
}
