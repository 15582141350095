import React, { useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import { ValidationIcon } from "components/Controls";
import InputMask from "react-input-mask";
import constants from "utils/constants";
import { isValidDate } from "utils/helpers";

const DateField = ({
  value,
  selected,
  onChange,
  label,
  name,
  onBlur,
  customClass,
  onCalendarClose,
  reset,
  meta: { touched },
  ...props
}) => {
  const [selectedDate, setDate] = useState(selected);

  if ((selectedDate !== "") && (reset)) {
    setDate("")
    onChange("");
  }

  if ((value) && (!selectedDate) && (!reset)) {
    setDate(new Date(value));
  }

  const toggleWrapperClass = () => {
    const wrapper = document.querySelector(".multistep-form");
    wrapper.classList.toggle("overflowed");
  };

  const handleChange = (date) => {
    setDate(date);
    onChange(date);
  };

  return (
    <div className="date-field-component flex flex--column">
      <label className="font-uppercased date-field-label">{label}</label>
      <div className={classNames("date-field-wrapper", `${customClass}`)}>
        <DatePicker
          {...props}
          customInput={
            <InputMask
              type="tel"
              mask="99/99/9999"
              maskChar={null}
              placeholder="MM/DD/YYYY"
              readOnly
            />
          }
          selected={selectedDate}
          dateFormat={constants.USER_DATE_FORMAT}
          onCalendarClose={onCalendarClose}
          onCalendarOpen={toggleWrapperClass}
          onChange={handleChange}
          className={classNames("date-field-input", {
            invalid: touched && !selectedDate,
            valid: selectedDate,
          })}
          placeholderText={constants.USER_DATE_FORMAT.toUpperCase()}
        />
        {<ValidationIcon touched={touched} valid={isValidDate(selectedDate)} />}
      </div>
    </div>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  valid: PropTypes.bool,
};

export default DateField;
