import React from "react";
import OfferBlock from '../OfferBlock';

const OfferView = ({ offerData, preview }) => {
  return (offerData.map((row, i)=> (
    <OfferBlock
      key={i}
      row={row}
    />
  )));
};

export default OfferView;
