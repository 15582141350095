import React, { Component } from "react";
import { connect } from "react-redux";
import Frame from "../Frame";
import { finishApplication } from "../../actions";
import PropTypes from "prop-types";
class ProgressiveContract extends Component {

  receiveMessage = (event) => {
    const { finishApplication } = this.props
    if (event.data.message === "Post Process: Complete") {
      finishApplication();
    }
    return;
  }

  render() {
    const { contractUrl, hidden } = this.props
    return (
      <Frame
        contractUrl={contractUrl}
        hidden={hidden}
        onReceiveMessage={this.receiveMessage}
      />
    )
  }
}

ProgressiveContract.propTypes = {
  contractUrl: PropTypes.string
};

ProgressiveContract.defaultProps = {
  contractUrl: ""
};

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values))
  };
};

ProgressiveContract = connect(null, mapDistpatchToProps)(ProgressiveContract);

export default ProgressiveContract;
