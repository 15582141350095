export const SCORE_TOOLTIP_TEXT =
  "While no credit history is required, Some of our lenders or lease providers obtain information from consumer reporting agencies in connection with applications, and your score with those agencies may be affected. Not all applicants are approved";
export const PRIVACY_TOOLTIP_TEXT =
  "All data is encrypted and stored securely using the industry’s best standards.";
export const PHONE_FIELD_TOOLTIP_TEXT = (merchant) =>
  `When you give ${merchant} your mobile phone number, they have your permission to contact you at that number. Your consent allows them to use text messaging, artificial or prerecorded voice messages, and automatic dialing technology for informational and service calls, but not telemarketing or sales calls. It may include contact from companies working on their behalf. Message and data rates may apply. You may contact ${merchant} at support@paypair.com to change these preferences.`;

export const FAST_ANSWER_TOOLTIP_TEXT =
  "Decisions and Funding by some partners may take additional time and not be same-day, including if more information is needed to complete an application.";

export const AFF_LTO_TOOLTIP_TEXT =
  "A lease is not a loan or credit transaction. It has several names: lease, lease-to-own, rent-to-own, or rental-purchase. With a lease, you select the item(s) and a payment solution company, such as American First Finance (AFF). AFF purchases the item(s) from a Merchant. You take the item(s) home.  AFF retains ownership until you pay for the item(s) plus rental fees. Other fees may be included, such as late, non-sufficient fund, delivery, and optional Liability Damage Waiver fees. As a result, the total amount you pay for leasing an item is more than the retail cost. You can save money on rental fees if you pay the balance in full early. See contract for details if you move forward with American First Finance.";

export const AFF_RISA_TOOLTIP_TEXT =
	"A retail installment agreement is not a loan. You may enter into a Retail Installment Agreement with a merchant to purchase item(s) and/or service(s). AFF may purchase the Agreement from the Merchant. You may choose to take an in-stock item today, schedule delivery, or complete a service and pay over time. A down payment may be required. You can save money on finance charges if you pay off in full during the Early Payoff Discount Period. See contract for more details if you move forward with American First Finance."
