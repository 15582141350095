import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { confirmOffer, nextStep, setSelectedOffer } from "../../actions";
import { Field, reduxForm } from 'redux-form';
import { appRequestSelector } from "containers/MultistepForm/selectors";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { ProvidersApi } from 'api/providers';
import { required } from "utils/validations";
import { activeStepDataSelector } from '../../selectors';
import { buildUserFriendlyPaymentSchedule } from  '../../helpers'
import "./styles.scss";
import Moment from 'moment'

class SnapTotalInfo extends Component {
  state = {
    loading: false,
    summaryAcceptance: false
  }


  submitForm = () => {
    const { nextStep, token } = this.props;

    this.setState({ loading: true });
    return ProvidersApi.confirmation(token, this.buildUpdateApplicationData()).then(agreement => {
      this.setState({loading: false});
      this.props.setSelectedOffer({agreement: agreement.data});
      return nextStep({step: 'contractStep'});
    })
  };

  buildUpdateApplicationData = () => {
    const { data } = this.props

    return {
      lease_app_id: data.lease_app_id,
      request_id: data.app.request_id,
      summaryAcceptance: this.state.summaryAcceptance
    };
  }

  renderStbsTitle = () => {
    const { data } = this.props

    if (data.options[0].row_data.paymentPlanSummary.stbs) {
      return <h2 className="blue pad-bottom"> Application Reference Number { data.lease_app_id }</h2>
    }
    return <h2 className="blue pad-bottom"> Your cart amount for {data.options[0].row_data.paymentPlanSummary.leaseAmount} has been approved!</h2>
  }

  renderStbsStates = () => {
    const { data } = this.props

    if (data.options[0].row_data.paymentPlanSummary.stbs) {
      return (
        <div className="text-italic">
          <span><span className="orange">*</span>Includes Process Fee (${data.options[0].row_data.paymentPlanSummary.processingFee}) & Sales Tax ({data.options[0].row_data.paymentPlanSummary.stbsSalesTaxPercent}%)<span className="orange">**</span></span>
          <br/>
          <span><span className="orange">**</span>In TX. IL, PA, VA, CT, Snap is responsible for collecting sales tax, instead of the merchant. We have removed the sales tax from your cash price, buts it is included in cost of lease with Snap</span>
        </div>
      )
    }
    return '';
  }

  confirmTerms = () => {
    this.setState({ summaryAcceptance: !this.summaryAcceptance })
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        {...input}
      />
    );
  };

  confirmationBlock = () => {
    return (
      <div className="privacy-policy">
        <div className="privacy-policy-agreement flex flex__align--center">
          <Field
            validate={[required]}
            name="summaryAcceptance"
            component={this.renderAgreementCheckbox}
            onChange={() => this.confirmTerms()}
          />
          <div className="privacy-policy-agreement__label">
            I have viewed and acknowledge these terms
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {valid, appRequest} = this.props;
    const {data} = this.props.data;
    const {loading} = this.state;

    return (
      <div className="genesis_confirmation_step step5__content flex--column ">
        <div className="flex flex--column snap-align">


          <h1 className="subtitle font-left orange pad-bottom">Pay with Snap</h1>
          <h5 className="grey-color">Apply for lease-to-own financing</h5>
          <br/>
          { this.renderStbsTitle() }

          <h4 className="grey-color text-bold">Summary</h4>
          <div className="snap-color-total">
            <div className="">
              <div className="container">
                <h4 className="grey-color">{data.options[0].payment_period} Month to Ownership</h4>
              </div>
              <div className="container">
                <h5 className="grey-color">
                  <span>{ data.options[0].row_data.paymentPlanSummary.numberOfPaymentsWithSameAmount } { data.options[0].row_data.paymentPlanSummary.paymentFrequency?.toLowerCase() } renewal terms</span>
                </h5>
              </div>

              <div className="payment">
                <div className=" container">
                  <div className="text-bold">
                    {data.options[0].row_data.paymentPlanSummary.numberOfPaymentsWithSameAmount} payments of
                  </div>
                  <div className="right-box">
                    ${data.options[0].row_data.paymentPlanSummary.paymentAmount}
                  </div>
                </div>
                <div className="container text-italic pad-top">
                  {buildUserFriendlyPaymentSchedule(data.payment_schedule)}
                </div>
                <div className="flex flex--wrap container">
                  <div className="text-bold">Last payment of</div>
                  <div className="right-box">${data.options[0].row_data.paymentPlanSummary.lastPaymentAmount}</div>
                </div>
              </div>

              <div className="container">
                <div>Cash price</div>
                <div className='right-box'>${data.options[0].row_data.paymentPlanSummary.leaseAmount}</div>
              </div>
              <div className="container">
                <span>{data.options[0].row_data.paymentPlanSummary.stbs ? '*' : ''}Cost of lease</span>
                <span className="right-box">${data.options[0].row_data.paymentPlanSummary.leaseCost}</span>
              </div>
              <div className="container">
                <span>Processing Fee</span>
                <span className="right-box">${data.options[0].row_data.paymentPlanSummary.processingFee}</span>
              </div>
              <div className="container bold">
                <span className="text-bold">Total Payments</span>
                <span className="right-box text-bold">${data.options[0].row_data.paymentPlanSummary.leaseTotalAmount}</span>
              </div>
            </div>
          </div>

          <h4>Early Ownership Options</h4>
          <br/>
          <div>
            <span className="blue-light"><strong className="blue">100-Day Option.</strong> Pay off your account by <span className="orange text-bold">{ Moment(data.options[0].row_data.paymentPlanSummary.cpoDate).format('LL')}</span> and only pay <span className="orange text-bold">${data.options[0].row_data.paymentPlanSummary.cpoAmount}</span></span>
            <br/>
            <br/>
            <div><span className="blue-light"><strong className="blue font-bold">Early Buyout Option.</strong> Pay off your account early any time after <span className="orange text-bold">{ Moment(data.options[0].row_data.paymentPlanSummary.cpoDate).format('LL') }</span> and save!</span><br/><br/></div>
            <span className="text-italic">Once you've received the merchandise, you can exercise one of these options, please go to <a className="orange">customer.snapfinance.com</a> or call <a className="orange">877-577-3769</a></span>
          </div>

          <br/>
          { this.renderStbsStates() }
        </div>

        { appRequest.residenceInfo.state === 'PA' && this.confirmationBlock() }

        <BlueBtn
          customClass="step5__submit-btn"
          disabled={!valid}
          loading={loading}
          onClick={this.submitForm}
        >
          SUBMIT
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: activeStepDataSelector()(state),
  appRequest: appRequestSelector()(state),
  token: tokenSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    setSelectedOffer: value => dispatch(setSelectedOffer(value)),
    confirmOffer: () => dispatch(confirmOffer()),
    finishApplication: values => dispatch(finishApplication(values)),
    nextStep: value => dispatch(nextStep(value))
  };
};

SnapTotalInfo = connect(mapStateToProps, mapDistpatchToProps)(SnapTotalInfo);
SnapTotalInfo = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(SnapTotalInfo);

export default SnapTotalInfo;
