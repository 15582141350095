import { createSelector } from 'reselect';

const selectWidget = state => state.widget;

const tokenSelector = () => createSelector(
  selectWidget,
  (widget) => widget.key
);

export {
  tokenSelector
};

