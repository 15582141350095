import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IconBtn = ({ disabled, customClass, ...props }) => (
  <button
    disabled={disabled}
    type="button"
    className={classNames(
      "button flex flex__justify--center flex__align--center",
      "button-icon",
      `${customClass}`
    )}
    {...props}
  >
    <div className="button-icon__block">{props.children}</div>
  </button>
);

IconBtn.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default IconBtn;
