import React, { Component } from "react";
import { setUownSession } from "../../actions";
import { connect } from "react-redux";

class UownMetric extends Component {
  
  state = {
    SessionId: null,
  };

  componentDidMount = () => {
    const sessionId = this.generateSessionId();
   
    this.appendMetrix(sessionId)
  }

  generateSessionId = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  appendMetrix = (sessionId) => {
    const { setUownSession } = this.props;

    window.seon.config({
      host: "seondf.com",
      session_id: sessionId,
      onSuccess: function(message) {
        console.log("success", message);
      },
      onError: function(message) {
        console.log("error", message);
      }
    })

    window.seon.getBase64Session().then((result) => {
      setUownSession(result);
    })
  }

  render() {
    return null;
  }
}

const mapDistpatchToProps = (dispatch) => {
  return {
    setUownSession: (value) => dispatch(setUownSession(value))
  }
}

UownMetric = connect(null, mapDistpatchToProps)(UownMetric)

export default UownMetric;
