import React, { PureComponent } from "react";
import "./styles.scss";
import AcimaLogo from "assets/img/IconsForCompare/acima_compare.svg";
import AffLogo from "assets/img/IconsForCompare/aff_compare.svg";
import FlexshopperLogo from "assets/img/IconsForCompare/fs_compare.svg";
import GreenWaveLogo from "assets/img/IconsForCompare/greenwave_compare.svg";
import PayTommorowLogo from "assets/img/IconsForCompare/pt_compare@2x.png";
import UownLogo from "assets/img/IconsForCompare/uown_compare.svg";
import OkinusLogo from "assets/img/IconsForCompare/okinus_compare.svg"
import SnapLogo from "assets/img/IconsForCompare/snap_compare.svg"


export const providerLogos = {
  paytomorrow: { img: PayTommorowLogo },
  flexshopper: { img: FlexshopperLogo },
  acima: { img: AcimaLogo },
  greenwave: { img: GreenWaveLogo },
  aff: { img: AffLogo },
  uown: { img: UownLogo },
  okinus: { img: OkinusLogo },
  snap: { img: SnapLogo }
  // add genesis logos
  // genesis: { img: GenesisLogo }
};

class CompareIcon extends PureComponent {

  render() {
    const { provider } = this.props
    return (
      <img src={providerLogos[provider].img} alt="alt"/>
    );
  }
}

export default CompareIcon;
