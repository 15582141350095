// income
export const INIT_WIDGET = 'INIT_WIDGET'
export const OPEN_WIDGET = 'OPEN_WIDGET'

// outcome
export const CANCEL_FLOW = 'CANCEL_FLOW'
export const FINISH_FLOW = 'FINISH_FLOW'
export const CLOSE_WIDGET = 'CLOSE_WIDGET'
export const INIT_WIDGET_DONE = 'INIT_WIDGET_DONE'
export const OPEN_WIDGET_DONE = 'OPEN_WIDGET_DONE'
export const LOG_EVENT = 'LOG_EVENT'