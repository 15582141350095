import * as ActionTypes from './actionTypes';

export const openModal = payload => ({
  type: ActionTypes.OPEN_MODAL,
  payload
});

export const closeModal = payload => ({
  type: ActionTypes.CLOSE_MODAL,
  payload
});