import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import "./styles.scss";

const colourStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  menuList: (styles) => ({ ...styles, maxHeight: 215 }),
  placeholder: (styles) => ({ ...styles, fontSize: 14 }),
  control: (styles, { isFocused }) => ({
    ...styles,
    border: isFocused ? "2px solid #333333" : "1.2px solid #979797",
    boxShadow: "none",
    width: "100%",
    backgroundColor: "#fff",
    height: "40px",
    borderRadius: "3px",
    color: "#000",
    fontSize: 16,
    "@media (max-width: 640px)": {
      height: "54px",
      borderRadius: "4px",
    },
    ":hover": {
      border: "1.2px solid #979797",
    },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: "none",
      color: "#000",
      fontSize: 16,
      fontFamily: isSelected ? "OpenSans-Bold" : "OpenSans-Regular",
      ":active": {
        ...styles[":active"],
        backgroundColor: "none",
      },
    };
  },
  singleValue: (styles) => ({
    ...styles,
    color: "#000",
    fontFamily: "OpenSans-Bold",
    fontSize: 16,
  }),
};

const SelectField = ({
  label,
  input,
  meta: { touched },
  value,
  options,
  onChange,
  skipBlur,
  customChange,
  placeholder,
  ...props
}) => {
  const combineChange = (value) => {
    onChange(value.value);
    customChange(value.value);
  };

  const onBlur = (event, newValue, previousValue) => {
    let blurHandler = props.onBlur;
    if (!newValue && !skipBlur) {
      blurHandler(previousValue);
    }
  };

  return (
    <div
      className={classNames("select-field-component flex flex--column", {
        invalid: touched && !value,
        valid: touched && value,
      })}
    >
      {label && (
        <label className="font-uppercased select-field-label">{label}</label>
      )}
      <div className="select-field-wrapper">
        <Select
          {...props}
          {...input}
          value={
            value === "" ? null : options.find((obj) => obj.value === value)
          }
          onBlur={onBlur}
          options={options}
          maxMenuHeight={215}
          placeholder={placeholder}
          styles={colourStyles}
          onChange={combineChange}
        />
      </div>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  valid: PropTypes.bool,
};

SelectField.defaultProps = {
  options: [],
  customChange: () => {},
};

export default SelectField;
