import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { nextStep } from "../../actions";
import { reduxForm } from 'redux-form';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

import "./styles.scss";

class SnapLeasePayment extends Component {
  state = {
    loading: false
  }

  submitForm = (e) => {
    const { nextStep } = this.props

    this.setState({loading: true})
    nextStep({step: 'flexshopperBankData'})
  }


  render() {
    return (
      <div className="genesis_confirmation_step step5__content flex--column paypair-step-content">
        <div>
          <span className="font-bold grey-color text-size-18">Before you apply</span>
        </div>
        <br/>
        <div className="snap-lease-to-own-and-payment">
          <div className="">
            <span className="font-bold">What Is Lease-to-Own Financing?</span>
            <br/>
            <br/>
            <span>Lease-to-own is an alternative method of financing. Snap buys the merchandise and leases it to you. Once you've fulfilled the
              terms of your agreement, the merchandise is yours.
            </span>
          </div>
          <br/>
          <br/>
          <div className="">
            <span className="font-bold">Standard Lease Costs and Payments</span>
            <br/>
            <ul>
              <li>Lease-to-own terms renew for a maximum of 12-18 months</li>
              <li>The lease includes a cost added to the cash price of the merchandise being leased, and your total
                cost could be more than double the cash price of the merchandise
              </li>
              <li>Your lease payments will be automatically deducted from the checking account provided in the lease
                application
              </li>
            </ul>
            <br/>
            <span className="font-bold">You Have Options With Snap</span>
            <br/>
            <ul>
              <li>You have options to gain ownership early and save on lease costs</li>
              <li>You may cancel your lease at any time by notifying and returning the merchandise to Snap</li>
            </ul>
          </div>
        </div>
        <BlueBtn
          customClass="step5__submit-btn"
          onClick={this.submitForm}
        >
          SUBMIT
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    finishApplication: values => dispatch(finishApplication(values)),
    nextStep: value => dispatch(nextStep(value))
  };
};

SnapLeasePayment = connect(mapStateToProps, mapDistpatchToProps)(SnapLeasePayment);
SnapLeasePayment = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(SnapLeasePayment);

export default SnapLeasePayment;
