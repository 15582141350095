import React, { Component} from "react";
import CheckMarkIcon from 'assets/img/check-mark.svg'
import { PERSONAL_INFO_TITLE } from '../../Step2/constants'
import PersonalInfoForm from "components/PersonalInfoForm";
import './styles.scss'

class PersonalInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  handleBoxOpen = () => {
    this.setState({ visible: true});
  }
  handleBoxClose = () => {
      this.setState({ visible: false});
  }

  handleEdit = () => {
    const { financialInfoBoxHandlerClose } = this.props;
    this.setState({ visible: true});
    financialInfoBoxHandlerClose()
  }

  render() {
    const { financialInfoBoxHandlerOpen } = this.props;

    return (
      <div className="personal-info-box">
        {!this.state.visible &&
          <div className="paypair-form personal-info-collapse">
            <div className="form-item-group">
              <div className="personal-info-text">
                <div className="personal-info-2">
                  <img className="personal-info-icon" src={CheckMarkIcon}/>
                </div>
                <div className="personal-info"><strong>{PERSONAL_INFO_TITLE}</strong></div>
              </div>
              <div className="edit-link">
                <a href="#" type="submit" onClick={this.handleEdit}>Edit</a>
              </div>
            </div>
          </div>
        }
        { this.state.visible &&
          <PersonalInfoForm
            financialInfoBoxHandlerOpen={financialInfoBoxHandlerOpen}
            handleBoxOpen={this.handleBoxOpen}
            handleBoxClose={this.handleBoxClose}
          />
        }
      </div>
    )
  }
}

export default PersonalInfo;
