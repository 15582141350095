import { call } from 'api/base';

export const AnalyticAPI = { 
  send_analytic_event(token, data, visit_id) {
    return call('trendings', 'POST', token, { trending: { type_of_screen: data, visit_id: visit_id }});
  },

  send_provider_analytic_event(token, data, provider, visit_id) {
    return call('trendings', 'POST', token, { trending: { type_of_screen: data, provider: provider, visit_id: visit_id }});
  }
}
