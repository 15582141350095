import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer } from "../../actions";
import { mergeRight } from "ramda";
import { OfferView, BuyoutInfo } from "components/DataDisplay";
import { reduxForm, Field, change } from "redux-form";
import PropTypes from "prop-types";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { appRequestSelector, trendingsVisitID } from "../../selectors";
import { createStructuredSelector } from "reselect";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { RadioField } from "components/Fields";
import { details } from "./details";
import { paymentFrequency } from "utils/helpers";

import "./styles.scss";

class OwnleaseOffer extends Component {
  state = {
    loading: false,
    selectedOffer: null,
    detailsShown: false,
    options: [],
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({
      options: offer.options,
    });
    const defaultSelected = offer.options[0];
    if (offer.options.length > 1) {
      this.props.dispatch(
        change(
          "form",
          "paymentPeriod",
          defaultSelected.payment_period.toString()
        )
      );
    }
    this.setSelected(defaultSelected);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      offer_id: option.offer_id,
      payment_schedule: offer.payment_schedule,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
      approved_amount: option.maxLoan
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  renderEarlyOptions = () => {
    const { selectedOption } = this.state;
    const promoAmount = selectedOption.cart_total > 1250 ? 100 : 50
    return (
      <div className="ownlease-early-ownership-options">
        <span class='options-header'>Early Ownership Options</span>
        <BuyoutInfo
          title={'Early Purchase Option:'}
          text={'The early payoff option allows you to become the owner of the leased item before the ownership date, by paying off in full, the cash price of the item, applicable sales tax, early payoff option fee, and applicable fees within the first 101 days of your lease-to-own agreement. Your exact early payoff option period is provided to you in your lease agreement, when exercising this option you must contact us at (855) 447-4921 to set up a payment. '}
        />
        <div className="second-buyoutinfo">
          <BuyoutInfo
            title={`$${promoAmount} Offer Details:`}
            text={
              <ul style={{margin: 0, paddingInlineStart: '24px'}}>
                <li>Keep your lease in good standing for 120 days after signing the lease agreement.
                </li>
                <li>{`The $${promoAmount} will be applied directly to your account after 120 days.`}</li>
                <li>Limit one offer per person.</li>
              </ul>
            }
            footnote={''}
          />
        </div>
      </div>
    )
  }

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <div className="offer-block-wrapper offer-block offer-block--100 flex">
        <div className="offer-item__title__pf">Payment Frequency:</div>
        <RadioField
          {...input}
          meta={meta}
          options={this.paymentScheduleOptions()}
          customChange={this.setPaymentSchedule}
        />
      </div>
    );
  };

  setPaymentPeriod = (value) => {
    const { offer } = this.props;

    const option = offer.options.find(
      (option) => option.payment_period === parseInt(value)
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Months`,
        key: option.payment_period.toString(),
      };
    });
  };

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'Payment Period',
          value: `${selectedOption.payment_period} Months`
        }]}
      />
    } else {
      return (<div className="offer-block offer-block--100">
        <div className="offer-item offer-item__column">
          <div className="offer-item__title">Payment Period:</div>
          <Field
            name="paymentPeriod"
            component={this.renderPaymentPeriodField}
          />
        </div>
      </div>)
    }
  }

  submitForm = (values) => {
    const { selectedOption } = this.state;
    const { selectOffer, logContinueEvent } = this.props;

    this.setState({ loading: true })
    selectOffer({selectedOffer: selectedOption});
    logContinueEvent()
  };


  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, valid, available, isExpanded, offer, toggleExpanded } = this.props;

    if (!selectedOption) {
      return '';
    }
    return (
      <div className="expanded-block-component__expanded-content ownlease">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={0}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={selectedOption.payment_period_rate.replace(/\s/g, '').toLowerCase()}
          cartTotal={selectedOption.cart_total}
          toggleExpanded={toggleExpanded}
        />
        {isExpanded && (

          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption, details())} />
              {this.renderPaymentPeriodBlock()}
            </div>

            {this.renderEarlyOptions()}

            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available || !valid}
              type="submit"
            >
              Continue with Own.lease
            </BlueBtn>
          </form>

        )}
      </div>
    );
  }
}

OwnleaseOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool
};

OwnleaseOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  appRequest: appRequestSelector(),
  visitID: trendingsVisitID()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

OwnleaseOffer = connect(mapStateToProps, mapDistpatchToProps)(OwnleaseOffer);

OwnleaseOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(OwnleaseOffer);

export default OwnleaseOffer;
