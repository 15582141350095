import React, { Component } from "react";
import { connect } from "react-redux";
import { finishApplication, cancelApplication } from "../../actions";
import PropTypes from "prop-types";
import Frame from "../Frame";
import { closeModal } from "containers/MultistepModal/actions";

class Flexshopper3Contract extends Component {
  receiveMessage = (event) => {
    const { finishApplication } = this.props
    switch (event.data) {
      //TODO refactor with real data
      case "paypossible_order_success":
        finishApplication();
        break;
    }
  }

  render() {
    const { contractUrl, hidden } = this.props
    return (
      <Frame
        hidden={hidden}
        contractUrl={contractUrl}
        onReceiveMessage={this.receiveMessage}
      />
    )
  }
}

Flexshopper3Contract.propTypes = {
  contractUrl: PropTypes.string
};

Flexshopper3Contract.defaultProps = {
  contractUrl: ""
};

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values)),
    cancelApplication: values => dispatch(cancelApplication(values)),
    closeModal: () => dispatch(closeModal())
  };
};

Flexshopper3Contract = connect(null, mapDistpatchToProps)(Flexshopper3Contract);

export default Flexshopper3Contract;

