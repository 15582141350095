import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { cartLoadingSelector } from "containers/MultistepForm/selectors";
import { IconChevronSingle, IconInfoBlue } from "components/Svgs";
import { BlueBtn } from "components/Controls";
import { addThousandsSeparator } from "utils/helpers";
import { editCart, toggleCartModal } from "containers/MultistepForm/actions";
import "./styles.scss";
import OrderSummary from "components/DataDisplay/OrderSummary";
import CartItem from "components/DataDisplay/CartItem";

class CartEdit extends PureComponent {
  state = {
    isExpanded: false,
    removedItems: [],
    lineItems: this.props.lineItems,
    valid: true,
  };

  componentDidMount = () => {
    const maxApprovedAmount = this.props.maxApprovedAmount

    if (maxApprovedAmount) {
      this.setState({
        valid: this.totalPrice() <= maxApprovedAmount
      })
    }
  }

  componentDidUpdate = () => {
    const { lineItems, removedItems } = this.state
    const maxApprovedAmount = this.props.maxApprovedAmount

    if (this.props.onItemsChange) {
      this.props.onItemsChange(lineItems, removedItems)
    }

    if (maxApprovedAmount) {
      this.setState({
        valid: this.totalPrice() <= maxApprovedAmount
      })
    }
  }

  totalPrice = () => {
    return this.itemsPriceSummary() + this.taxSummary()
  }

  itemsPriceSummary = () => {
    const { lineItems } = this.state

    if (lineItems.length !== 0) {
      return lineItems.map(i => i.price * i.quantity).reduce((a, b) => a + b);
    }

    return 0
  }

  taxSummary = () => {
    const { lineItems } = this.state;
    const { isTaxApplied } = this.props

    //TODO check taxes
    if (lineItems.length > 0 && !isTaxApplied) {
      return lineItems.map(i => i.taxAmount * i.quantity).reduce((a, b) => a + b);
    }

    return 0
  }

  toggleExpandView = () => {
    const { isExpanded } = this.state;

    this.setState(() => ({
      isExpanded: !isExpanded,
    }));
  };

  removeItem = (item) => {
    const { lineItems, removedItems } = this.state;

    this.setState({
      removedItems: removedItems.concat(item),
      lineItems: lineItems.filter(i => i !== item),
    })
  }

  undoItemRemoval = (item) => {
    const { lineItems, removedItems } = this.state;

    this.setState({
      lineItems: lineItems.concat(item),
      removedItems: removedItems.filter(i => i !== item),
    })
  }

  changeItemQuantity = (item, quantity) => {
    const { lineItems } = this.state;
    item.quantity = quantity
    const updatedItems = lineItems.map((obj) => {
      return obj.id === item.id ? item : obj;
    });

    this.setState({ lineItems: updatedItems })
  }

  generateUpdatedItems = () => {
    const { lineItems, removedItems } = this.state;
    const updatedItems = lineItems.map((item) => {
      return { id: item.id, quantity: item.quantity }
    })
    const deletedItems = removedItems.map((item) => {
      return { id: item.id, _destroy: true }
    })

    return updatedItems.concat(deletedItems)
  }

  submitCartChanges = () => {
    const {editCart, toggleCartModal} = this.props || {};
    if (this.isCartChanged()) {
      const updatedItems = this.generateUpdatedItems();
      return editCart({ items: updatedItems });
    } else {
      toggleCartModal(false);
    }
  }

  isCartChanged = () => {
    if (this.props.initialLineItems) {
      const initialLineItems = this.props.initialLineItems.sort((a, b) => a.id - b.id)
      const currentLineItems = this.state.lineItems.sort((a, b) => a.id - b.id)

      return JSON.stringify(initialLineItems) !== JSON.stringify(currentLineItems)
    } else {
      return true
    }
  }

  render() {
    const { isExpanded, removedItems, lineItems, valid } = this.state;
    const {
      discount,
      shipping,
      loading,
      saveButtonText,
      saveButtonClass,
    } = this.props

    return (
      <React.Fragment>
        <div className="items-block">
          <div className="expanded-block-component">
            {this.props.maxApprovedAmountBlock &&
              this.props.maxApprovedAmountBlock(valid, this.totalPrice())
            }
            <div className="cart-summary">
              <div className="flex-row flex__justify--between flex__align--center cart-summary-row">
                <div className="flex-col">
                  <span className="font-bold">Your Cart Total</span> ({lineItems.length} items)
                </div>
                <div className="flex-col">
                  ${addThousandsSeparator(this.totalPrice())}
                </div>
              </div>
              <div className="flex-col">
                <div className="expanded-block-component__expand-text expand-link">
                  <div
                    onClick={this.toggleExpandView}
                    isExpanded={isExpanded}
                  >
                    View Order Summary
                    <IconChevronSingle className={"svg-icon expand-icon " + (isExpanded ? 'icon-flip' : '')} />
                  </div>
                </div>
              </div>
              {isExpanded &&
                <OrderSummary
                  lineItems={lineItems}
                  totalPrice={this.itemsPriceSummary()}
                  taxSummary={this.taxSummary()}
                  shipping={shipping}
                  discount={discount}
                />
              }
            </div>

            <hr className="separator" />

            <div className="notice">
              <IconInfoBlue className="svg-icon info-icon" />
              <div className="notice-text">
                <span className="font-bold">Please note:</span> Prices below are based on Cash Price.
              </div>
            </div>
            {lineItems.map((item, i) => (
              <div id={item.id}>
                <CartItem
                  item={item}
                  removeItem={this.removeItem}
                  changeItemQuantity={this.changeItemQuantity}
                />
                {(i + 1 !== lineItems.length) &&
                  <hr className="separator" />
                }
              </div>
            ))}
            {(removedItems.length !== 0) &&
              <div>
                <hr className="separator" />
                <div className="removed-items">
                  {removedItems.map((item) => (
                    <div className="removed-item flex-row flex__justify--between flex__align--center">
                      <div className="flex-col image-name-col">
                        <div className="flex-col">
                          <img src={item.image} alt=""></img>
                        </div>
                        <div className="flex-col removed-item-name-col">
                          <p className="font-bold item-model">{item.brand} - {item.model}</p>
                        </div>
                      </div>
                      <div className="flex-col">
                        <a href="#"
                          className="undo-link"
                          onClick={() => this.undoItemRemoval(item)}
                        >
                          Undo
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }

          </div>
        </div>
        <div className={(saveButtonClass ? saveButtonClass : "save-button-container")}>
          <BlueBtn className="save-button button blue-button"
            loading={loading}
            onClick={() => this.submitCartChanges()}
            customClass="save-button button blue-button"
            disabled={!valid}
          >
            {saveButtonText}
          </BlueBtn>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: cartLoadingSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    editCart: (values) => dispatch(editCart(values)),
    toggleCartModal: (values) => dispatch(toggleCartModal(values))
  };
};

CartEdit = connect(mapStateToProps, mapDistpatchToProps)(CartEdit);

export default CartEdit;
