import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { required } from "utils/validations";
import { Field, reduxForm } from "redux-form";
import URLS from "utils/import_urls";
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import {
  appRequestSelector,
  trendingsVisitID,
  subscribingDataSelector,
} from "../../selectors";
import { createStructuredSelector } from "reselect";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { paymentFrequency } from "utils/helpers";

import "./styles.scss";

class AffOffer extends Component {
  state = {
    loading: false,
    selectedOffer: null,
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer, orderData } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: offer.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: orderData.total_price,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { selectOffer, offer, logContinueEvent } = this.props;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(
      token,
      "select_provider",
      "aff",
      visitID
    );
    logContinueEvent()
    this.setState({ loading: true });
    selectOffer({ selectedOffer: offer });
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return <CustomCheckbox meta={meta} {...input} />;
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <Field
            name="isAgreementAffChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />
          <div className="agreemnet-block__label">
            <span>
              I agree to the&nbsp;
              <a
                className="link"
                href={URLS.TERMS_AND_CONDITIONS_AFF}
                target="_blank"
              >
                Application Terms and Conditions
              </a>
              ,&nbsp;
              <a className="link" href={URLS.ARDITATION_AFF} target="_blank">
                Arbitration Provision
              </a>
              ,&nbsp;
              <a className="link" href={URLS.PRIVACY_AFF} target="_blank">
                Terms of Use & Online Privacy Policy
              </a>
              ,&nbsp; and to recive SMS messages (including by auto dialing
              system) to verify the mobile number I have provided.&nbsp; Message
              and data rates may apply.
            </span>
          </div>
        </div>
      </div>
    );
  };

  retailDisclaimer = () => {
    const { appRequest } = this.props;
    return (
      <div className="step-tooltip-block">
        {appRequest.residenceInfo.state === "NJ" ? (
          <span>
            Terms and conditions are subject to change at any time without prior
            disclosure or notice. Loans are originated by FinWise Bank, a
            Utah-chartered bank, Member FDIC. Products may vary by location.
          </span>
        ) : (
          <span>
            A retail installment agreement is not a loan.  You may enter into a
            Retail Installment Agreement with a merchant to purchase item(s) and/or
            service(s). AFF may purchase the Agreement from the Merchant. You may
            choose to take an in-stock item today, schedule delivery, or complete a
            service and pay over time. A down payment may be required. You can save
            money on finance charges if you pay off in full during the Early Payoff
            Discount Period. See contract for more details if you move forward with
            American First Finance.
          </span>
        )}
      </div>
    );
  };

  renderFootter = () => {
    const { offer, appRequest } = this.props;
    if (
      (offer.options[0].apr >= 0 && appRequest.residenceInfo.state === "PA") ||
      appRequest.residenceInfo.state === "NJ"
    ) {
      return this.retailDisclaimer();
    } else if (appRequest.residenceInfo.state === "CA") {
      return "";
    }

    return (
      <BuyoutInfo
        title={""}
        text={
          "A lease is not a loan or credit transaction. It has several names: lease, lease-to-own, rent-to-own, or rental-purchase. With a lease, you select the item(s) and a payment solution company, such as American First Finance (AFF). AFF purchases the item(s) from a Merchant. You take the item(s) home.  AFF retains ownership until you pay for the item(s) plus rental fees. Other fees may be included, such as late, non-sufficient fund, delivery, and optional Liability Damage Waiver fees. As a result, the total amount you pay for leasing an item is more than the retail cost. You can save money on rental fees if you pay the balance in full early. See contract for more details if you move forward with American First Finance."
        }
      />
    );
  };

  renderAzStatePaymentCreditReporting = () => {
    return <div>* Payment Credit Reporting is not applicable to Arizona</div>;
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, valid, available, isExpanded, offer, appRequest } =
      this.props;

    if (!selectedOption) {
      return "";
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          showPlanTypeTooltip
          available={available}
          isExpanded={isExpanded}
          dueToday={0}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={offer.options[0].apr >= 0 && appRequest.residenceInfo.state === "PA" ? 'Retail Installment Plan' : selectedOption.type}
        />
        {isExpanded && (
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="aff-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView
                offerData={buildOfferData(
                  selectedOption,
                  {},
                  {},
                  appRequest.residenceInfo.state
                )}
              />
            </div>

            {appRequest.residenceInfo.state === "AZ" &&
              this.renderAzStatePaymentCreditReporting()}
            {this.renderFootter()}

            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available || !valid}
              type="submit"
            >
              Continue with AFF
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

AffOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

AffOffer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  appRequest: appRequestSelector(),
  visitID: trendingsVisitID(),
  orderData: subscribingDataSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
  };
};

AffOffer = connect(mapStateToProps, mapDistpatchToProps)(AffOffer);

AffOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AffOffer);

export default AffOffer;
