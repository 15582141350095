import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getNextPaycheckDate } from "../../../MultistepForm/Step2/input_helpers"
import "./styles.scss";
import { FINANCIAL_INFO_TITLE, REQUIRED_FIELD_TITLE } from "./constants";
import { continueInitApplication, initApplication } from "containers/MultistepForm/actions";
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { formValuesSelector, loadingSelector, trendingsVisitID } from "containers/MultistepForm/selectors";
import FinancialInfoForm from "components/FinancialInfoForm";


class FinancialInfo extends Component {
  state = {
    additionalData: {},
    additionalDataValid: false,
    showPayoutDayOption: false,
    payoutDay: 'first',
    payFrequency: '',
    clearLastPaydate: false
  };

  render() {
    const { visibleFinancialInfo } = this.props
    return (
      <div className="financial-border">
        <div className="step2__form paypair-form">
          <div className="financial-info-string">
            <div className={visibleFinancialInfo ? "financial-info-2" : "financial-info-2-passive"}>2</div>
            <div className={visibleFinancialInfo ? "financial-info" : "financial-info-passive"}>
              <strong>{FINANCIAL_INFO_TITLE}</strong>
            </div>
          </div>
          {visibleFinancialInfo ? (
            <div className="financial-form-block">
              <div className="require-text-financial">{REQUIRED_FIELD_TITLE}</div>
              <FinancialInfoForm
                  setValue={this.setState.bind(this)}
                  payoutDay={this.state.payoutDay}
                  values={this.props}
                  clearLastPaydate={this.state.clearLastPaydate}
                  showPayoutDayOption={this.state.showPayoutDayOption}
                  payoutFrequency={this.state.payoutFrequency}
                  prequalification
                />
            </div>
            ) : null 
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector()(state),
  values: formValuesSelector()(state),
  loading: loadingSelector()(state),
  visitID: trendingsVisitID()(state)
});

const mapDistpatchToProps = (dispatch) => {
  return {
    initApplication: (values) => dispatch(initApplication(values)),
    continueInitApplication: values => dispatch(continueInitApplication(values))
  };
};

FinancialInfo = connect(mapStateToProps, mapDistpatchToProps)(FinancialInfo);

FinancialInfo = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(FinancialInfo);

export default FinancialInfo;
