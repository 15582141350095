import React, { Component } from "react";
import { reduxForm, Field, change } from "redux-form";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import { RadioField } from "components/Fields";
import {paymentPeriodSelector, trendingsVisitID} from "../../selectors";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { addThousandsSeparator, paymentFrequency, capitalizeFirstletter } from "utils/helpers";

import "./styles.scss";

class MultiOffer extends Component {
  state = {
    loading: false,
    options: [],
    selectedOption: {
      provider: "",
      type: "",
      offer_id: null,
      payment_period: null,
      payment_period_rate: null,
      first_period: null,
      first_period_rate: null,
      last_period: null,
      last_period_rate: null,
      buyout: false,
      buyout_rate: null,
      initial_payment: null,
      total_payment: null
    },
  };

  componentDidMount = () => {
    const { offer } = this.props;

    const sortedOptions = offer.options.sort((a, b) => {
      return a.payment_period - b.payment_period;
    });

    this.setState({
      options: sortedOptions,
    });

    const defaultSelected = offer.options[offer.options.length - 1];
    if (offer.options.length > 1) {
      this.props.dispatch(
        change(
          "form",
          `${offer.specific_provider_name}paymentPeriod`,
          defaultSelected.payment_period.toString()
        )
      );
    }
    this.setSelected(defaultSelected);
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      specific_provider_name: offer.specific_provider_name,
      payment_schedule: offer.payment_schedule,
      cart_total: offer.cart_total,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_period === parseInt(value)
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Months`,
        key: option.payment_period.toString(),
      };
    });
  };

  submitForm = (e) => {
    const { selectOffer, logContinueEvent } = this.props;
    const { selectedOption } = this.state;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', 'paytomorrow', visitID);
    logContinueEvent()

    e.preventDefault();
    this.setState({ loading: true })
    selectOffer({ selectedOffer: selectedOption, body: { offer_id: selectedOption.offer_id } });
  };

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'Payment Period',
          value: `${selectedOption.payment_period} Months`
        }]}
      />
    } else {
      return (
        <div className="offer-block offer-block--100">
          <div className="offer-item offer-item__column block-padding">
            <div className="offer-item__title">Payment Period:</div>
            <Field
              name={`${offer.specific_provider_name}paymentPeriod`}
              component={this.renderPaymentPeriodField}
            />
          </div>
        </div>
      )
    }
  }

  renderNote = () => {
    const { selectedOption } = this.state;

    if (selectedOption.specific_provider_name == 'aff') {
      return <div className="initial_payment_note">The approval & amount are subject to remaining underwriting
        criteria. Amount may vary.</div>;
    }
  }

  renderHeaderPaymentPeriod = () => {
    const { selectedOption } = this.state
    const { offer } = this.props

    if (offer.specific_provider_name === 'paytomorrow' || offer.specific_provider_name === 'bread') {
      return (`up to ${selectedOption.payment_period} Months`)
    } else {
      return (`${selectedOption.payment_period} Months`)
    }
  }

  render() {
    const { selectedOption, loading } = this.state;
    const { available, isExpanded, offer } = this.props;

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={offer.specific_provider_name === 'bread' ? 0 : addThousandsSeparator(selectedOption.initial_payment)}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${this.renderHeaderPaymentPeriod()}`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form
            onSubmit={(e) => this.submitForm(e)}
            className="paytomorrow-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption, false, false, '', offer.specific_provider_name)}  />
              {this.renderPaymentPeriodBlock()}
              <OfferView offerData={buildOfferData(selectedOption, false, true, '', offer.specific_provider_name)}  />
            </div>
            {selectedOption.type == "Lease to Loan" && selectedOption.buyout && <BuyoutInfo
              title={"Early Buyout Option"}
              text={`Payoff in 90 days and only pay a ${selectedOption.buyout_rate}% fee`}
            />}

            {selectedOption.specific_provider_name == 'greenwave' && <BuyoutInfo
              title={"120 Day Payment Option"}
              text={`Simply pay the cash price within 100 days after the lease is completed.`}
            />}

            {selectedOption.specific_provider_name == 'katapult' && (
              <>
                <BuyoutInfo title={"Early Purchase Option"} text={'Save more with our 90 day early purchase option!'} />
                <BuyoutInfo title={"No Late Fees!"} text={''} />
              </>
            )}

            {/* {this.renderZeroOfferNote()} */}

            {this.renderNote()}
            <BlueBtn
              customClass="step3__recommended-content-btn"
              disabled={!available}
              loading={loading}
              type="submit"
            >
              Continue with {capitalizeFirstletter(offer.specific_provider_name)}
            </BlueBtn>
          </form>)}
      </div>
    );
  }
}
MultiOffer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

MultiOffer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  paymentPeriod: paymentPeriodSelector(),
  visitID: trendingsVisitID(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

MultiOffer = connect(mapStateToProps, mapDispatchToProps)(MultiOffer);

MultiOffer = reduxForm({
  form: "form",
})(MultiOffer);

export default MultiOffer;
