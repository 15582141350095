import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import {
  loadingSelector,
  activeStepDataSelector,
  appRequestSelector,
} from "containers/MultistepForm/selectors";
import {backToOffers, selectOffer} from 'containers/MultistepForm/actions';

import { selectedOfferSelector } from "../../../selectors";

import "./styles.scss";
import ProgressiveLogo from "../../../../../assets/img/progressive-logo.png";
import { logEvent } from "../../../../EmbeddableWidget/actions";
import { addThousandsSeparator } from "../../../../../utils/helpers";

class ApprovedAppScreen extends Component {
  state = {
    loadingLease: false,
  };

  handleBackButton = () => {
    const { backToOffers, logEvent } = this.props;
    backToOffers();
    logEvent({ name: "view other options" });
  };

  submit = () => {
    const { offer } = this.props;
    this.setState({loadingLease: true})
    this.props.selectOffer({offer});
  };

  render() {
    const { loading, offer } = this.props;
    const { loadingLease} = this.state;

    const approvedAmount = `$${addThousandsSeparator(offer.approval_amount)}`;
    const cartTotal = `$${addThousandsSeparator(offer.cart_total)}`;
    const filteredPricing = offer?.pricing_estimate?.termMonthEstimates.filter(item => [12, 3, 6].includes(item.paymentMonth));
    return (
      <div className="expanded-block-component bank-data-form-container">
        <div className="bank-data-form-header">
          <img src={ProgressiveLogo} alt="Progressive Logo" />
          <h1>Congrats, You're Approved</h1>
          <p>
            {`You've been approved to lease items up to ${approvedAmount} in
            cash price with Progressive Leasing.`}
          </p>

          <div className="approval-amount">
            <p className="font-bold">
              Approval Amount{" "}
              <span className="font-bold">{approvedAmount}</span>
            </p>
            {offer.lease_id && <p>{`Lease ID: ${offer.lease_id}`}</p>}
          </div>

          <div className="overview">
            <p className="font-bold">Overview of Your Purchase</p>
            <p>
              How often you’re paid and your cart subtotal make up your payment
              schedule. You’ll automatically be set up for a 12-month lease.
            </p>
            {cartTotal &&
              <p className="gray-highlight">
                Cart subtotal <span>{cartTotal}</span>
              </p>
            }
          </div>

          <div className="lease-to-own active">
            <p className="font-bold">12-month standard lease-to-own</p>
            <p>
              Bi-weekly payments*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[2].paymentAmount
              )}`}</span>
            </p>
            <p className="font-bold">
              Lease-to-own total cost*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[2].cumulativeAmountPaid
              )}`}</span>
            </p>
          </div>

          <div className="overview">
            <p className="font-bold">The earlier you pay, the more you save</p>
            <p>
              After you receive your items, call Progressive Leasing at (877)
              898-1970 to set up an early purchase option.
            </p>
          </div>
          <div className="lease-to-own inactive">
            <p className="font-bold">3-month standard lease-to-own</p>
            <p>
              Bi-weekly payments*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[0].paymentAmount
              )}`}</span>
            </p>
            <p className="font-bold">
              Lease-to-own total cost*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[0].cumulativeAmountPaid
              )}`}</span>
            </p>
          </div>
          <div className="lease-to-own inactive">
            <p className="font-bold">6-month standard lease-to-own</p>
            <p>
              Bi-weekly payments*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[1].paymentAmount
              )}`}</span>
            </p>
            <p className="font-bold">
              Lease-to-own total cost*{" "}
              <span>{`$${addThousandsSeparator(
                filteredPricing?.[1].cumulativeAmountPaid
              )}`}</span>
            </p>
          </div>
          <p className="font-italic">
            *Applicable taxes will be added to all payments and collected by
            Progressive Leasing.
          </p>
        </div>

        <div className="footer">
          <BlueBtn loading={loading || loadingLease} onClick={this.submit}>
            Sign Lease & Place Order
          </BlueBtn>
          <BlueBtn customClass="outlined-btn" onClick={this.handleBackButton}>
            Back
          </BlueBtn>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: loadingSelector()(state),
  offer: activeStepDataSelector()(state),
  selectedOffer: selectedOfferSelector()(state),
  appRequest: appRequestSelector()(state),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    backToOffers: () => dispatch(backToOffers()),
    logEvent: (value) => dispatch(logEvent(value)),
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

ApprovedAppScreen = connect(
  mapStateToProps,
  mapDistpatchToProps
)(ApprovedAppScreen);

export default ApprovedAppScreen;
