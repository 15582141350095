import { capitalizeFirstletter, addThousandsSeparator } from "utils/helpers";

// selectedOffer = {
// provider: ''
// payment_schedule: ''
// initial_payment: ''
// first_period: '',
// first_period_rate: '',
// last_period: '',
// last_period_rate: ''
// payment_period_rate: ''
// }

// FIXME: think how to avoid this
export const buildOfferData = (selectedOffer, details, part, state, specific_provider_name) => {
  switch (selectedOffer.provider) {
    case 'paytomorrow':
      if (part === true) {
        return buildPaytomorrowSecondBlockOfferData(selectedOffer, specific_provider_name);
      }
      return buildPaytomorrowFirstBlockOfferData(selectedOffer, specific_provider_name);
    case 'flexshopper':
      return selectedOffer.new_flexshopper ?
        buildFlexshopper3OfferData(selectedOffer, part)
        : buildFlexshopperOfferData(selectedOffer, details);
    case 'okinus':
      return buildOkinusOfferData(selectedOffer);
    case 'genesis':
      return buildGenesisOfferData(selectedOffer);
    case 'uown':
      if (part === true) {
        return buildUownOfferSecondBlockData(selectedOffer);
      }
      return buildUownOfferFirstBlockData(selectedOffer);
    case 'aff':
      return buildAffOfferData(selectedOffer, state);
    case 'acima':
      return buildAcimaOfferData(selectedOffer);
    case 'snap':
      return buildSnapOfferData(selectedOffer, details);
    case 'koalafi':
      return buildKoalafiOfferData(selectedOffer, details);
    case 'ownlease':
      return buildOwnleaseOfferData(selectedOffer, details);
    case 'kornerstone':
      return buildKornerstoneOfferData(selectedOffer, details);
    case 'progressive':
      return buildProgressiveOfferData(selectedOffer, details);
    default:
      break;
  }
}

export const buildUserFriendlyPaymentSchedule = (payment_schedule) => {
  if (!payment_schedule) {
    return '';
  }
  switch(payment_schedule.toLowerCase().replace(/-|_/g, '')) {
    case 'monthly':
      return 'Monthly'
    case 'weekly':
      return 'Weekly'
    case 'biweekly':
      return 'Every two weeks'
    case 'semimonthly':
      return 'Semi-monthly'
    default:
      return '';
  }
}

export const buildPaytomorrowFirstBlockOfferData = (selectedOffer, specific_provider_name) => {
  const isBreadProvider = specific_provider_name === 'bread';
  const per = buildUserFriendlyPaymentSchedule(
    (isBreadProvider && selectedOffer.paymentInterval)
      ? selectedOffer.paymentInterval
      : selectedOffer.payment_schedule
  );
  const aprPresent = isBreadProvider && !!selectedOffer.apr;

  let properties = [];
  properties.push([{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.maxLoan)}`
  }])
  properties.push([{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }])
  properties.push([{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }])
  // look at this
  if (selectedOffer.first_period && selectedOffer.first_period_rate &&
    selectedOffer.last_period && selectedOffer.last_period_rate) {
    properties.push([{
      title: `First ${selectedOffer.first_period} Months`,
      value: `$${addThousandsSeparator(selectedOffer.first_period_rate)}`,
      note: `/ ${per}`
    }])
    properties.push([{
      title: `Last ${selectedOffer.last_period} Months`,
      value: `$${addThousandsSeparator(selectedOffer.last_period_rate)}`,
      note: `/ ${per}`
    }])
  } else {
    properties.push([{
      title: `Recurring Payment`,
      value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
      note: `/ ${per}`
    }])
  }
  properties.push([{
    title: 'Payment Frequency',
    value: per
  }])
  if (aprPresent) {
    properties.push([{
      title: 'Estimated APR',
      value: `${(selectedOffer.apr * 100).toFixed(2) +'%'}`
    }])
  }
  return properties;
};

export const buildPaytomorrowSecondBlockOfferData = (selectedOffer, specific_provider_name) => {
  let properties = [];
  const isBreadProvider = specific_provider_name === 'bread';
  properties.push([{
    title: 'Required To Complete',
    value: isBreadProvider ? 'Accept Agreement' : 'Credit or Debit Card'
  }])
  properties.push([{
    title: 'Payment Credit Reporting',
    value: isBreadProvider ? 'Yes' : 'No'
  }])
  return properties;
};

export const buildFlexshopperOfferData = (selectedOffer, details) => {
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
    details: ''
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
    details: details,
  }],[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Weeks`
  }],[{
    title: 'Required To Complete',
    value: 'Checking Account and Routing Number'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'No'
  }]];
};

export const buildFlexshopper3OfferData = (selectedOffer, part) => {
  if (part) {
    return [[{
      title: 'Required To Complete',
      value: 'Checking Account and Routing Number'
    }],[{
      title: 'Payment Credit Reporting',
      value: 'No'
    }]]
  }

  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
    details: ''
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: selectedOffer.payment_period_rate,
  }],[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }]];
};

export const buildFlexshopperOfferPaymentDetailsData = (selectedOffer, preview = false) => {
  return [[{
    title: 'Cash Price',
    value: `$${addThousandsSeparator(selectedOffer.cash_price)}`,
    titleTooltip: "You will own the item(s) and not have to pay any additional amount if you pay the full Cash Price (the Cash Price is the price we charge for a cash sale of the item (or $50 more than the price the merchant you select charges) plus sales taxes and may be higher than the price you would pay if you were to purchase the item(s) directly; if you rent multiple items at the same time, we reduse the Cash Price (and increase the Cost of Rental) by $50 for the second and each subsequent item you rent which makes it less expensive to purchase the items before the end of the term of the Lease). After 90 days, you also have an Early Purchase Option that allow you to own your item(s) for less. Simply refer to the table in your Lease to look up the Early Purchase Option amount after each payment date."
  }],[{
    title: 'Cost Of Rental',
    value: `$${addThousandsSeparator(selectedOffer.cost_of_rental)}`,
    titleTooltip: '"Cost of Rental" is the difference between the Total of Payments to Own and the Cash Price of the property/merchandise.'
  }],[{
    title: 'Total Payments To Own',
    value: `$${addThousandsSeparator(selectedOffer.total_payments_to_own)}`,
    titleTooltip: '"Total of Payments to Own" is the amount you will pay to own the leased property/merchandise unless you exercise your 90 days Same as Cash purchase option. After 90 days, an early purchase option is still available. See lease for details.'
  }]]
};

export const buildOkinusOfferData = (selectedOffer, preview = false) => {
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
  }],[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`,
  }],[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: 'Required To Complete',
    value: 'Credit or Debit Card'
  }]]
}

export const buildGenesisOfferData = (selectedOffer, preview = false) => {
  return [[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: 'Required To Complete',
    value: 'Credit or Debit Card'
  }],[{
    title: 'Minimum Payment Amount',
    titleTooltipIcon: "The monthly minimum payment is 5% of the outstanding balance",
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
    note: `/ month`
  }]]
}

export const buildUownOfferFirstBlockData = (selectedOffer, preview = false) => {
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`
  }],[{
    title: 'Payment Frequency',
    value: `${capitalizeFirstletter(selectedOffer.payment_schedule)}`
  }]]
}

export const buildUownOfferSecondBlockData = (selectedOffer, preview = false) => {
  return [[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`,
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Required To Complete',
    value: 'Credit or Debit Card'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'Yes',
    tooltip: 'Uown reports payments to TransUnion which can improve your credit score.'
  }]]
}

export const buildAffOfferData = (selectedOffer, state) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  const azState = state === 'AZ'
  const njState = state === 'NJ'
  const aprPresent = state === 'PA' && selectedOffer.apr >= 0

  let properties = []
  properties.push(
    [{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }],[{
      title: 'Type of Plan',
      value: typeOfPlanValue(selectedOffer, state)
    }],[{
      title: 'Cart Total',
      value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
    }],[{
      title: 'Estimated Recurring Payment',
      value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`
    }],[{
      title: 'Payment Frequency',
      value:  capitalizeFirstletter(formattedPaymentSchedule)
    }],[{
      title: 'Payment Period',
      value: `${selectedOffer.payment_period} Months`,
    }],[{
      title: 'Required To Complete',
      value: 'Debit Card'
    }]
  )
  if (aprPresent) {
    properties.push([{
      title: 'Estimated APR',
      value: `${selectedOffer.apr+'%'}`
    }])
  }
  if (njState) {
    properties.push([{
      title: 'Estimated Origination Fee',
      value: `$${selectedOffer.origination_fee}`
    }])
  }
  if (azState) {
    properties.push([{
      title: 'Payment Credit Reporting',
      value: 'No *'
    }])
  }
  return properties;
}

export const typeOfPlanValue = (selectedOffer, state) => {
  const njState = state === 'NJ'
  const aprPresent = state === 'PA' && selectedOffer.apr >= 0

  if (njState) {
    return 'Bank Loan originated by FinWise Bank'
  }

  if (aprPresent) {
    return 'Retail Installment Plan'
  }

  return selectedOffer.type
}

export const buildAcimaOfferData = (selectedOffer, preview = false) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`
  }],[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(formattedPaymentSchedule)
  }],[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`,
  }],[{
    title: 'Required To Complete',
    value: 'Credit or Debit Card<br/>Drivers License and State ID<br/>Checking and Routing Numbers'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'No'
  }]]
}

export const buildSnapOfferData = (selectedOffer, details) => {
  const stds = selectedOffer.row_data.paymentPlanSummary.stbs
  return [[{
    title: 'Prequalified amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
    details: ''
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`,
    details: details.lease_to_own
  }],[{
    title: stds ? 'Recurring Payment (estimate)' : 'Recurring Payment',
    value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
    details: details.payment
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Payment Frequency',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: 'Months to Ownership',
    value: `${selectedOffer.payment_period} Months`
  }],[{
    title: 'Required To Complete',
    value: 'Checking and Routing Numbers'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'Yes'
  }]]
};

export const buildKoalafiOfferData = (selectedOffer, details) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  if (selectedOffer.type !== 'Lease to Own') {
    return [[{
      title: 'Approval Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }], [{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`,
      tooltip: details.lease_to_own
    }],[{
      title: 'Cart Total',
      value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
    }],[{
      title: 'Estimated Recurring Payment',
      value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
      tooltip: details.lease_payment
    }], [{
      title: 'Estimated APR',
      value: '32.99%',
    }], [{
      title: 'Payment Frequency',
      value: capitalizeFirstletter(formattedPaymentSchedule)
    }], [{
      title: 'Required To Complete',
      value: '&bull; Debit Card</br>&bull; Checking Account / Routing number'
    }], [{
      title: 'Payment Credit Reporting',
      value: 'Yes'
    }]]
  } else {
    return [[{
      title: 'Prequalification Amount',
      value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`
    }], [{
      title: 'Type of Plan',
      value: `${selectedOffer.type}`,
    }],[{
      title: 'Cart Total',
      value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
    }],[{
      title: 'Estimated Payment',
      value: `$${selectedOffer.payment_period_rate}`,
      details: details.loan_payment
    }], [{
      title: 'Payment Frequency',
      value: capitalizeFirstletter(formattedPaymentSchedule)
    }], [{
      title: 'Required To Complete',
      value: '&bull; Debit Card</br>&bull; Checking Account / Routing number'
    }], [{
      title: 'Payment Credit Reporting',
      value: 'Yes'
    }]]
  }
}

export const buildOwnleaseOfferData = (selectedOffer) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  return [[{
    title: 'Approval Amount',
    value: `${selectedOffer.approved_amount}`
  }], [{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `${selectedOffer.payment_period_rate}`
  }], [{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`,
  }], [{
    title: 'Required To Complete',
    value: 'Credit or Debit Card'
  }], [{
    title: 'Payment Credit Reporting',
    value: 'Yes'
  }]]
}

export const buildKornerstoneOfferData = (selectedOffer, details) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
    details: ''
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment',
    value: `$${selectedOffer.payment_period_rate}`,
    details: details,
  }],[{
    title: 'Payment Frequency',
    value: capitalizeFirstletter(formattedPaymentSchedule)
  }],[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`
  }],[{
    title: 'Required To Complete',
    value: 'Checking / Routing Number'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'No'
  }]]
};

export const buildProgressiveOfferData = (selectedOffer, details) => {
  const formattedPaymentSchedule = buildUserFriendlyPaymentSchedule(selectedOffer.payment_schedule)
  return [[{
    title: 'Approval Amount',
    value: `$${addThousandsSeparator(selectedOffer.approved_amount)}`,
    details: ''
  }],[{
    title: 'Type of Plan',
    value: `${selectedOffer.type}`
  }],[{
    title: 'Cart Total',
    value: `$${addThousandsSeparator(selectedOffer.cart_total)}`,
  }],[{
    title: 'Recurring Payment (+ taxes)',
    value: `$${parseFloat(selectedOffer.payment_period_rate).toFixed(2)} / ${formattedPaymentSchedule}`,
    details: details,
  }],[{
    title: 'Payment Period',
    value: `${selectedOffer.payment_period} Months`
  }],[{
    title: 'Required To Complete',
    value: 'Credit or debit card and banking information'
  }],[{
    title: 'Payment Credit Reporting',
    value: 'No'
  }],[{
    title: 'Lease to own total cost',
    value: `$${addThousandsSeparator(selectedOffer.cost_of_lease)}`,
  }],[{
    title: '12 Month lease to own total',
    value: `$${addThousandsSeparator(selectedOffer.cumulative_amount_paid)}`,
  }]]
};

export const paymentScheduleOptionsMapper = (value) => {
  switch (value) {
    case 'biWeekly':
      return 'Bi-Weekly'
    default:
      return 'Weekly';
  }
}
