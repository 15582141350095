import { call } from 'api/base'

export const ProvidersApi = {
  updateAgreement(token, data) {
    return call('providers/snap/update_agreement', 'POST', token, data)
  },
  confirmation(token, data) {
    return call('providers/snap/contract', 'POST', token, data)
  },
  active(token) {
    return call('providers', 'GET', token)
  },
  affAgreements(token) {
    return call('providers/aff_agreements', 'GET', token)
  },
  updateProgressive(token, data) {
    return call('providers/progressive/update_application', 'POST', token, data)
  }
}
