import React, { Component } from "react";
import PropTypes from "prop-types";
import { BlueBtn, CustomCheckbox } from "components/Controls";
import { Field, reduxForm } from "redux-form";
import { required } from "utils/validations";
import { formValueSelector } from "redux-form";
const selector = formValueSelector("flexshopper_contract_form");
import { connect } from "react-redux";
import { finishApplication } from "../../actions";
import { Spinner } from "components/DataDisplay";
import URLS from "utils/import_urls";
import { downloadPdf, setHeight } from "../helpers";
import PrintIcon from 'assets/img/print-icon.svg';
import "./styles.scss";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

class FlexshopperContract extends Component {
  state = {
    loading: false,
    submitLoading: false,
    bottomIsReached: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return <CustomCheckbox meta={meta} {...input} />;
  };

  submit = (e) => {
    this.setState({ submitLoading: true });
    this.props.finishApplication();
  };

  downloadPdf = () => {
    downloadPdf(this);
  };

  showSubmit = () => {
    this.setState({ bottomIsReached: true });
  };

  hideSubmit = () => {
    this.setState({ bottomIsReached: false });
  };

  renderBottomSnippet = () => {
    const { isAgreementChecked } = this.props;
    const { bottomIsReached, submitLoading } = this.state;
    if (bottomIsReached) {
      return (
        <div>
          <div className="privacy-policy-agreement flex flex__align--center">
            <Field
              name="isAgreementChecked"
              validate={[required]}
              component={this.renderAgreementCheckbox}
            />
            <div className="privacy-policy-agreement__label">
              Check here to "Complete your lease order"
            </div>
          </div>
          {isAgreementChecked && (
            <div>
              <div className="aggreement_content">
                BY CHECKING HERE AND CLICKING "COMPLETE YOUR LEASE ORDER", YOU
                AGREE TO ALL THE TERMS OF THE LEASE, INCLUDING PAYMENT
                AUTHORIZATION, ARBITRATION, AND CONSENT TO RECEIPT OF ELECTRONIC
                COMMUNICATIONS. A COPY OF YOUR LEASE WILL BE EMAILED TO YOU UPON
                SIGNING FOR YOUR RECORDS AND WILL ALSO BE AVAILABLE TO YOU
                WITHIN YOUR CUSTOMER PORTAL ON OUR WEBSITE (
                <a href={URLS.MAIN_FS} target="_blank">
                  WWW.FLEXSHOPPER.COM
                </a>
                ).
              </div>
              <BlueBtn
                onClick={this.submit}
                loading={submitLoading}
                disabled={!isAgreementChecked || submitLoading}
              >
                COMPLETE YOUR LEASE ORDER
              </BlueBtn>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="privacy-policy-agreement privacy-policy-agreement-note">
            Scroll to Review and Sign
          </div>
        </div>
      );
    }
  };

  handleScroll = (e) => {
    if (e.target.scrollTop >= e.target.scrollHeight - 500) {
      this.setState({ bottomIsReached: true });
    }
  };

  // calculateIframeHeight = () => {
  //   return document.getElementsByClassName('multistep-form')[0].clientHeight - 80
  // }

  render() {
    const { contract, hidden } = this.props;
    const { loading } = this.state;

    const content = window.atob(contract);

    return (
      <div className="flexshopper_contract_step" style={{display: hidden ? 'none' : 'inherit'}}>
        <div onClick={this.downloadPdf} className="download_pdf_btn">
          <span>
            <img src={PrintIcon} alt={"print"} />
          </span>
          <span>Download Pdf </span>
          {loading && <Spinner />}
        </div>
        <SimpleBar
          style={{ maxHeight: 350, width: "100%" }}
          scrollableNodeProps={{ onScroll: this.handleScroll }}
        >
          <div id="contract-iframe-wrapper">
            <iframe
              onLoad={(e) => setHeight(e)}
              srcDoc={content}
              id="contract-iframe"
              width="100%"
              frameBorder="0"
              border="0"
            ></iframe>
          </div>
        </SimpleBar>
        {this.renderBottomSnippet()}
      </div>
    );
  }
}

FlexshopperContract.propTypes = {
  contract: PropTypes.string,
};

FlexshopperContract.defaultProps = {
  contract: "",
};

const mapStateToProps = (state) => ({
  isAgreementChecked: selector(state, "isAgreementChecked"),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    finishApplication: (data) => dispatch(finishApplication(data)),
  };
};

FlexshopperContract = connect(
  mapStateToProps,
  mapDistpatchToProps
)(FlexshopperContract);
FlexshopperContract = reduxForm({
  form: "flexshopper_contract_form",
  initialValues: { isAgreementChecked: false },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(FlexshopperContract);

export default FlexshopperContract;
