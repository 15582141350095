import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { BlueBtn } from "components/Controls";
import {
  activeProviders, affAgreements,
  affiliatedProviderSelector,
  appRequestSelector,
  formValuesSelector,
  loadingSelector,
  trendingsVisitID
} from '../selectors';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { AnalyticAPI } from "api/analytic";
import { ApplicationRequestAPI } from "api/application_request";
import { renderPrivacyPolicy } from "../privacy_policy"
import { renderHeader } from "../header"
import PersonalInfo from './PersonalInfo'
import FinancialInfo from "./FinancialInfo";
import { onlyNumbers } from "utils/normalize";
import { getNextPaycheckDate } from "../Step2/input_helpers";
import { initApplication, continueInitApplication } from "../actions";
import { prequalificationCart } from "utils/stubData.js";
import Breadcrumb from "components/Breadcrumb";
import Secure from "assets/img/lock_secure.png"
import {ProvidersApi} from '../../../api/providers';
import {logEvent} from '../../EmbeddableWidget/actions';

const selector = formValueSelector('form')

const breadcrumbMap = [
  { key: "Personal Information", number: 1 },
  { key: "Financial Information", number: 2 },
  { key: "Results", number: 3 }
];


class PrequalificationStep extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visibleFinancialInfo: false,
    };
  }

  financialInfoBoxHandlerOpen = () => {
    this.setState({ visibleFinancialInfo: true});
  };

  financialInfoBoxHandlerClose = () => {
    this.setState({ visibleFinancialInfo: false});
  };

  submitForm = (values) => {
    const { logEvent } = this.props;
    logEvent({name: 'click_aff_tcpa_agreement', payload: {isChecked: values.agreeTcpa ?? false}})
    this.koalafiPreInit().finally(() => {
      this.props.initApplication({ values: this.buildBodyToPrequalificationInitApplication() })
    });
  };

  componentDidMount() {
    const { token, visitID, values } = this.props;
    this.setState({ phone: values.phone });
    AnalyticAPI.send_analytic_event(token, 'prequalification_step', visitID);
  }

  koalafiPreInit = () => {
    const { token } = this.props;

    const preInitData = this.buildPreInitData();
    return ApplicationRequestAPI.koalafiCreate(token, preInitData).then((data) => {
      const koalafiId = data.koalafi.id;
      const script = document.createElement("script");
      script.setAttribute(
        "src",
        `https://content.koalafi.com/fp/tags.js?org_id=${process.env.KOALAFI_ORG_ID}&session_id=${koalafiId}`
      );
      document.head.appendChild(script);
    });
  }

  buildPreInitData = () => {
    const { appRequest, values } = this.props;
    const { phone } = this.state;

    return {
      application: {
        personalInfo: {
          phone: phone && onlyNumbers(phone),
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
        shipping: appRequest.shipping,
        items: prequalificationCart,
        orderId: appRequest.orderId
      },
    };
  };

  buildBodyToPrequalificationInitApplication = () => {
    const { appRequest, values } = this.props;
    const { additionalData } = this.state;

    const applicationRequest = {
      personalInfo: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        dob: `${values.year}-${values.month}-${values.day}`,
        ssn: values.ssn && onlyNumbers(values.ssn)
      },
      residenceInfo: {
        street: values.streetAddress,
        country: "US",
        city: values.city,
        state: values.state,
        zip: values.zipCode
      },
      consents: {
        agreeTcpa: values.agreeTcpa || false,
      },
      employmentInformation: {
        payFrequency: values.payFrequency,
        monthlyIncome: values.income && parseFloat(onlyNumbers(values.income)),
        last_payday_on: values.lastPaycheck,
        next_payday_on: getNextPaycheckDate(values.payFrequency, values.lastPaycheck, values.payoutDay),
        direct_deposit: 'true',
      },
      items: prequalificationCart
    };

    if (appRequest.provider_name === 'flexshopper') {
      applicationRequest.has_additional_info = true;
      applicationRequest.additional_info_data = {};
      if (additionalData.routingNum && additionalData.accountNum) {
        applicationRequest.additional_info_data.bankAccount = {
          routing: additionalData.routingNum,
          account: additionalData.accountNum,
          type: 'checking'
        };
      }
    }
    return applicationRequest;
  };

  activeBreadcrumb = () => {
    const { visibleFinancialInfo } = this.state

    return visibleFinancialInfo ? "Financial Information" : "Personal Information"
  }

  render() {
    const { loading, handleSubmit, valid, isAgreementChecked, token, activeProviders, affAgreements, logEvent } = this.props;
    const { visibleFinancialInfo } = this.state

    const validateAllForm = valid && visibleFinancialInfo && isAgreementChecked

    return (
      <div className="step2">
        {renderHeader()}
        <Breadcrumb data={breadcrumbMap} activeBreadcrumb={this.activeBreadcrumb()} />
        <div className="step2__content flex flex--column flex__align--center">
          <PersonalInfo
            financialInfoBoxHandlerOpen={this.financialInfoBoxHandlerOpen}
            financialInfoBoxHandlerClose={this.financialInfoBoxHandlerClose}
          />
          <FinancialInfo
            visibleFinancialInfo={visibleFinancialInfo}
          />
          <div className="div-lock-green prequal">
            <div>
              <img src={Secure} className="lock-img" />
            </div>
            <span className="lock-green">Your information is secure and only stored temporarily while we process your application.</span>
          </div>
          <div className="Line-73"></div>
          {renderPrivacyPolicy(token, activeProviders, affAgreements)}
          <div className="blue-btn_pre-approval">
            <BlueBtn
              customClass="step2__submit-btn"
              loading={loading}
              disabled={!validateAllForm}
              type="submit"
              onClick={handleSubmit(this.submitForm)}
            >
              Continue
            </BlueBtn>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector()(state),
  loading: loadingSelector()(state),
  visitID: trendingsVisitID()(state),
  values: formValuesSelector()(state),
  hasAdditionalInfo: selector(state, 'hasAdditionalInfo'),
  appRequest: appRequestSelector()(state),
  providerName: affiliatedProviderSelector()(state),
  isAgreementChecked: selector(state, 'isAgreementChecked'),
  activeProviders: activeProviders()(state),
  affAgreements: affAgreements()(state),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    initApplication: (values) => dispatch(initApplication(values)),
    continueInitApplication: values => dispatch(continueInitApplication(values)),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

PrequalificationStep = connect(mapStateToProps, mapDistpatchToProps)(PrequalificationStep);

PrequalificationStep = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(PrequalificationStep);

export default PrequalificationStep;
