import React, { PureComponent } from "react";
import Modal from "@mui/material/Modal";
import { IconBtn } from "components/Controls";
import { BlueBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import Row from "./row";

import "./styles.scss";

class CompareModalScreen extends PureComponent {
  state = {

  }

  renderRow = (index) => {
    const { offers } = this.props
    const key = index == 0 ? 'header' : 'table'
    let rowData = { key: key, content: [] }
    offers.map((offer) => {
      offer.tableData[index]['provider'] = offer.specificProviderName
      rowData['content'].push(offer.tableData[index])
    })
    return (
      <Row
        data={rowData}
        selectOffer={this.selectOfferAndCloseModal}
      />
    )
  }

  selectButton = () => {
    const { offers } = this.props

    return (
      <div className="table-row">
        <div className="table-title">
        </div>
        {offers.map((offer, index) => (
          <div key={index} className="table-value">
            <BlueBtn
              onClick={() => this.props.selectOffer(offer.specificProviderName)}
            >
              SELECT
            </BlueBtn>
          </div>
        ))}
      </div>
    )
  }

  selectOfferAndCloseModal = (providerName) => {
    const { selectOffer, closeModal } = this.props
    closeModal()
    selectOffer(providerName)
  }

  render() {
    const { isOpen, offers, closeModal } = this.props
    if (!offers[0]) {
      return ""
    }
    return (
      <Modal
        className="multistep-modal"
        aria-labelledby="multistep-modal-title"
        aria-describedby="multistep-modal-description"
        open={isOpen}

      >
        <React.Fragment>
          <div className="multistep-modal__wrapper multistep-modal__wrapper_blue">
            <IconBtn
              customClass="multistep-modal__close-btn"
              onClick={() => closeModal()}
            >
              <IconClose style={{ width: 14, height: 14 }} />
            </IconBtn>

            <h2 className="text-center font-bold">
              Choose a plan to continue
            </h2>

            <div className="table-compare">
              {offers[0].tableData.map((offer, index) => (
                <div
                  key={index}
                >
                  {this.renderRow(index)}
                </div>
              ))}
              {this.selectButton()}
            </div>

            <div className="table-compare-button">
              <BlueBtn
                onClick={() => closeModal()}
              >
                CLOSE
              </BlueBtn>
            </div>
          </div>
        </React.Fragment>
      </Modal>
    );
  }
}

// const mapStateToProps = createStructuredSelector({
// });

// CompareScreen = connect(mapStateToProps)(CompareScreen);

export default CompareModalScreen;