import React, { Component } from "react";
import PropTypes from "prop-types";
import { BlueBtn, CustomCheckbox } from "components/Controls";
import { Field, reduxForm } from "redux-form";
import { required } from "utils/validations";
import { formValueSelector } from "redux-form";
const selector = formValueSelector("snap_contract_form");
import { connect } from "react-redux";
import { finishApplication } from "../../actions";
import { downloadPdf, setHeight } from "../helpers";

import "./styles.scss";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

class SnapContract extends Component {
  state = {
    loading: false,
    submitLoading: false,
    bottomIsReached: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return <CustomCheckbox meta={meta} {...input} />;
  };

  submit = (e) => {
    this.setState({ submitLoading: true });
    this.props.finishApplication();
  };

  downloadPdf = () => {
    downloadPdf(this);
  };

  showSubmit = () => {
    this.setState({ bottomIsReached: true });
  };

  hideSubmit = () => {
    this.setState({ bottomIsReached: false });
  };

  renderBottomSnippet = () => {
    const { isAgreementChecked } = this.props;
    const { bottomIsReached, submitLoading } = this.state;
    if (bottomIsReached) {
      return (
        <div>
          <div className="privacy-policy-agreement flex flex__align--center">
            <Field
              name="isAgreementChecked"
              validate={[required]}
              component={this.renderAgreementCheckbox}
            />
            <div className="privacy-policy-agreement__label">
              Click here to agree to these terms
            </div>
          </div>
          {isAgreementChecked && (
            <div>
              <div className="aggreement_content">
                By clicking SIGN AGREEMENT, you agree to the terms of this
                agreement which include important lease-to-own disclosures, ACH
                or other payment method authorization, jury trial waiver, and an
                arbitration clause.
              </div>
              <BlueBtn
                onClick={this.submit}
                loading={submitLoading}
                disabled={!isAgreementChecked || submitLoading}
              >
                SIGN AGREEMENT
              </BlueBtn>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="privacy-policy-agreement privacy-policy-agreement-note">
            Scroll to Review and Sign
          </div>
        </div>
      );
    }
  };

  handleScroll = (e) => {
    if (e.target.scrollTop >= e.target.scrollHeight - 500) {
      this.setState({ bottomIsReached: true });
    }
  };

  render() {
    const { selectedOffer, hidden } = this.props;

    return (
      <div className="snap_contract_step" style={{display: hidden ? 'none' : 'inherit'}}>
        <SimpleBar
          style={{ maxHeight: 350, width: "100%" }}
          scrollableNodeProps={{ onScroll: this.handleScroll }}
        >
          <div id="contract-iframe-wrapper">
            <h2 className="subtitle font-center">Terms of agreement.</h2>
            <iframe
              onLoad={(e) => setHeight(e)}
              srcDoc={selectedOffer.agreement.contract}
              id="contract-iframe"
              width="100%"
              frameBorder="0"
              border="0"
            ></iframe>
          </div>
        </SimpleBar>
        {this.renderBottomSnippet()}
      </div>
    );
  }
}

SnapContract.propTypes = {
  contract: PropTypes.string,
};

SnapContract.defaultProps = {
  contract: "",
};

const mapStateToProps = (state) => ({
  isAgreementChecked: selector(state, "isAgreementChecked"),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    finishApplication: (data) => dispatch(finishApplication(data)),
  };
};

SnapContract = connect(mapStateToProps, mapDistpatchToProps)(SnapContract);
SnapContract = reduxForm({
  form: "snap_contract_form",
  initialValues: { isAgreementChecked: false },
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SnapContract);

export default SnapContract;
