import React from "react";
import AccountNumber from 'assets/img/account-number-guide.png';
import './styles.scss'

const AccountNumberTooltip = () => {
  return (
    <div className="account-number-tooltip">
      <h3 className="title3 font-uppercased font-center">
        HOW TO FIND YOUR ROUTING AND ACCOUNT NUMBER
      </h3>
      <p className="account-number-tooltip__text">
        You will find this information at the bottom of one of your checks.
        There will be a line of printed numbers which indicate the bank’s
        routing number, your account number, and the individual check number.
      </p>
      <div className="account-number-tooltip__img">
      <img src={AccountNumber} alt="account-number-guide" />
      </div>
    </div>
  );
};

export default AccountNumberTooltip;
