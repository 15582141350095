import { ofType, combineEpics } from 'redux-observable';
import { withLatestFrom, flatMap, catchError } from 'rxjs/operators';

import * as ActionTypes from './actionTypes';
import * as WidgetActions from 'containers/EmbeddableWidget/actions';

const openModal = (action$, state$) => action$.pipe(
  ofType(ActionTypes.OPEN_MODAL),
  flatMap(() => {
    return [ 
      WidgetActions.sendMessage({type: 'OPEN_WIDGET_DONE' })
    ];
  })
);

const closeModal = (action$, state$) => action$.pipe(
  ofType(ActionTypes.CLOSE_MODAL),
  withLatestFrom(state$),
  flatMap(([ action, state]) => {
    return [ WidgetActions.cancelFlow() ];
  })
);

export default combineEpics(
  openModal,
  closeModal
)

