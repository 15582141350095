import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer} from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { AnalyticAPI } from "api/analytic";
import {allSelectedOffersSelector, trendingsVisitID} from '../../selectors';
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { paymentFrequency } from "utils/helpers";

class GenesisOffer extends Component {
  state = {
    loading: false,
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, offer);
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { selectedOption } = this.state;
    const { allSelectedOffers, nextStep, setSelectedOffer, logContinueEvent} = this.props
    this.setState({loading: true})
    setSelectedOffer(selectedOption);
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', 'genesis', visitID);
    logContinueEvent()

    const isContractOpened = allSelectedOffers.some(offer => offer.app_id === selectedOption.app_id)
    nextStep({ step: isContractOpened ? 'contractStep' : selectedOption.account_accepted ? 'genesisConfirmation2' : 'genesisConfirmation1', data: selectedOption });
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, available, isExpanded, offer  } = this.props;

    if (!selectedOption) {
      return '';
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={0}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        { isExpanded && (
        <form
          onSubmit={handleSubmit(this.submitForm)}
          className="tempoe-offer application-offer"
        >
          <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
            <OfferBlock
              className={'centered type_of_plan_block'}
              row={[{
                title: 'Type Of Plan',
                value: `${selectedOption.type}`
              }]}
            />
            <OfferView offerData={buildOfferData(selectedOption)} />
          </div>

          <BuyoutInfo
            title={"No Early Payment Fees"}
            text={`You can pay off the balance in full at any time without penalty. Additional purchases can be made at Tire Agent with this credit line. On time minimum payments can help your credit score.`}
          />

          <p className="small_message">You can choose to stop receiving "prescreened" offers of credit from this and other companies by calling toll free, 1-888-567-8688. See <span className="underlined">PRESCREEN & OPT OUT NOTICE</span> for more information about prescreened offers.</p>

          <BlueBtn
            customClass="step3__recommended-content-btn"
            loading={loading}
            disabled={!(available)}
            type="submit"
          >
            Review & Accept
          </BlueBtn>
        </form>
        )}
        </div>
    );
  }
}

GenesisOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

GenesisOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  allSelectedOffers: allSelectedOffersSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

GenesisOffer = connect(mapStateToProps, mapDistpatchToProps)(GenesisOffer);

GenesisOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GenesisOffer);

export default GenesisOffer;


