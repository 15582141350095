import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware} from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import modalReducer from "./containers/MultistepModal/reduser";
import leaseFormReducer from "./containers/MultistepForm/reduser";
import formReducer from "./containers/MultistepForm/formReduser";
import widgetReducer from "./containers/EmbeddableWidget/reducer";

import { composeWithDevTools } from '@redux-devtools/extension';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import widgetEpic from './containers/EmbeddableWidget/epics';
import formEpic from './containers/MultistepForm/epics';
import modalEpic from './containers/MultistepModal/epics';

import "./index.css";
import './styles.scss';


import MultistepForm from "./containers/MultistepForm";
import MultistepModal from './containers/MultistepModal';
import EmbeddableWidget from './containers/EmbeddableWidget';

import * as serviceWorker from "./serviceWorker";

const reducers = combineReducers({
  form: reduxFormReducer.plugin(formReducer),
  leaseForm: leaseFormReducer,
  modal: modalReducer,
  widget: widgetReducer
});

// Sentry.init({
//   dsn: process.env.SENTRY_DSN
// });

const compose = composeWithDevTools({ trace: true, traceLimit: 25 });

const epicMiddleware = createEpicMiddleware();

let store = createStore(reducers, compose(
  applyMiddleware(epicMiddleware)
));

epicMiddleware.run(
  combineEpics(
    widgetEpic,
    formEpic,
    modalEpic
  )
)

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/modal" element={<MultistepModal />} />
          <Route path="/form" element={<MultistepForm />} />
          <Route path="/widget" element={<EmbeddableWidget />} />
        </Routes>
      </Router>
    </Provider>
  );
};

const root = document.getElementById('root');
const reactRoot = createRoot(root);
reactRoot.render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
