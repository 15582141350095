import React, { Component } from "react";
import { setPayTomorrowMetrix } from "../../actions";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

class PayTomorrowMetrix extends Component {

  state = {
    threatMetrixPayTomorrowSessionId: null,
    url: 'https://h.online-metrix.net/fp/tags.js?org_id=bov9t792&session_id='
  };

  componentDidMount = () => {
    const { url } = this.state;
    const sessionId = this.generateSessionId();

    const script = document.createElement('script');
    script.setAttribute("src", url + sessionId);
    document.head.appendChild(script);

    this.appendMetrix(sessionId)
  }

  generateSessionId = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  appendMetrix = (sessionId) => {
    const { setPayTomorrowMetrix } = this.props;

    this.setState({
      threatMetrixPayTomorrowSessionId: sessionId
    })

    setPayTomorrowMetrix(sessionId);

  }

  render() {
    const { threatMetrixPayTomorrowSessionId, url } = this.state;
    let sessionId
    return (<div className="">
       <Helmet>
          TrustevV2.Init('de4f14d17db2427999d0fc56ef83bb27', function(sessionId) {
                     console.log('SessionId: ' + sessionId)
             });
      </Helmet>
    </div>);
  }
}


const mapDistpatchToProps = (dispatch) => {
  return {
    setPayTomorrowMetrix: (value) => dispatch(setPayTomorrowMetrix(value)),
  };
};

PayTomorrowMetrix = connect(null, mapDistpatchToProps)(PayTomorrowMetrix);

export default PayTomorrowMetrix;
