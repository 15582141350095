import * as ActionTypes from './eventTypes';
import { mergeDeepRight } from 'ramda';
import CONSTANTS from 'utils/constants';

const defaultState = {
  inIframe: false,
  key: CONSTANTS.API_TOKEN
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_WIDGET:
      return mergeDeepRight(state, { 
        key: action.payload.clientId,
        inIframe: true
      });
    default:
      return state;
  }
};