import React, { Component } from "react";
import { connect } from "react-redux";
import { setIovation } from "../../actions";
import { runIovationScript } from 'utils/blackbox';

class Iovation extends Component {

  state = {
    blackboxId: null
  };

  componentDidMount = () => {
    runIovationScript().then(blackboxId => {
      const { setIovation } = this.props;
      setIovation(blackboxId)
    });
  }

  render() {
    return null
  }
}

const mapDistpatchToProps = (dispatch) => {
  return {
    setIovation: (value) => dispatch(setIovation(value)),
  };
};

Iovation = connect(null, mapDistpatchToProps)(Iovation);

export default Iovation;
