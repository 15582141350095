import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import './styles.scss'

const TextBtn = ({ disabled, customClass, ...props }) => (
  <button
    disabled={disabled}
    type="button"
    className={classNames("button", "text-button", `${customClass}`)}
    {...props}
  >
   {props.children}
  </button>
);

TextBtn.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextBtn;
