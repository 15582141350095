import React, { Component } from 'react';
import PropTypes from "prop-types";

import "./styles.scss";
import { renderTextField } from 'containers/MultistepForm/Step2/input_helpers';
import { Field } from 'redux-form';
import { required, isCodeEqual6 } from 'utils/validations';
import { normalizeCode} from 'utils/normalize';

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.codeInput = React.createRef();
  }

  resendCode = () => {
    const { onChange, onResend } = this.props;
    this.codeInput.current.setState({ values: Array(this.codeInput.current.props.fields).fill('') });
    onResend();
    onChange('');
  }

  render() {
    const { trigger, text, resend, onChange, label, meta: {valid, error, touched}, ...props } = this.props
    return (
      <div>
        <p>{text}</p>
        <div className="verification_code_component">
          {label && (<label className="flex flex__align--baseline font-uppercased text-field-label">{label}</label>)}
          <div className="pos-relative">
            <Field
              name="passcode"
              validate={[required, isCodeEqual6]}
              component={renderTextField}
              normalize={normalizeCode}
              onChange={onChange}
              inputMode="numeric"
              autoComplete="one-time-code"
            />
          </div>
          {resend && (<span
            className="resend_code_button"
            onClick={this.resendCode}
          >RESEND CODE</span>)}
          </div>

      </div>
    );
  }
};

VerificationCode.propTypes = {
  trigger: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onResend:  PropTypes.func,
  resend:  PropTypes.bool
  // onComplete: PropTypes.func
};

VerificationCode.defaultProps = {
  trigger: false,
  text: '',
  resend: true,
  onResend: () => {},
  // onComplete: () => {}
};

export default VerificationCode;
