import React from 'react';
import { IconBtn, SelectQuantity } from "components/Controls";
import { IconClose } from "components/Svgs";
import { addThousandsSeparator } from "utils/helpers";
import "./styles.scss";

const CartItem = ({
  item,
  removeItem,
  changeItemQuantity,
}) => {

  return (
    <div className='item-content'>
      <div className="items-row">
        <IconBtn
          customClass="remove-item-btn"
          onClick={() => removeItem(item)}
        >
          <IconClose className="remove-btn" />
        </IconBtn>
        <div className="image-col">
          <img src={item?.image} alt=""></img>
        </div>
        <div className="details-col">
          <div className="name-quantity-col">
            <div className="item-name-block">
              <p className="item-brand">{item?.brand}</p>
              <p className="font-bold item-model">{item?.model}</p>
            </div>
          </div>
          <div className="price-col">
            <SelectQuantity
              item={item}
              name="quantity1"
              isStaggered
              selectedQuantity={item?.quantity}
              onSelect={changeItemQuantity}
            />
            <div className="price-block">
              <span className="font-bold price-value">{addThousandsSeparator(item.price)}</span>
              <span className="additional-info">/each</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem;
