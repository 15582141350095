import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as SharedEventTypes from './eventTypes';
import MultistepModal from "containers/MultistepModal";

import './styles.css';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class EmbeddableWidget extends Component {
  constructor(props) {
    super(props)
    window.addEventListener("message", this.receiveMessage, false);
  }

  componentDidMount = () => {
    this.appendMetrix();
    this.appendFs();
  }

  appendMetrix = () => {
    if(process.env.REACT_APP_PAYPAIR_ENV === 'production' || process.env.REACT_APP_PAYPAIR_ENV === 'sandbox'){
      var script = document.createElement("script");
      script.innerHTML = '!function(w, d, t, u) {\
        if (w.__ls) return; var f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)};\
        if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0";\
        var ls = d.createElement(t); ls.async = true; ls.src = u;\
        var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);\
      }(window, document, "script", ("https:" == window.location.protocol ? "https://" : "http://") + "cdn.livesession.io/track.js");\
      __ls("init", "c415fe43.95e6dcc0");\
      __ls("newPageView");';
      document.body.appendChild(script);
    }
  }

  appendFs = () => {
    if(process.env.REACT_APP_PAYPAIR_ENV === 'production' || process.env.REACT_APP_PAYPAIR_ENV === 'sandbox'){
      const scriptContent = `
        window['_fs_run_in_iframe'] = true;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = 'o-1Z1XN5-na1';
        window['_fs_namespace'] = 'FS';
        !function(m,n,e,t,l,o,g,y){var s,f,a=function(h){
        return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),!1)}(e)
        ;function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}
        d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{
        then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){
        h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}
        ;function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){
        "function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){
        (o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}
        ;var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}
        function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h("setProperties",{type:b,properties:d},j)}function j(b,h){d("user",b,h)}function r(b,h,d){j({
        uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h("trackEvent",{
        name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},
        g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",
        f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v="2.0.0")
        }(window,document,window._fs_namespace,"script",window._fs_script);
      `;

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.text = scriptContent;
      document.head.appendChild(scriptElement);
    }
  }

  receiveMessage = (event) => {
    if(this.isKnownEvent(event)) {
      this.props.dispatch({ type: event.data.type, payload: event.data.value });
    }
  }

  isKnownEvent = (event) => {
    return !!event && event.data && event.data.type && event.data.type in SharedEventTypes;
  }


  render() {
    return (
      <MultistepModal />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state)
  }
};
const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddableWidget);

