import React from "react";

const SSN_ITIN_TOOLTIP_TEXT =
  "We use this information as a way to verify your identity.";
const SSN_ITIN_TOOLTIP_TITLE = "Why do we need this information?";

const INCOME_TOOLTIP_TEXT = (
  <div>
    Alimony, child support, or separate maintenance income need not be included unless relied upon for credit. 
    You may include the monthly amount that you have available to spend from your assets.
    <div className="space"></div>
    <span className="font-semibold"> Married WI Residents Only: </span>
    If you are applying for an individual account and your spouse also is a WI resident,
    combine your spouse’s financial information.
  </div>
);

export const HEADER_TITLE = "Please fill out the information below to process your payment plan application with PayPair.";

export const STEP2__FEILDS = {
  income: {
    label: "* Gross monthly income: ",
    tooltipType: "icon",
    placeholder: "",
    tooltipText: INCOME_TOOLTIP_TEXT,
    tooltipTitle: null
  },
  ssn: {
    label: "* SSN / ITIN: ",
    tooltipType: "text",
    placeholder: "*** ** 0000",
    tooltipText: SSN_ITIN_TOOLTIP_TEXT,
    tooltipTitle: SSN_ITIN_TOOLTIP_TITLE
  }
};

// TODO: Move to containers/MultistepForm/constants/collections. Maybe all from this file.
export const PERSONAL_INFO_TITLE = 'Personal Information'
export const REQUIRED_FIELD_TITLE = '* Required field'
