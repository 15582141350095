import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { appRequestSelector, availableApplicationsSelector } from "containers/MultistepForm/selectors";
import PropTypes from "prop-types";
import { ApplicationAPI } from 'api/application';
import Offer from "../Offer";
import { required, isCodeEqual6 } from "utils/validations";
import { formValueSelector } from 'redux-form';
import VerificationCode from "components/CustomFields/VerificationCode"; 
import { SubmissionError } from 'redux-form'
import { nextStep } from "containers/MultistepForm/actions";
import "./styles.scss";

const selector = formValueSelector('flexshopper_auth_form');

class AuthenticationRequiredOffer extends Component {

  state = {
    offer: null,
    loading: false,
    showPasscodeField: false,
    appUpdated: false,
    appApproved: false
    // passcode: ""
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({offer: offer});
  };

  submitForm = (values) => {
    const { showPasscodeField } = this.state;
    this.setState({loading: true});
    if (showPasscodeField) {
      return this.passcodeLogin();
    } else {
      return this.sendPasscode();
    }
  }

  sendPasscode = () => {
    const { token } = this.props;
    const { offer } = this.state;
    return ApplicationAPI.sendPasscode(token, offer.app_id).then(data => {
      this.setState({loading: false});

      this.showPasscodeField();
      if (data.errors) {
        throw new SubmissionError({
          passcode: 'Some server error'
        })
      }
    });
  }

  passcodeLogin = () => {
    const { token, passcode } = this.props;
    const { offer } = this.state;

    return ApplicationAPI.passcodeLogin(token, offer.app_id, {passcode: passcode}).then(data => {
      if (data.errors) {
        this.setState({loading: false});
        throw new SubmissionError({
          passcode: 'Some server error'
        })
      }
      this.updateApplication();
    })
  }

  updateApplication = () => {
    const { token, appRequest, availableApplications, nextStep } = this.props;
    const { offer } = this.state;

    return ApplicationAPI.update(token, offer.app_id, {application: appRequest}).then(data => {
      this.setState({loading: false});

      const approved = data.approve_status === 'approved';

      if (!approved && availableApplications.length < 2){
        nextStep({step: 'nobodyApprovedStep'});
        return
      }

      this.setState({
        offer: data,
        appUpdated: true,
        appApproved: approved
      });
    })
  }

  resendCode = () => {
    // this.setState({passcode: ""});
    this.sendPasscode();
  }

  showPasscodeField = () => {
    this.setState({ showPasscodeField: true });
  }

  // setPasscode = (passcode) => {
  //   this.setState({ passcode: passcode });
  // }

  renderCodeField = ({ input, meta }) => {
    const { showPasscodeField } = this.state;
    return (
      <VerificationCode 
        meta={meta}
        {...input}
        trigger={showPasscodeField}
        onResend={this.resendCode}
        label={false}
        text="Please enter the 6-digit verification code sent to your phone number or email."
      />
    );
  };

  renderOffer = () => {
    const { appApproved, offer } = this.state;
    return appApproved ? (<Offer 
      offer={offer} 
    />) : (
      <div className="flexshopper_decline_message">At this time you were not approved by Flexshopper, but the good news is you have other great approval(s) to choose from.</div>
    )
  }

  renderLoginForm = () => {
    const { loading, showPasscodeField} = this.state;
    const { handleSubmit } = this.props;
    return (<form
        onSubmit={handleSubmit(this.submitForm)}
        className="offer-wrapper verification-required-offer"
      >

        {!showPasscodeField && (<p>In order to complete your checkout with FlexShopper, you must be verified through their service. A verification code will be sent to your phone and email.</p>)}

          <Field
            name="passcode"
            validate={showPasscodeField ? [required, isCodeEqual6] : null}
            component={this.renderCodeField}
          />
        <BlueBtn
          loading={loading}
          customClass="step3__recommended-content-btn"
          type="submit"
        >
          {showPasscodeField ? "SUBMIT" : "SEND VERIFICATION CODE"}
        </BlueBtn>
      </form>
    );
  }

  render() {
    const { appUpdated } = this.state;
    return appUpdated ? this.renderOffer() : this.renderLoginForm();
  }
}

AuthenticationRequiredOffer.propTypes = {
  offer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  passcode: selector(state, 'passcode'),
  token: tokenSelector()(state),
  appRequest: appRequestSelector()(state),
  availableApplications: availableApplicationsSelector()(state),
});

const mapDistpatchToProps = dispatch => {
  return {
    nextStep: value => dispatch(nextStep(value))
  };
};

AuthenticationRequiredOffer = connect(mapStateToProps, mapDistpatchToProps)(AuthenticationRequiredOffer);

AuthenticationRequiredOffer = reduxForm({
  form: "flexshopper_auth_form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AuthenticationRequiredOffer);

export default AuthenticationRequiredOffer;
