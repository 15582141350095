import React from "react";
import CustomDateField from "components/CustomFields/CustomDateField";
import { getNextPaycheckDate, renderField, renderLastPaycheckField, renderPayFrequencyField, renderPayoutDayField } from "containers/MultistepForm/Step2/input_helpers";
import { Field, change } from "redux-form";
import { normalizeIncome } from "utils/normalize";
import { greaterOrEqualZeroIncome, isNumGreaterThan50000, required } from "utils/validations";
import "./styles.scss";
import Calendar from 'assets/img/calendar_icon.svg';


const FinancialInfoForm = ({ setValue,
                             payoutDay,
                             values, 
                             clearLastPaydate, 
                             showPayoutDayOption,
                             payoutFrequency,
                             prequalification
                            }) => {

  const onChangeLastPaydate = (date) =>{
    setValue({
      clearLastPaydate: false,
      nextPaycheckDate: getNextPaycheckDate(payoutFrequency, date, payoutDay)
    });
  }

  const setPayoutFrequency = (value) => {
    setValue({
      payoutFrequency: value,
      showPayoutDayOption: value === 'semi_monthly',
      clearLastPaydate: true
    });
    change('lastPaycheck', null)
  }

  const setPayoutDay = (value) => {
    setValue({
      payoutDay: value,
      clearLastPaydate: true
    });
    change('lastPaycheck', null)
  }

  return (
    <div>
      <div className="form-item-group">
        <div className="form-item">
          <Field
            name="income"
            validate={[required, greaterOrEqualZeroIncome, isNumGreaterThan50000]}
            component={renderField}
            normalize={normalizeIncome}
            label={"* Gross Monthly Income"}
          />
        </div>
        <div className="form-item form-item--frequency-payment">
          <Field
            name="payFrequency"
            validate={required}
            component={renderPayFrequencyField}
            onChange={setPayoutFrequency}
            label={"* How often do you get paid?"}
          />
        </div>
      </div>

      {showPayoutDayOption &&
        <div className="form-item form-item--frequency-payment frequency-payment-input">
          <Field
            name="payoutDay"
            validate={required}
            component={renderPayoutDayField}
            onChange={setPayoutDay}
            label={"* Are you paid on the first or the last day of the month?"}
          />
        </div>
      }

      <div className="form-item last-pay-ckeck_input">
        <img src={Calendar} className="calendar-icon"/>
        <CustomDateField
          name="lastPaycheck"
          payoutFrequency={payoutFrequency}
          validate={[required]}
          component={renderLastPaycheckField}
          onChange={onChangeLastPaydate}
          reset={clearLastPaydate}
          label={"* Last paycheck date"}
        />
      </div>
    </div>
  );
};

export default FinancialInfoForm;
