import { renderDayField, renderMonthField, renderYearField } from "containers/MultistepForm/Step2/input_helpers";
import React from "react";
import { Field } from "redux-form";
import { onlyNumbers } from "utils/normalize";
import { isYearEqual4, required } from "utils/validations";


const DateOfBirth = ({ month, day, year, resetDay }) => {

  return (
    <div>
      <div className="birthday_text">
        * What is your date of birth?
      </div>
      <div className="month-block">
        <div className="dob-label">Month</div>
        <Field
          name="month"
          onChange={resetDay}
          validate={[required]}
          component={renderMonthField}
          normalize={onlyNumbers}
        />
      </div>
      <div className="day-block">
        <div className="dob-label">Day</div>
        <Field
          name="day"
          validate={[required]}
          component={renderDayField}
          props={{month, year}}
          normalize={onlyNumbers}
        />
      </div>
      <div className="year-block">
        <div className="dob-label">Year</div>
        <Field
          name="year"
          validate={[required, isYearEqual4]}
          component={renderYearField}
          props={{month, day}}
          normalize={onlyNumbers}
        />
      </div>
    </div>
  );
};

export default DateOfBirth;
