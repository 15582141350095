import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PROGRESSIVE_STEP__FIELDS } from 'containers/MultistepForm/constants/collections';
import { onlyNumbers } from "utils/normalize";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
	isLessThan20digits,
	required,
} from 'utils/validations';
import { TextField } from "components/Fields";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import asyncValidate from '../../../../../components/DataDisplay/AdditionalInfoBlock/asyncValidate';

const selector = formValueSelector('bank_data_form');

class Form extends PureComponent {
	componentDidMount() {
		this.checkInitialValidity();
	}

	checkInitialValidity = () => {
		const { additionalData, onDataChange } = this.props;
		const valid = !!(additionalData.routingNum && additionalData.accountNum);
		onDataChange({
			data: additionalData,
			valid: valid
		});
	}

	handleChange = () => {
		const { additionalData, onDataChange, valid } = this.props;
		onDataChange({
			data: additionalData,
			valid: valid
		});
	}

	renderField = ({ input, meta }) => {
		const field = input.name;
		return (
			<TextField
				type={"text"}
				label={PROGRESSIVE_STEP__FIELDS[field].label}
				placeholder={PROGRESSIVE_STEP__FIELDS[field].placeholder}
				meta={meta}
				customChange={this.handleChange}
				{...input}
			/>
		);
	};

	render() {
		return (
			<div>
				<div className="form-item-group">
					<div className="form-item ">
						<Field
							name="routingNum"
							normalize={onlyNumbers}
							validate={[required]}
							component={this.renderField}
						/>
					</div>
					<div className="form-item">
						<Field
							name="accountNum"
							normalize={onlyNumbers}
							validate={[required, isLessThan20digits]}
							component={this.renderField}
						/>
					</div>
				</div>
			</div>
		);
	};
}

Form.propTypes = {
	onDataChange: PropTypes.func
};

Form.defaultProps = {
	onDataChange: () => {}
};

Form = reduxForm({
	form: "bank_data_form",
	asyncValidate,
	asyncChangeFields: ['routingNum'],
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(Form);

Form = connect(
	state => ({
		additionalData: selector(state, 'routingNum', 'accountNum'),
		token: tokenSelector()(state)
	})
)(Form);



export default Form;
