import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/DataDisplay";
import classNames from "classnames";
import "./styles.scss";

const BlueBtn = ({ disabled, loading, customClass, ...props }) => (
  <button
    disabled={disabled || loading}
    type="button"
    className={classNames(
      "font-uppercased button flex flex__justify--center flex__align--center",
      "blue-button",
      `${customClass}`
    )}
    {...props}
  >
    {props.children}
    {loading && <Spinner />}
  </button>
);

BlueBtn.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  customClass: PropTypes.string
};

export default BlueBtn;
