import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import "./styles.scss";

import { userInfoSelector, affiliatedProviderSelector} from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { closeModal } from "../../MultistepModal/actions";
import { Denial } from "components/FlexshopperMetrics";


class ProviderHaveNotApprovedStep extends Component {
  render() {
    const { userInfo, closeModal, providerName } = this.props;

    return (
      <div className="not-approved-step flex flex--column flex__align--center ">
        { providerName == 'flexshopper' && <Denial/> }
        <span className="subtitle font-center">Hi, {userInfo.firstName}!</span>

        <span className="not-approved-step__text flex__align--center">
          Thank you for your interest in shopping with Tire Agent.
        </span>
        <span className="not-approved-step__text flex__align--center">
          Unfortunately your <strong>{providerName}</strong> application was not approved but we can provide further assistance and help you get the tires you need.
        </span>
        <span className="not-approved-step__text flex__align--center">
          Please email us at <a href="mailto:paymentplanhelp@tireagent.com">paymentplanhelp@tireagent.com</a> and provide your name, email address, phone number and a date and time we can contact you.
        </span>
        <span className="not-approved-step__text flex__align--center">
          One of our expert customer experience team members will contact you shortly.
        </span>

        <BlueBtn customClass="not-approved-step__back-btn" onClick={closeModal} >EMAIL US</BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector(),
  providerName: affiliatedProviderSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    closeModal: () => { window.location.href="mailto:paymentplanhelp@tireagent.com", dispatch(closeModal()) }
  };
};

ProviderHaveNotApprovedStep = connect(mapStateToProps, mapDistpatchToProps)(ProviderHaveNotApprovedStep);

export default ProviderHaveNotApprovedStep;
