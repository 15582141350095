import React, { Component } from "react";
import { createStructuredSelector } from 'reselect';
import { connect } from "react-redux";
import { affiliatedProviderSelector, appRequestSelector} from '../../selectors';
import { Helmet } from 'react-helmet';

class FlexshopperMetrix extends Component {

  render() {
    const user = this.props.appRequest.personalInfo
    if (user.email == null || this.props.providerName !== 'flexshopper') {
      return null
    }

    return (
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-844077454"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-844077454');
        `}
      </script>

        <script>
          {`

            !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
            document,'script','//connect.facebook.net/en_US/fbevents.js');
            fbq('init', '347609072070486', {
               em: '${user.email}',
               fn: '${user.firstName}',
               ln: '${user.lastName}'
            });
            fbq('track', "PageView");
          `}
        </script>
    </Helmet>)

  }
}



const mapStateToProps = createStructuredSelector({
  appRequest: appRequestSelector(),
  providerName: affiliatedProviderSelector()
});

FlexshopperMetrix = connect(mapStateToProps, null)(FlexshopperMetrix);

export default FlexshopperMetrix;
