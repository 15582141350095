import React from "react";

export const details = () => {
  return (
      {
        lease_to_own: leaseToOwn(),
        lease_payment: lease_payment(),
        loan_payment: loan_payment()
      }
  );
}

const leaseToOwn = () => {
  return (
    <div className="step-tooltip-block">
      <span className="font-bold">What Is Lease-to-Own Financing?</span>
      <br/>
      <br/>
      <span>Consumer leasing is a financing option in which the provider will purchase merchandise from a merchant and lease it to you.
        Lease-to-own (or rent-to-own) financing are not loans.
        For example, instead of paying interest, you will pay rental fees on top of the retail price.
        You will not own the property until you complete all payments or take advantage of an Early Purchase
        Option under the full term of the agreement.
        The minimum lease term may vary with a maximum lease term of 48 months. 
        Please review your lease agreement for complete terms and conditions.
        Eligibility for lease-to-own financing may vary according to state law. 
        In the event you reside in a state that does not allow lease-to-own financing you will not be considered for that financing option.
      </span>
    </div>
  )
};

const lease_payment = () => {
  return (
    <div className="step-tooltip-block">
      <span className="font-bold">Estimated Recurring Payment</span>
      <br />
      <br />
      <span>This is an estimated payment amount. 
        You will be provided your actual terms before signing your lease agreement.
      </span>
    </div>
  );
};

const loan_payment = () => {
  return (
    <div className="step-tooltip-block">
      <span>This is an estimated payment amount.
        You will be provided your actual terms before signing your loan agreement.
      </span>
    </div>
  );
};
