import React from "react";

const IconChevronSingle = props => (
	<svg {...props} width="22" height="22" viewBox="0 0 22 22">
		<circle data-name="Ellipse 1624" cx="11" cy="11" r="11" style={{"fill": "#3665fc"}} />
		<g data-name="Group 2491" transform="translate(2 1)">
			<circle data-name="Ellipse 1617" cx="9" cy="9" r="9" transform="translate(0 1)" style={{"fill" : "#fff" }} />
			<text transform="matrix(-1 0 0 1 9 15)" style={{"fill": "#235cd8","fontSize": "14px","fontFamily": "Arial-Black,Arial Black","fontWeight": "800"}}><tspan x="-2.331" y="0">i</tspan></text>
		</g>
	</svg>
);

export default IconChevronSingle;