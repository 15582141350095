import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, CustomTooltip, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { AnalyticAPI } from "api/analytic";
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { trendingsVisitID } from "../../selectors";
import AcimaTooltip from "components/DataDisplay/AcimaTooltip";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { addThousandsSeparator, paymentFrequency } from "utils/helpers";
import { ExpandBtnWithText } from "components/Controls";

import "./styles.scss";

class AcimaOffer extends Component {
  state = {
    loading: false,
    selectedOffer: null,
    detailsShown: false
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: offer.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { nextStep, selectOffer, offer, logContinueEvent } = this.props;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', 'acima', visitID);
    logContinueEvent()
    this.setState({ loading: true })
    selectOffer({ selectedOffer: offer });
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox
        meta={meta}
        {...input}
      />
    );
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <div className="agreemnet-block__label tooltip">
            <p className="small_message">
              <span>View additional disclosures </span>
              <CustomTooltip
                title="here"
                text={<AcimaTooltip />}
              />.
            </p>
          </div>
        </div>
      </div>
    );
  };

  toggleDetails = () => {
    this.setState({ detailsShown: !this.state.detailsShown })
  }

  render() {
    const { loading, selectedOption, detailsShown } = this.state;
    const { handleSubmit, valid, isExpanded, offer, available } = this.props;

    if (!selectedOption) {
      return '';
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          specificProviderName={offer.specific_provider_name}
          dueToday={addThousandsSeparator(selectedOption.initial_payment)}
          approvedAmount={offer.approved_amount}
          paymentFrequency={paymentFrequency(selectedOption)}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (

          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption)} />

              <div className="offer-block--100 step-tooltip text-center">
                <ExpandBtnWithText
                  customClass="step-tooltip-button"
                  expandedInfo={<AcimaTooltip />}
                >
                  <span className="step-tooltip-button__text">
                    View additional disclosures here
                  </span>

                </ExpandBtnWithText>
              </div>
            </div>

            <BuyoutInfo
              title={'90 Day Same-As-Cash'}
              text={'If paid in full within 90 days the total price owed is only the order amount + application fee + sales tax.'}
              footnote={''}
            />
            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available || !valid}
              type="submit"
            >
              Continue with Acima
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

AcimaOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool
};

AcimaOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  visitID: trendingsVisitID()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value))
  };
};

AcimaOffer = connect(mapStateToProps, mapDistpatchToProps)(AcimaOffer);

AcimaOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(AcimaOffer);

export default AcimaOffer;


