import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import AdditionalInfoBlock from "components/DataDisplay/AdditionalInfoBlock"; 
import PropTypes from "prop-types";
import { continueInitApplication } from "../../actions";
import { createStructuredSelector } from "reselect";
import { loadingSelector } from "../../selectors";

class AdditionalDataRequiredOffer extends Component {

  state = {
    loading: false,
    additionalData: {},
    valid: false
  }

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      valid: data.valid
    });
  }

  buildBodyToInitApplication = () => {
    const { additionalData } = this.state;  

    const applicationRequest = {}
    applicationRequest.has_additional_info = true;
    applicationRequest.additional_info_data = {};
    if(additionalData.routingNum && additionalData.accountNum ){
      applicationRequest.additional_info_data.bankAccount = {
        routing: additionalData.routingNum,
        account: additionalData.accountNum,
        type: 'checking'
      }
    }

    return applicationRequest;
  }

  submitForm = (e) => {
    const { valid } = this.state;
    e.preventDefault();
    if (valid) {
      this.setState({loading: true})
      this.props.continueInitApplication({values: this.buildBodyToInitApplication()});
    }
  };

  render() {
    const { valid, loading } = this.state;

    return (
      <div className="additional-info-step__form-block">
        <div className="additional-info-step__form-block-content">
          <div className="paypair-form-padding">
            <div className="section_header_message">There may be more payment options available for you. Fill in the information below to apply.</div>
          </div>
          <AdditionalInfoBlock
            onDataChange={this.setAdditionalData}
          />
          <div className="paypair-form-padding">
            <BlueBtn  customClass="additional-info-step__submit-btn"
              disabled={!valid}
              loading={loading}
              onClick={this.submitForm}
            >Submit</BlueBtn>
          </div>
        </div>
      </div>
    );
  }
}

AdditionalDataRequiredOffer.propTypes = {
  onSuccessSubmit: PropTypes.func
};

AdditionalDataRequiredOffer.defaultProps = {
  onSuccessSubmit: () => {},
};
const mapStateToProps = createStructuredSelector({
  loading: loadingSelector(),
});

const mapDistpatchToProps = dispatch => {
  return {
    continueInitApplication: values => dispatch(continueInitApplication(values))
  };
};

AdditionalDataRequiredOffer = connect(
  mapStateToProps,
  mapDistpatchToProps
)(AdditionalDataRequiredOffer);

export default AdditionalDataRequiredOffer;
