import React, { Component } from "react";
import { createStructuredSelector } from 'reselect';
import { connect } from "react-redux";
import { finishApplication, cancelApplication } from "../../actions";
import { selectedOfferSelector, userInfoSelector, appRequestSelector } from '../../selectors';
import { closeModal } from "containers/MultistepModal/actions";
import "./../Frame/styles.scss";
class AcimaContract extends Component {

  componentDidMount() {
    const { selectedOffer, appRequest, finishApplication } = this.props
    const acima = new Acima.Client({
      merchantId: process.env.ACIMA_MERCHANT_ID,
      iframeUrl: process.env.ACIMA_IFRAME_URL,
      iframeContainer: document.getElementById('iframe-container'),
    });
    const userInfo = appRequest.personalInfo
    const address = appRequest.residenceInfo

    acima.checkout({
      contractId: selectedOffer.lease_app_id,
      customer: {
        firstName: userInfo.firstName,
        middleName: "",
        lastName: userInfo.lastName,
        phone: userInfo.phone,
        email: userInfo.email,
        address: {
          street1: address.street,
          street2: "",
          city: address.city,
          state: address.state,
          zipCode: address.zip,
        },
      },
      transaction: selectedOffer.transaction_data,
    }).then(({ leaseId, leaseNumber, checkoutToken }) => {
      finishApplication();
    }).catch(({ code, message }) => {
      console.log("error")
    })
  }

  receiveMessage = (event) => {
    const { finishApplication, cancelApplication, closeModal } = this.props
    switch (event.data.event) {
      case "signing-complete":
        finishApplication();
        break;
    }
  }

  render() {
    const { hidden } = this.props
    return (
      <div style={{display: hidden ? 'none' : 'inherit'}}>
        <div id="iframe-container"></div>
      </div>
    )
  }
}

AcimaContract.propTypes = {
};

AcimaContract.defaultProps = {

};
const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector(),
  selectedOffer: selectedOfferSelector(),
  appRequest: appRequestSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values)),
    cancelApplication: values => dispatch(cancelApplication(values)),
    closeModal: () => dispatch(closeModal())
  };
};

AcimaContract = connect(mapStateToProps, mapDistpatchToProps)(AcimaContract);

export default AcimaContract;

