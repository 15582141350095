import { createSelector } from 'reselect';

const selectModal = state => state.modal;

const modalOpenedSelector = () => createSelector(
  selectModal,
  (modal) => modal.modalOpened
);

export {
  modalOpenedSelector
};

