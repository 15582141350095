import React, { Component } from "react";

import "./styles.scss";

class ArbitrationAgreement extends Component {
  render () {
    return (
      <div>
        <p>We have put this Agreement in question and answer form to make it easier to understand. However, this Agreement is a legally 
        binding contractand by checking thebox and clicking “Continue” you agree to be bound by its terms. In this Arbitration Agreement, 
        “we,” “our,” and “us” mean FlexShopper, LLC, 901 Yamato Road, Suite 260, Boca Raton, FL 33431, its successors and assigns 
        and any other parties identified below.</p>
        <p>Background and Scope</p>
        <p>What is arbitration?</p>
        <p><b>Short answer: </b>An alternative to a court case.</p>
        <p>In arbitration, a neutral third party (“Arbitrator”) resolves Disputes in a hearing. It is less formal than a court case.</p>
        <p>Is it different from court and jury trials?</p>
        <p><b>Short answer: </b>Yes.</p>
        <p>The hearing is private. There is no jury. It is usually less formal, faster and less expensive than a 
          court lawsuit. Pre-hearing fact finding (called “discovery”) is limited. Appeals are limited. Courts rarely overturn 
          arbitration awards.</p>
        <p>Can you opt-out of this Agreement?</p>
        <p><b>Short answer: </b>Yes, within 60 days.</p>
        <p>If you do not want this Arbitration Agreement to apply, you must send us a signed notice within 60 calendar days after
           signing this Arbitration Agreement. You must send the notice in writing (and not electronically) to our Legal Department, 
           at our above address. Provide your name, address, date and email. State that you “opt out” of the Arbitration Agreement.
            Please note that any lease with FlexShopper, LLC, will contain a separate arbitration agreement with a right to reject 
            arbitration. That arbitration agreement appliesonly to your lease. Rejecting the arbitration agreement in your lease will 
            not constitute a rejection of this Arbitration Agreement, nor will rejecting this Arbitration Agreement 
          constitute a rejection of the arbitration agreement in your lease.</p>
          <p>What is this Agreement about?</p>
          <p><b>Short answer: </b>The parties’ agreement to arbitrate Disputes.</p>
          <p>Unless prohibited by applicable law and unless you opt out, you and we agree that any you or we may elect to arbitrate 
            individually or require individual arbitration of any “Dispute” as defined below.</p>
          <p>Who does the Agreement cover?</p>
          <p><b>Short answer: </b>You, us, and certain “Related Parties”.</p>
          <p>This Arbitration Agreement governs you and us. It also covers certain “Related Parties”: (1) our parents, subsidiaries 
            and affiliates and their successors and assigns; (2) our employees, directors, officers, shareholders, members and 
            representatives; and (3) any person or company that is involved in a Dispute you pursue at the same time you pursue 
            a related Dispute with us.</p>
          <p>What Disputes does the Agreement cover?</p>
          <p><b>Short answer: </b>All Disputes (except certain Disputes about this Arbitration Agreement).</p>
          <p>This Arbitration Agreement governs all “Disputes” that would usually be decided in courtand are between us (or any Related 
            Party) and you. In this Arbitration Agreement, the word “Disputes” has the broadest reasonable meaning. It includes all 
            claims even indirectly related to your application, any spending limit we provide you (or decline to provide you), or the 
            relationships between you and us and/or Related Parties resulting therefrom. It includes claims related to any prior 
            applications. It includes extensions, renewals, or payment plans. It includes claims related to collections, privacy 
            and customer information. It includes claims related to the validity of any application. “Disputes” includes, but is 
            not limited to, initial claims, counterclaims, cross-claims and third-party claims, and claims based on any constitution, 
            statute, regulation, ordinance, common law rule (including rules relating to contracts, torts, negligence, fraud or other 
            intentional wrongs) and equity. It includes claims that seek relief of any type, including damages and/or injunctive, 
            declaratory or other equitable relief (but only individual equitable relief, not classwide or representative equitable 
            relief). It includes claims related to the validity in general of this application.However, it does not include 
            disputes about the validity, coverage or scope of this Arbitration Agreement or any part of this Arbitration Agreement. 
            All such disputes are for a court and not the Arbitrator to decide. Disputes about the application as a whole are for 
            the Arbitrator to decide.</p>
            <p>Who handles the arbitration?</p>
            <p><b>Short answer: </b>Usually AAA or JAMS.</p>
            <p>Arbitrations are conducted under this Arbitration Agreement and the rules of the arbitration administrator in effect 
              at the time the arbitration is commenced. However, arbitration rules that conflict with this Arbitration Agreement do 
              not apply. The arbitration administrator will be either:</p>
            <ul> 
            <li>The American Arbitration Association (“AAA”), 120 Broadway, Floor 21, New York, N.Y 10271,www.adr.org.</li>
            <li>JAMS, 620 Eighth Avenue, 34th Floor, New York, NY 10018,www.jamsadr.org.</li>
            <li>Any other company picked by agreement of the parties.</li>
            <li>If all of the above options are unavailable, a court with jurisdiction will pick the administrator.No arbitration may 
              be administered without our consent by any administrator that would permit class arbitration under this Arbitration 
              Agreement.</li>
            <li>The arbitrator will be selected under the administrator’s rules. However, the arbitrator must be a lawyer with 
              at least ten years of experience or a retired judgeunless you and we otherwise agree.</li>
              
              </ul>
             <p>Can Disputes be litigated?</p>
             <p><b>Short answer: </b>Sometimes.</p> 
             <p>Either party may bring a lawsuit if the other party does not demand arbitration. We will 
          not demand arbitration of any lawsuit you bring as an individual action in small-claims court or an equivalent court. 
          However, we may demand arbitration of any transfer or removal of a small-claims court action to a different court, 
          any appeal of a small-claims decision or any small-claims action brought or amended to be on a class basis. Both 
          parties may use lawful self-help remedies. This includes set-off or repossession and sale of any collateral. Both 
          parties may seek remedies which don't claim money damages. This includes pre-judgment seizure, injunctions, or equitable 
          relief. Even if all parties have opted to litigate a Dispute in court, you or we may elect arbitration with respect to any 
          Dispute made by a new party or any Dispute later asserted by a party in that or any related or unrelated lawsuit 
          (including a Dispute initially asserted on an individual basis but modified to be asserted on a class, representative 
          or multi-party basis). Nothing in that litigation shall constitute a waiver of any rights under this Arbitration Agreement.</p>
          <p>Are you giving up any rights?</p>
          <p><b>Short answer: </b>Yes.</p>
          <p>For Disputes subject to this Arbitration Agreement, you and we give up our rights to:</p>
          <ul>
          <li>1.Have juries decide Disputes.</li>
          <li>2.Have courts, other than small-claims courts, decide Disputes.</li>
          <li>3.Serve as a private attorney general or in a representative capacity in court or in arbitration.</li>
          <li>4.Join a Dispute you, we or Related Parties have with a dispute by other consumers.</li>
          <li>5.Bring or be a class member in a class action in court or in class arbitration.</li>
          <li>6.You and we also give up the right to a jury trial and to have courts decide Disputes you or we wish to arbitrate.</li>
          </ul>
          <p>Can you or another consumer start class arbitration?</p>
          <p><b>Short answer: </b>No.</p>
          <p>The Arbitrator is not allowed to handle any Dispute on a class or representative basis.All Disputes subject to 
            this Arbitration Agreement must be decided in an individual arbitration or an individual small-claims action. 
            This Arbitration Agreement will 
          be void if a court rules that the Arbitrator can decide a Dispute on a class basis and the court’s ruling is 
          not reversed on appeal.</p>
          <p>What law applies?</p>
          <p><b>Short answer: </b>The Federal Arbitration Act (“FAA”).</p>
          <p>This Arbitration Agreement and your request for a spending limit involve interstate commerce. Thus, the FAA governs this 
          Arbitration Agreement. The Arbitrator must applysubstantive law consistent with the FAA. The Arbitrator must 
          honor statutes of limitation and privilege rights. The Arbitrator is authorized to award all remedies permitted by 
          applicable substantive law, including, without limitation, compensatory, statutory and punitive damages (subject to 
          constitutional limits that would apply in court), individual declaratory, injunctive and other equitable relief, 
          and attorneys’ fees and costs. In the event of any conflict or inconsistency between this Arbitration Agreement 
          and the administrator’s rules or the application, this Arbitration Agreement will govern.</p>
          <p>Will this Arbitration Agreement continue to govern?</p>
          <p><b>Short answer: </b>Yes, unless otherwise agreed or unless you opt out on a timely basis.</p>
          <p>This Arbitration Agreement stays in force even if you: (1) cancel or end the application; 
            (2) decide not to enter into a lease; or (3) go into or through bankruptcy.</p>
          <p>Process</p>
          <p>What must a party do before starting a lawsuit or arbitration?</p>
          <p><b>Short answer: </b>Send a written Dispute notice and work to resolve the Dispute.</p>
          <p>Before starting a lawsuit or arbitration, the complaining party must give the other party written notice of the Dispute. 
            The notice must explain in reasonable detail the nature of 
          the Dispute and any supporting facts. If you are the complaining party, you must send the notice in writing 
          (and not electronically) to our Legal Department, at our above address. You or an attorney you have personally hired 
          must sign the notice and must provide your full name and a phone number where you (or your attorney) can be reached. 
          A collections letter from us to you will serve as our written notice of a Dispute. Once a Dispute notice is sent, 
          the complaining party must give the other party a reasonable opportunity over the next 30 days to resolve the 
          Dispute on an individual basis.</p>
          <p>How does arbitration start?</p>
          <p><b>Short answer: </b>Mailing a notice.</p>
          <p>If the parties do not reach an agreement to resolve the Dispute within 30 days after notice of the 
            Dispute is received, the complaining party may commence a lawsuit or arbitration, subject to the terms of this 
            Arbitration Agreement. To start arbitration, the complaining party picks the administrator and follows the 
            administrator’s rules. If one party begins or threatens a lawsuit, the other party can demand arbitration. 
            This demand can be made in court papers, such as a motion to compel arbitration. It can be made if a party 
            begins a lawsuit on an individual basis and then tries to pursue a class action. Once an arbitration demand is made, 
            no lawsuit can be brought and any existinglawsuit must stop unless a court rules otherwise.</p>
          <p>Will any hearing be held nearby?</p>
          <p><b>Short answer: </b>Yes.</p>
          <p>The Arbitrator may decide that an in-person hearing is unnecessary and that he or she can resolve a Dispute based on written 
          filings and/or a conference call. However, any in-person arbitration hearing must be held at a place reasonably convenient 
          to you.</p>
          <p>What aboutWhat about appeals?</p>
          <p><b>Short answer: </b>Very limited.</p>
          <p>Appeal rights under the FAA are very limited. Except for FAA appeal rights and except for Disputes involving more than $50,000 
          (including Disputes involving requests for injunctive relief that could cost more than $50,000), 
          the Arbitrator’s award will be final and binding. For Claims involving more than $50,000, any party may appeal the award 
          to a three-arbitrator panel appointed by the administrator, which will reconsider from the start anything 
          in the initial award that is appealed. The panel’s decision will be final and binding, except for any FAA appeal right. 
          Any appropriate court may enter judgment upon the arbitrator’s award. No arbitration award involving the 
          parties will have any preclusive effect as to issues or claims in any dispute involving anyone who is not a 
          party to the arbitration, nor will an arbitration award in prior disputes involving other parties have preclusive 
          effect in an arbitration between the parties to this Arbitration Agreement.</p>
          <p>What What happens if a part of this Arbitration Agreement cannot be enforced?</p>
          <p><b>Short answer: </b>It depends.</p>
          <p>If any portion of this Arbitration Agreement cannot be enforced, the rest of the Arbitration Agreement will continue to apply, 
            except that (A) this Arbitration Agreement (except for this sentence) will be void if a court rules that the Arbitrator can 
            decide a Dispute on a class basis and the court's ruling is not reversed on appeal, and (B) if a claim is brought seeking 
            public injunctive relief and a court determines that the restrictions in this Arbitration Agreement prohibiting the 
            arbitrator from awarding relief onbehalf of third parties are unenforceable with respect to such claim (and that determination 
            becomes final after all appeals have been exhausted), the claim for publicinjunctive relief will be determined in court 
            and any individual claims seeking monetary relief will be arbitrated. In such a case the parties will request that the 
            court stay the claim for public injunctive relief until the arbitration award pertaining to individual relief has been 
            entered in court. In no event will class claims or claims for public injunctive relief be arbitrated.</p>
          <p>Arbitration Fees and Awards</p>
          <p>Who bears arbitration fees?</p>
          <p><b>Short answer: </b>Usually, we do.</p>
          <p>We will pay all filing, administrative, hearing, and Arbitrator’s fees if you act in good faith,
          cannot get a waiver of such fees and ask us to pay. We will always pay amounts required under applicable law or the 
          administrator's rules or if payment is required to enforce this Arbitration Agreement.</p>
          <p>When When will we cover your legal fees and costs?</p>
          <p><b>Short answer: </b>If you win.</p>
          <p>If you win an arbitration, we will pay your reasonable fees and costs for attorneys, experts, and witnesses. 
          We will also pay these amounts if required under applicable lawor the administrator’s rules or if payment is 
          required to enforce this Arbitration Agreement. The Arbitrator shall not limit his or her award of these amounts 
          because your Dispute is for a small amount.</p>
          <p>Will you Will you ever owe us for arbitration or attorneys’ fees?</p>
          <p><b>Short answer: </b>Only for bad faith.</p>
          <p>The Arbitrator can require you to pay our fees if (and only if): (1) the Arbitrator finds that you have acted in bad 
          faith (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)); and (2) this power does not 
          make this Arbitration Agreement invalid.Can a failure to resolve a Dispute informally result in a larger recovery for 
          you?</p>
          <p><b>Short answer: </b>Yes.</p>
          <p>You are entitled to an arbitration award of at least $7,500 if: (1) you give us notice of a Dispute 
          on your own behalf (and not on behalf of any other party) and comply with all of the requirements of this Arbitration 
          Agreement (including the requirements described in response to the question reading “What must a party do before starting 
          a lawsuit or arbitration?)”; and (2) the Arbitrator awards you money damages greater than the last amount you requested 
          before the was appointed. This is in addition to the attorneys’ fees and expenses (including expert witness fees and costs) 
          to which you are otherwise entitled. This $7,500 minimum award is a single award that applies to all Disputes you have 
          raised or could have raised in the arbitration. Multiple awards of $7,500 are not contemplated by this Arbitration Agreement. 
          Settlement demands and offers are strictly confidential. They may not be used in any proceeding by either party 
          except to justify a minimum recovery of $7,500.</p>
          <p>Can an award be explained?</p>
          <p><b>Short answer: </b>Yes.</p>
          <p>A party may request details from the Arbitrator, within 14 days of the ruling. Upon such request, the Arbitrator will 
          explain the ruling in writing.</p>
      </div>
    );
  }
}

export default ArbitrationAgreement;