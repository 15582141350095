import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { AnalyticAPI } from "api/analytic";
import {allSelectedOffersSelector, trendingsVisitID} from '../../selectors';
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { addThousandsSeparator, paymentFrequency } from "utils/helpers";

class KornerstoneOffer extends Component {
  state = {
    loading: false,
    selectedOption: null,
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, offer);
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = () => {
    const { selectedOption } = this.state;
    const { allSelectedOffers, setSelectedOffer, nextStep, token, visitID, logContinueEvent } = this.props;

    AnalyticAPI.send_provider_analytic_event(
      token,
      "select_provider",
      "kornerstone",
      visitID
    );
    this.setState({ loading: true });
    logContinueEvent();
    setSelectedOffer(selectedOption);

    const isContractOpened = allSelectedOffers.some(offer => offer.app_id === selectedOption.app_id)
    nextStep({ step: isContractOpened ? 'contractStep' : 'confirmationStep', data: selectedOption });
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, available, isExpanded } = this.props;

    if (!selectedOption) {
      return "";
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={addThousandsSeparator(selectedOption.first_payment)}
          specificProviderName={selectedOption.specific_provider_name}
          paymentPeriod={`${selectedOption.payment_period} Months`}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={selectedOption.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption)} />
            </div>
            <BuyoutInfo
              title={"Buyout Anytime - Simple. Transparent. No Hidden Fees."}
              text={"Google Rating - 4.8 with 9,700+ reviews"}
              footnote={
                <>
                  We do not accept Online Banks such as:
                  <br/>
                  Cash App, Chime, Varo, Go2Bank, Aspiration, Etc.
                </>
              }
            />
            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!available}
              type="submit"
            >
              Continue with Kornerstone
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

KornerstoneOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

KornerstoneOffer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  allSelectedOffers: allSelectedOffersSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
    setSelectedOffer: (value) => dispatch(setSelectedOffer(value)),
  };
};

KornerstoneOffer = connect(
  mapStateToProps,
  mapDistpatchToProps
)(KornerstoneOffer);

KornerstoneOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(KornerstoneOffer);

export default KornerstoneOffer;
