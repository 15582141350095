import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {PROGRESSIVE_STEP__FIELDS} from 'containers/MultistepForm/constants/collections';
import { onlyNumbers } from "utils/normalize";
import { Field, reduxForm } from "redux-form";
import {
	isLessThan20digits,
	required,
} from "utils/validations";
import { TextField } from "components/Fields";
import { formValueSelector } from 'redux-form'
const selector = formValueSelector('agreement_data_form')
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

import asyncValidate from '../../../../../components/DataDisplay/AdditionalInfoBlock/asyncValidate';
import URLS from '../../../../../utils/import_urls';
import {CustomCheckbox} from '../../../../../components/Controls';
import {userInfoSelector} from '../../../selectors';

class Form extends PureComponent {
	componentDidMount() {
		this.checkInitialValidity();
	}

	checkInitialValidity = () => {
		const { additionalData, onDataChange } = this.props;
		const valid = !!additionalData.isAgreementChecked;
		onDataChange({
			data: additionalData,
			valid: valid
		});
	}

	handleChange = () => {
		const { additionalData, onDataChange, valid } = this.props || {}
		onDataChange({
			data: additionalData,
			valid: valid
		});
	}

	renderCheckbox = ({ input, meta }) => {
		return <CustomCheckbox meta={meta} {...input} customChange={this.handleChange}
		/>;
	};

	render() {
		const {userInfo} = this.props;
		return (
      <div className="agreement-form-checkbox-container">
        <div className="flex flex__align--baseline agreement-form-checkbox">
          <Field
            name="isAgreementChecked"
            validate={[required]}
            component={this.renderCheckbox}
          />
          <div>
            I have read and I agree to all of the above statements. I further
            agree to the
            <a href="#" className="link" onClick={this.reviewAgreement}>
              Terms of Use
            </a>
            ,&nbsp;
            <a href={URLS.DISCLOSURE_FS} className="link" target="_blank">
              Privacy Policy
            </a>
            ,&nbsp;
            <a href={URLS.TERMS_FS} className="link" target="_blank">
              Arbitration Provision
            </a>
            ,&nbsp;
            <a href={URLS.PRIVACY_FS} className="link" target="_blank">
              Application Disclosure
            </a>
            and&nbsp;
            <a href={URLS.PRIVACY_FS} className="link" target="_blank">
              E-Sign Disclosure
            </a>
            .{" "}
          </div>
        </div>
        <div className="flex flex__align--baseline agreement-form-checkbox">
          <Field name="isConsentChecked" component={this.renderCheckbox} />
          <div>
            {`Yes! I would like to receive marketing
						communications at the number (${userInfo.phone}) from or on behalf of Progressive Leasing. These communications may be delivered using an auto dialer or a prerecorded message. Consent is not a condition of application, lease, or purchase. For text messages, message and data rates may apply.
						Text STOP to cancel future marketing messages.
						Text HELP for help. Message frequency is recurring.`}
          </div>
        </div>
      </div>
    );
	};
}

Form.propTypes = {
	onDataChange: PropTypes.func
};

Form.defaultProps = {
	onDataChange: () => {}
};

Form = reduxForm({
	form: "agreement_data_form",
	initialValues: { isAgreementChecked: false, isConsentChecked: false},
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(Form);

Form = connect(
	state => ({
		additionalData: selector(state, 'isAgreementChecked', 'isConsentChecked'),
		token: tokenSelector()(state),
		userInfo: userInfoSelector()(state)
	})
)(Form);



export default Form;
