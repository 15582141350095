import React, { Component } from "react";
import {
  selectedOfferSelector,
  affiliatedProviderSelector,
  subscribingDataSelector,
  trendingsVisitID
} from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { AnalyticAPI } from 'api/analytic';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { CompletePurchase } from "components/FlexshopperMetrics";

import "./styles.scss";
import { reset } from "redux-form";

class SuccessStep extends Component {

  componentDidMount() {
    const {
      token,
      visitID
    } = this.props;

    AnalyticAPI.send_analytic_event(token, 'lease_finalized', visitID)
  }

  componentWillUnmount() {
    const { resetForm } = this.props;
    resetForm();
  }

  providerName = () => {
    const names = {
      flexshopper: "FlexShopper",
      paytomorrow: "PayTomorrow",
      greenwave: "GreenWave",
      genesis: "Genesis",
      okinus: "Okinus",
      aff: 'American First Finance',
      snap: 'Snap',
      acima: 'Acima',
      uown: 'Uown',
      koalafiLo: 'KoalafiLO',
      koalafiLe: 'KoalafiLE',
      ownlease: 'Own.lease',
      katapult: 'Katapult',
      bread: 'Bread Pay',
      progressive: 'Progressive Leasing'
    }
    const { selectedOffer } = this.props
    return names[selectedOffer.specific_provider_name] || ""
  }

  render() { 
    const { providerName, orderData } = this.props

    return (
      <div className="text-center step-message">
        { providerName == 'flexshopper' &&
          <CompletePurchase
            orderNumber={orderData.order_id}
            orderTotal={orderData.total_price}
          />
        }
        <h2>Thank you!</h2> 
        <p>Your documents were signed successfully.</p>
        <p>Thank you for using {this.providerName()}</p>
        <p>We hope you enjoy your merchandise</p>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector(),
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  providerName: affiliatedProviderSelector(),
  orderData: subscribingDataSelector()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(reset('form'))
  };
};

SuccessStep = connect(mapStateToProps, mapDistpatchToProps)(SuccessStep);

export default SuccessStep;
