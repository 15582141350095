import React, { PureComponent } from "react";
import ExpandedOfferBlock from "../Step3/ExpandedOfferBlock";
import {
  initApplicationResponseSelector, userInfoSelector,
  additionalsRequiredApplicationsSelector, additionalsProvidedSelector,
  approvedApplicationsSelector, trendingsVisitID, maxApproveSelector
} from '../selectors';
import { selectOffer, nextStep } from "../actions";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { BlueBtn } from "components/Controls";
import { AnalyticAPI } from 'api/analytic';
import AdditionalDataRequiredOffer from "../Step3/AdditionalDataRequiredOffer";
import CheckIconGreen from 'assets/img/check-icon-green.png'
import { closeModal } from "../../MultistepModal/actions";
import "../Step3/styles.scss";
import "./styles.scss";

class Step3Prequalification extends PureComponent {
  state = {
    additionalInfoBlock: false,
    orderedOffers: [],
    offersWithOptions: [],
    isCompareMode: false
  }

  constructor(props) {
    super(props);
    this.state.orderedOffers = this.props.availableApplications;
  }

  componentDidUpdate(props) {
    if (props.initAppResponse.additionals_provided || props.initAppResponse.updated) {
      this.setState({ additionalInfoBlock: false })
    }
  }

  componentDidMount() {
    const {
      additionalsRequiredApplications,
      additionalsProvided,
      availableApplications,
      token,
      visitID
    } = this.props;

    AnalyticAPI.send_analytic_event(token, 'approvals', visitID)

    if (!additionalsProvided && additionalsRequiredApplications.length > 0
      && availableApplications.length === 0
    ) {
      this.setState({ additionalInfoBlock: true })
    }
  }

  addOffersForSort = (offer) => {
    this.setState(({offersWithOptions}) => {
      const newSortedOffers = offersWithOptions.filter(elem => elem.specific_provider_name !== offer.specific_provider_name)
      return {offersWithOptions: [...newSortedOffers, offer]}})
  }

  render() {
    const {
      maxApprove,
      closeModal,
    } = this.props;

    const { additionalInfoBlock, orderedOffers } = this.state;

    const isFlexshopperOnly = orderedOffers.length === 1 && orderedOffers[0].provider === 'flexshopper' && orderedOffers[0].authentication_is_required;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
    return (
      <div className="step3-prequalification">
        <div className='header-text-block congrats-text-block'>
          <div className="header-text-block__icon"><img src={CheckIconGreen}/></div>
          <div className="congrats-text">Congrats, You’re Prequalified!</div>
        </div>
        <div className="header-text-block">
          <div className='info-text__prequalification'>Here’s your max approval amount:</div>
        </div>
        <div className="header-text-block">
          <div className="max-approve">{formatter.format(maxApprove)}</div>
        </div>

        <div className="step3 paypair-step-content">
        <div className="line"></div>

          {isFlexshopperOnly && (
            <div>
              <h2 className="subtitle font-center">We've found your Flexshopper account!</h2>
              <h2 className="title2 font-center "></h2>
            </div>
          )}

          <div className="step3__content flex flex--column">
            {orderedOffers.map((offer, index) => {
              return (<ExpandedOfferBlock
                offer={offer}
                key={offer.specific_provider_name}
                addOffersForSort={this.addOffersForSort}
              />)
            })}

            {additionalInfoBlock && (<AdditionalDataRequiredOffer />)}
          </div>

          <div className="start-shopping">
            <BlueBtn
              customClass="start-shopping-btn"
              onClick={closeModal}
            >
              Start Shopping
            </BlueBtn>
          </div>
        </div>
      </div>
    );

  }
}

const mapStateToProps = createStructuredSelector({
  initAppResponse: initApplicationResponseSelector(),
  availableApplications: approvedApplicationsSelector(),
  additionalsRequiredApplications: additionalsRequiredApplicationsSelector(),
  additionalsProvided: additionalsProvidedSelector(),
  userInfo: userInfoSelector(),
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  maxApprove: maxApproveSelector()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    closeModal: () => dispatch(closeModal())
  };
};

Step3Prequalification = connect(mapStateToProps, mapDistpatchToProps)(Step3Prequalification);

export default Step3Prequalification;
