import React, { Component } from "react";
import { activeStepDataSelector } from "../selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import "./styles.scss";

class ErrorStep extends Component {
  render() { 
    const {activeStepData} = this.props;
    return (
      <div className="step-message">
        <h3 className="text-center">{activeStepData.title || ''}</h3>
        <p className="text-center">{activeStepData.message || ''}</p>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  activeStepData: activeStepDataSelector(),
});

ErrorStep = connect(mapStateToProps)(ErrorStep);
export default ErrorStep;
