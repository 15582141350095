import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import {
  loadingSelector,
  activeStepDataSelector,
  appRequestSelector,
} from "containers/MultistepForm/selectors";
import { nextStep, selectOffer } from "containers/MultistepForm/actions";

import { selectedOfferSelector } from "../../../selectors";

import "./styles.scss";
import ProgressiveLogo from "../../../../../assets/img/progressive-logo.png";
import Form from "./Form";
import { ProvidersApi } from "../../../../../api/providers";
import { tokenSelector } from "../../../../EmbeddableWidget/selectors";

class BankDataForm extends Component {
  state = {
    additionalData: {},
    valid: false,
    loadingLease: false,
  };

  buildBodyToInitApplication = () => {
    const { additionalData } = this.state;
    const { appRequest } = this.props;
    const applicationRequest = this.props.offer;

    if (additionalData.isAgreementChecked) {
      applicationRequest.additional_info_data.agreementInfo = {
        isAgreementChecked: additionalData.isAgreementChecked,
        isConsentChecked: additionalData.isConsentChecked,
      };
    }

    return { ...appRequest, ...applicationRequest };
  };

  submitForm = (e) => {
    const { valid } = this.state;
    const { token, nextStep, selectedOffer } = this.props;
    e.preventDefault();
    if (valid) {
      this.setState((prev) => ({
        ...prev,
        loadingLease: true,
      }));
      const offerData = this.buildBodyToInitApplication();

      return ProvidersApi.updateProgressive(token, offerData)
        .then((resp) => {
          const data = {
            ...selectedOffer,
            lease_id: resp.application_id,
            approval_amount: resp.approval_amount,
            pricing_estimate: resp.pricing_estimate,
          };
          if (resp.status === "Approved") {
            return nextStep({ step: "progressiveApprovedAppScreen", data });
          } else if (resp.status === "Denied") {
            return nextStep({ step: "progressiveDeniedAppScreen", data });
          } else {
            return nextStep({ step: "progressivePendingAppScreen", data });
          }
        })
        .catch(() => {
          return nextStep({ step: "nobodyApprovedStep" });
        })
        .finally(() => {
          this.setState((prev) => ({
            ...prev,
            loadingLease: false,
          }));
        });
    }
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      valid: data.valid,
    });
  };

  render() {
    const { loading, selectedOffer } = this.props;
    const { valid, loadingLease } = this.state;

    return (
      <div className="expanded-block-component bank-data-form-container">
        <div className="bank-data-form-header">
          <img src={ProgressiveLogo} alt="Progressive Logo" />
          <h1>Apply for Lease-to-Own</h1>
          <h2>Submit Application</h2>
        </div>
        <ul className="agreement-form-list">
          <li>
            I certify that I provided complete and accurate information on my
            application.
          </li>
          <li>
            I authorize Prog Leasing, LLC and/or its affiliates ("Progressive")
            to obtain reports about me from consumer reporting agencies. I agree
            that Progressive may use my consumer reports for any lawful purpose,
            such as: (1) authenticating my identity; (2) making lease-to-own
            eligibility decisions; (3) assisting with internal modeling and
            analysis; (4) servicing my account throughout the life of my lease;
            and (5) verifying information in my application.
          </li>
          <li>
            I authorize Progressive Leasing to share information regarding my
            application, including the decision, with the retailer.
          </li>
        </ul>
        <Form
          onDataChange={this.setAdditionalData}
          providerName={selectedOffer.specific_provider_name}
        />
        <BlueBtn
          customClass="bank-data-form-submit-btn"
          disabled={!valid}
          loading={loading || loadingLease}
          onClick={this.submitForm}
        >
          Agree and Submit
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: loadingSelector()(state),
  token: tokenSelector()(state),
  offer: activeStepDataSelector()(state),
  selectedOffer: selectedOfferSelector()(state),
  appRequest: appRequestSelector()(state),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
  };
};

BankDataForm = connect(mapStateToProps, mapDistpatchToProps)(BankDataForm);

export default BankDataForm;
