import React, { Component } from "react";
import classNames from "classnames/bind";
import { CustomTooltip } from "components/DataDisplay";
import { ExpandBtn } from "components/Controls";

import "./styles.scss";

class OfferItem extends Component {
  state = {
    detailsShown: false
  };

  renderTitle = () => {
    const { title, titleTooltip, titleTooltipIcon } = this.props
    if (titleTooltip) {
      return (<CustomTooltip
        title={`${title}:`}
        titleBold={true}
        text={titleTooltip}
      />)
    } else if (titleTooltipIcon) {
      return (
        <div>
          <strong style={{ marginRight: 2 + 'px' }}>{title}</strong>
          <CustomTooltip
            text={titleTooltipIcon}
          />
          :
        </div>
      )
    } else {
      return (<span>{title}:</span>)
    }
  }

  toggleDetails = () => {
    this.setState({detailsShown: !this.state.detailsShown})
  }

  render() {
    const { value, note, isColumn, tooltip, details } = this.props
    const { detailsShown } = this.state
    return (
      <div>
        <div
          className={classNames(`offer-item`, {
            'offer-item__column': isColumn
          })}>
          <span className="offer-item__title ">
            <span className="pos-relative">
              {this.renderTitle()}
            </span>
          </span>
          <span className="offer-item-value dfjce">
            <div className="pos-relative flex flex__align--center flex__justify--end">
              <span className="font-bold text-right" dangerouslySetInnerHTML={{ __html: value }}></span>
              {tooltip && (<CustomTooltip text={tooltip} />)}
              {note && <span className="offer-item__frequency-text">{note}</span>}
              {details && <span className="payment-details">
                <ExpandBtn 
                  customClass="step-tooltip-button"
                  onClick={this.toggleDetails} 
                  isExpanded={detailsShown}
                >
                  <span className="step-tooltip-button__text">
                    {details.title}
                  </span>
                </ExpandBtn>
              </span>}
            </div>
          </span>
        </div>
        {details && detailsShown && <span>{details.expandedValue}</span>}
      </div>
    );
  }
};

export default OfferItem;
