import React, { Component } from "react";
import { connect } from "react-redux";
import { finishApplication, cancelApplication } from "../../actions";
import PropTypes from "prop-types";
import Frame from "../Frame";
import { closeModal } from "containers/MultistepModal/actions";

class AffContract extends Component {

  receiveMessage = (event) => {
    const { finishApplication, cancelApplication, closeModal } = this.props
    switch (event.data.event) {
      case "signing-complete":
        finishApplication();
        break;
    }
  }

  render() {
    const { contractUrl, hidden } = this.props
    return (
      <Frame
        contractUrl={contractUrl}
        hidden={hidden}
        onReceiveMessage={this.receiveMessage}
      />
    )
  }
}

AffContract.propTypes = {
  contractUrl: PropTypes.string
};

AffContract.defaultProps = {
  contractUrl: ""
};

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values)),
    cancelApplication: values => dispatch(cancelApplication(values)),
    closeModal: () => dispatch(closeModal())
  };
};

AffContract = connect(null, mapDistpatchToProps)(AffContract);

export default AffContract;

