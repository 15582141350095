import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { createStructuredSelector } from "reselect";
import {
  activeStepDataSelector,
  activeStepSelector,
  allSelectedOffersSelector,
  availableApplicationsSelector,
  multipleIframeFlagSelector,
  selectedOfferSelector,
} from './selectors';
import { backToOffers, init } from "./actions";
import { TextBtn } from "../../components/Controls";
import ContractStep from "./ContractStep";
import { getForms } from "./constants";
import { logEvent } from "../EmbeddableWidget/actions";

class MultistepForm extends Component {
  handleBackButton = () => {
    const { backToOffers, logEvent } = this.props;
    backToOffers();
    logEvent({ name: "view other options" });
  };

  renderContracts = () => {
    const { activeStepData, activeStep, selectedOffer, allSelectedOffers } = this.props;

    if (!allSelectedOffers || allSelectedOffers.length === 0) {
      return null;
    }

    return allSelectedOffers.map((offer) => {
      const isIframeVisible =
        activeStep === "contractStep" && (offer?.app_id === selectedOffer?.app_id || offer?.app_id === activeStepData?.app_id);
      return (
        <ContractStep
          key={offer?.app_id}
          offer={offer}
          hidden={!isIframeVisible}
        />
      );
    });
  };

  render() {
    const {
      activeStep,
      multipleIframeEnabled,
      availableApplications,
    } = this.props;
    const FORMS = getForms(multipleIframeEnabled);
    const Form = multipleIframeEnabled && activeStep === "contractStep" ? null : FORMS[activeStep]?.form;

    return (
      <div className="multistep-form">
        {multipleIframeEnabled && (
          <>
            {activeStep === "contractStep" &&
              availableApplications?.length > 1 && (
                <>
                  <TextBtn
                    customClass="step5__change-btn"
                    onClick={this.handleBackButton}
                  >
                    View Other Options
                  </TextBtn>
                </>
              )}
            {this.renderContracts()}
          </>
        )}
        {Form && <Form />}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  activeStep: activeStepSelector(),
  allSelectedOffers: allSelectedOffersSelector(),
  selectedOffer: selectedOfferSelector(),
  multipleIframeEnabled: multipleIframeFlagSelector(),
  availableApplications: availableApplicationsSelector(),
  activeStepData: activeStepDataSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    init: (values) => dispatch(init(values)),
    backToOffers: () => dispatch(backToOffers()),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(MultistepForm);
