export const addMetrix = (requestData) => {
  return {
    ...allMetrix(requestData.leaseForm),
    ...requestData.leaseForm.applicationRequest
  }
}

const allMetrix = (data) => {
  let tevId, thrt_metrix_id
  if (window.TrustevV2 && window.TrustevV2.SessionId) {
    tevId = window.TrustevV2.SessionId;
  }
  if (typeof affTmSessionId !== 'undefined' && affTmSessionId.length) {
    thrt_metrix_id = affTmSessionId;
  }

  return {
      metrixSessionId: data.metrixSessionId,
      threatMetrixPayTomorrowSessionId: data.threatMetrixPayTomorrowSessionId,
      snapSessionId: data.snapSessionId,
      uownSessionId: data.uownSessionId,
      blackboxId: data.blackboxId,
      tevId: tevId,
      thrtMetrixId: thrt_metrix_id,
  }
}
