import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ValidationIcon } from "components/Controls";

import "./styles.scss";

const TextField = ({ onChange, customChange, input, label, meta: {valid, error, touched, asyncValidating}, ...props }) => {

  const combineChange = (e) => {
    const value = e.target.value;
    onChange(value);
    setTimeout(() => customChange(value), 0);
  };
  
  return (
    <div className="text-field-component flex flex--column">
      <label className="font-uppercased text-field-label">{label}</label>
        <div className="text-field-wrapper">
          <input
            onChange={combineChange}
            className={classNames("text-field font-bold", {
              invalid: !valid && touched,
              valid: !asyncValidating && touched && valid
            })}
            {...input} 
            {...props}
          />

          <ValidationIcon
            asyncValidating={asyncValidating}
            touched={touched}
            valid={valid}
          />

        </div>

        <div className={classNames("text-field-error", {
          shown: touched && error !== undefined && error !== 'required'
        })}>
        <div className="text-field-error__wrapper">{error}</div>
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  error: PropTypes.string,
  customChange: PropTypes.func
};

TextField.defaultProps = {
  customChange: () => {}
};

export default TextField;
