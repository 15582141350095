import React, { Component } from "react";
import PropTypes from "prop-types";
import { OfferView } from "components/DataDisplay";
import {
  buildFlexshopperOfferPaymentDetailsData
} from "containers/MultistepForm/helpers";
import "./styles.scss";

class PaymentDetails extends Component {

  render() {
    const { selectedOption } = this.props;

    if (!selectedOption) {
      return '';
    }

    return (
      <div className="step-tooltip-block">
        <div className="flex step-tooltip-details">
          <div className="step-tooltip-details__title">Payment Details:</div>
          <div className="step-tooltip-details__value">
            <div className="payment-details__content">
              <OfferView offerData={buildFlexshopperOfferPaymentDetailsData(selectedOption)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


PaymentDetails.propTypes = {
  selectedOption: PropTypes.object.isRequired,
};

export default PaymentDetails;
