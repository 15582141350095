/* eslint-disable no-unused-expressions */
import React from "react";

const IconClose = props => (
  <svg {...props} viewBox="0 0 16 16">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="2.5"
    >
      <path d="M14 0L0 14M0 0L14 14" transform="translate(1 1)" />
    </g>
  </svg>
);

export default IconClose;
