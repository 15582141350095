import React from 'react';

import { createSelectOptions } from 'utils/helpers';
import Select from "react-select";
import classNames from "classnames";

const SelectQuantity = ({
	isStaggered,
	name,
	item,
	onSelect,
	selectedQuantity,
	max = 24,
	disabled,
	...props
}) => {
	const defaultQuantity = isStaggered ? { label: 2, value: 2 } : { label: 4, value: 4 };
	selectedQuantity = { label: selectedQuantity, value: selectedQuantity }

	return (
		<div
			className={
				classNames("product-quantity-select",
					`${isStaggered
						? 'product-quantity-select-container product-quantity-select-container--staggered'
						: 'product-quantity-select-container'}`)
			}
		>
			<div  className="select-field-wrapper">
				<Select
					{...props}
					isDisabled={disabled || false}
					value={selectedQuantity || defaultQuantity}
					options={createSelectOptions(max)}
					onChange={e => onSelect(item, e.value)}
					name={name} />
			</div>
		</div>
	);
};

export default SelectQuantity;