import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { reduxForm, Field, change } from "redux-form";
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { RadioField } from "components/Fields";
import { AnalyticAPI } from "api/analytic";
import {allSelectedOffersSelector, trendingsVisitID} from '../../selectors';
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import { capitalizeFirstletter, addThousandsSeparator, paymentFrequency } from "utils/helpers";

class UownOffer extends Component {
  state = {
    loading: false,
    selectedOption: {
      provider: "",
      type: "",
      offer_id: null,
      payment_schedule: "",
      payment_period: null,
      payment_period_rate: null,
      first_period: null,
      first_period_rate: null,
      last_period: null,
      last_period_rate: null,
      buyout: false,
      buyout_rate: null,
      initial_payment: null,
    },
  };

  componentDidMount = () => {
    const { offer } = this.props;
    const defaultSelected = offer.options?.[0] || {};
    this.setSelected(defaultSelected);
    this.props.dispatch(
      change(
        "form",
        "paymentSchedule",
        defaultSelected.payment_schedule
      )
    );
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      specific_provider_name: offer.specific_provider_name,
      approved_amount: offer.approved_amount,
      cart_total: offer.cart_total,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { allSelectedOffers, setSelectedOffer, nextStep, selectOffer, offer, logContinueEvent } = this.props;
    const { selectedOption } = this.state;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(token, 'select_provider', 'uown', visitID);
    logContinueEvent()
    // selectOffer({ selectedOffer: selectedOption });
    this.setState({ loading: true })
    setSelectedOffer(selectedOption);

    const isContractOpened = allSelectedOffers.some(offer => offer.app_id === selectedOption.app_id)
    nextStep({ step: isContractOpened ? 'contractStep' : 'confirmationStep', data: selectedOption });
  };

  renderPaymentScheduleField = ({ input, meta }) => {
    return (
      <div className="offer-block-wrapper offer-block offer-block--100 flex">
        <div className="offer-item__title__pf">Payment Frequency:</div>
        <RadioField
          {...input}
          meta={meta}
          options={this.paymentScheduleOptions()}
          customChange={this.setPaymentSchedule}
        />
      </div>
    );
  }

  setPaymentSchedule = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_schedule === value
    );

    this.setSelected(option);
  };

  paymentScheduleOptions = () => {
    const { offer } = this.props;
    return offer.options.map((option) => {
      return {
        value: capitalizeFirstletter(option.payment_schedule),
        key: option.payment_schedule
      };
    });
  };

  // attantion for this
  renderPaymentSchedule() {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'PAYMENT SCHEDULE',
          value: `${selectedOption.payment_schedule}`
        }]}
      />
    } else {
      return (
      <div className="offer-block-wrapper offer-block offer-block--100 flex">
        <div className="offer-block offer-block--100">
          <Field
            name="paymentSchedule"
            component={this.renderPaymentScheduleField}
          />
        </div>
      </div>
      )
    }
  }


  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, available, isExpanded, offer } = this.props;

    if (!selectedOption) {
      return '';
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={addThousandsSeparator(selectedOption.initial_payment)}
          specificProviderName={offer.specific_provider_name}
          paymentPeriod={selectedOption.payment_period ? `${selectedOption.payment_period} Months` : null}
          paymentFrequency={paymentFrequency(selectedOption)}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (

          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="uown-offer application-offer"
          >
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView offerData={buildOfferData(selectedOption)} />
              {this.renderPaymentSchedule()}
              <OfferView offerData={buildOfferData(selectedOption, {}, true)} />
            </div>

            <BuyoutInfo
              title={'Early Buyout Option:'}
              text={'If paid in full within 90 days, the total price owed is only the order amount + application fee + sales tax.'}
              footnote={''}
            />

            <BlueBtn
              customClass="step3__recommended-content-btn"
              loading={loading}
              disabled={!(available)}
              type="submit"
            >
              Continue with Uown
            </BlueBtn>
          </form>

        )}
      </div>
    );
  }
}

UownOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
  getSelectedOffer: PropTypes.func
};

UownOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  visitID: trendingsVisitID(),
  allSelectedOffers: allSelectedOffersSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

UownOffer = connect(mapStateToProps, mapDistpatchToProps)(UownOffer);

UownOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(UownOffer);

export default UownOffer;
