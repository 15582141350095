import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn, IconBtn } from "components/Controls";
import { createStructuredSelector } from "reselect";
import { userInfoSelector, loadingSelector, formValuesSelector } from "../selectors";
import { continueInitApplication } from "../actions";
import { closeModal } from "../../MultistepModal/actions";

import AdditionalInfoBlock from "components/DataDisplay/AdditionalInfoBlock"; 

import "./styles.scss";

class AdditionalInfoStep extends Component {

  state = {
    additionalData: {},
    valid: false
  }

  buildBodyToInitApplication = () => {
    const { additionalData } = this.state;  

    const applicationRequest = {}
    applicationRequest.has_additional_info = true;
    applicationRequest.additional_info_data = {};
    if(additionalData.routingNum && additionalData.accountNum ){
      applicationRequest.additional_info_data.bankAccount = {
        routing: additionalData.routingNum,
        account: additionalData.accountNum,
        type: 'checking'
      }
    }

    return applicationRequest;
  }
  
  submitForm = (e) => {
    const { valid } = this.state;
    e.preventDefault();
    if (valid) {
      this.props.continueInitApplication({values: this.buildBodyToInitApplication()});
    }
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      valid: data.valid
    });
  }

  renderForm = () => {
    const { loading, closeModal } = this.props;  
    const { valid } = this.state;  

    return (
      <div>
        <div className="paypair-step-content">
          <div className="additional-info-step__form-block">
            <div className="additional-info-step__form-block-title">
              BANK ACCOUNT INFORMATION
            </div>
            <div className="additional-info-step__form-block-content">
            
                <AdditionalInfoBlock
                  onDataChange={this.setAdditionalData}
                />
            </div>
          </div>
       
        <BlueBtn  customClass="additional-info-step__submit-btn"
          disabled={!valid}
          loading={loading}
          onClick={this.submitForm}
        >Submit</BlueBtn>
        <IconBtn
          customClass="additional-info-step__back-btn" 
          onClick={closeModal}
        >return to store</IconBtn>
         </div>
    </div>);
  };

  render() {
    const { userInfo } = this.props;

    return (
      <div className="additional-info-step flex flex--column flex__align--center ">
        <span className="subtitle font-center">Hi, {userInfo.firstName}!</span>
        <span className="additional-info-step__text">
          Your application was not approved by some of the payment providers
          but don’t worry - we can still help!
        </span>
        <span className="additional-info-step__text">
          You can apply to more financial companies by filling out the
          information below. 
        </span>
        <span className="additional-info-step__text">
          This is completely <span className="font-italic">optional.</span>
        </span>
        {this.renderForm()}
      </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  values: formValuesSelector(),
  userInfo: userInfoSelector(),
  loading: loadingSelector(),
});


const mapDistpatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
    continueInitApplication: values => dispatch(continueInitApplication(values))
  };
};

AdditionalInfoStep = connect(
  mapStateToProps,
  mapDistpatchToProps
)(AdditionalInfoStep);

export default AdditionalInfoStep;
