import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import { IconBtn, BlueBtn } from "components/Controls";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import { modalOpenedSelector } from "./selectors";
import { openModal, closeModal } from "./actions";
import { createStructuredSelector } from "reselect";
import CloseIcon from "assets/img/close_icon.svg";

import MultistepForm from "../MultistepForm";
import {
  activeStepSelector,
  applicationHasContractSelector,
  closingSelector,
  selectedOfferSignedSelector,
} from "containers/MultistepForm/selectors";

import "./styles.scss";
import {
  finishApplication,
  startClosingModalWithLoader,
  finishClosingModalAndRemoveLoader,
} from "../MultistepForm/actions";

class MultistepModal extends Component {
  handleOpenModal = () => {
    this.props.openModal();
  };

  componentDidUpdate(prevProps) {
    const {
      closeModal,
      applicationSigned,
      isClosing,
      finishClosingModalAndRemoveLoader,
    } = this.props;

    if (prevProps.applicationSigned !== applicationSigned) {
      if (applicationSigned && isClosing) {
        clearTimeout(this.timeoutId);
        closeModal();
        finishClosingModalAndRemoveLoader();
      }
    }
  }

  handleCloseModal = () => {
    const {
      applicationHasContract,
      closeModal,
      finishApplication,
      applicationSigned,
      startClosingModalWithLoader,
      finishClosingModalAndRemoveLoader,
      activeStep,
    } = this.props;

    if (
      !applicationHasContract ||
      (applicationHasContract && activeStep === "confirmationStep") ||
      applicationSigned
    ) {
      closeModal();
      return;
    }

    startClosingModalWithLoader();

    this.timeoutId = setTimeout(() => {
      if (!applicationSigned) {
        finishApplication({ status: "exited" });
      }
      closeModal();
      finishClosingModalAndRemoveLoader();
    }, 5000);
  };

  componentDidMount = () => {
    this.handleOpenModal();
  };

  renderModalContent = () => {
    const { activeStep } = this.props;
    const noFooterRenderSteps = ["loader", "step3Prequalification"];

    return (
      <React.Fragment>
        <IconBtn
          customClass="multistep-modal__close-btn"
          onClick={this.handleCloseModal}
        >
          <img src={CloseIcon} />
        </IconBtn>
        <ModalHeader />
        <MultistepForm />
        {!noFooterRenderSteps.includes(activeStep) && <ModalFooter />}
      </React.Fragment>
    );
  };

  render() {
    const { modalOpened } = this.props;
    return (
      <div>
        {/* <BlueBtn onClick={this.handleOpenModal}>Open Modal</BlueBtn> */}
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={modalOpened}
          onClose={this.handleCloseModal}
        >
          <div className="multistep-modal__wrapper">
            {this.renderModalContent()}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modalOpened: modalOpenedSelector(),
  activeStep: activeStepSelector(),
  applicationHasContract: applicationHasContractSelector(),
  applicationSigned: selectedOfferSignedSelector(),
  isClosing: closingSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
    finishApplication: (values) => dispatch(finishApplication(values)),
    startClosingModalWithLoader: () => dispatch(startClosingModalWithLoader()),
    finishClosingModalAndRemoveLoader: () =>
      dispatch(finishClosingModalAndRemoveLoader()),
  };
};

MultistepModal = connect(mapStateToProps, mapDistpatchToProps)(MultistepModal);

export default reduxForm({
  form: "form",
})(MultistepModal);
