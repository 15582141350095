import { ValidtionsAPI } from 'api/validations';

const asyncValidate = (values, dispatch, {token, providerName} ) => {
  if (values.routingNum.length === 9) {
    return ValidtionsAPI.validate_routing_number(token, values.routingNum, providerName === 'progressive' ? 'progressive' : 'flexshopper').then(data => {
      if (!data.valid && process.env.REACT_APP_PAYPAIR_ENV === 'production') {
        throw { routingNum: 'Routing number is invalid' }
      }
    })
  } else {
    return new Promise(resolve => {
      throw { routingNum: 'Number must be 9 digits long' }
    })
  }
}

export default asyncValidate
