import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const setHeight = (e) => {
  const iframe = document.getElementById('contract-iframe');
  const container = document.getElementById('contract-iframe-wrapper');

  // set iframe content style to count correct height
  var style = document.createElement('style');
  style.textContent =

    'html, body {\
      height: 100%\
      width: 100%;\
      overflow: hidden;\
      margin: 0;\
    };\
    body > *{\
      padding-right: 10px;\
    }\
    @media (max-width:  640px) {\
      body > table, \
      body > table > tbody, \
      body > table > tbody > tr, \
      body > table > tbody > tr > td \
      body > table > tbody > tr > td > table, \
      body > table > tbody > tr > td > table > tbody, \
      body > table > tbody > tr > td > table > tbody > tr, \
      body > table > tbody > tr > td > table > tbody > tr > td {\
        display: block;\
      }\
      #initial-payment, div.hangtag {\
        transform: scale(0.8);\
        margin-left: -16px;\
      }\
      .payment-schedule {\
        overflow-x: auto;\
      }\
    }'
  ;
  iframe.contentDocument.head.appendChild(style);

  setTimeout(() => {
    const heigth = iframe.contentWindow.document.body.scrollHeight;

    container.style.height = `${heigth}px`;
    iframe.height = `${heigth}px !important`;
  }, 4)

};

// NOTE: Used to dawnload snap agreement pdf, look this commit
export const downloadPdf = (state) => {
  state.setState({loading: true})
  const iframe = document.getElementById('contract-iframe')
  const iframeDocument = (iframe.contentDocument || iframe.contentWindow.document).body;

  iframeDocument.style.overflow="visible !important";
  iframeDocument.style.height="auto !important";
  iframeDocument.style.maxHeight="auto !important";

  html2canvas(iframeDocument).then(canvas => {
    try {
      var img = canvas.toDataURL("image/png", 1.0);
      let $w, $actw, $h, $acth, $maxw, $maxh, $count
      $w = $actw = canvas.width;
      $h = $acth = canvas.height;
      var pdf = new jsPDF("p", "pt", "a4");
      var width = $maxw = pdf.internal.pageSize.width;
      var height = $maxh = pdf.internal.pageSize.height;

      if ($w > $maxw) {
        $w = $maxw;
        $h = Math.round($acth / $actw * $maxw);
        if (state.props.form == 'snap_contract_form'){
          $h += 637;
        }
      }
      pdf.addImage(img, 'JPEG', 0, 0, $w, $h);
      $count = Math.ceil($h / $maxh) - 1;
      for (var i = 1; i <= $count; i++) {
        let position = - $maxh * i;
        pdf.addPage();
        pdf.addImage(img, 'JPEG', 0, position, $w, $h);
      }
      pdf.save("contract.pdf");
      state.setState({loading: false})
    } catch (e) {
      alert("Error description: " + e.message);
    }
  });
}
