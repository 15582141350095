const BLACKBOX_JS_SCRIPT_URL = process.env.BLACKBOX_JS_SCRIPT_URL
var events = require('events');
var eventEmitter = new events.EventEmitter();
var blackboxId = ''
export function runIovationScript() {
  return new Promise((resolve, reject) => {
    eventEmitter.on('blackboxCaptured', () => resolve(blackboxId));
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = BLACKBOX_JS_SCRIPT_URL;
    scriptElement.addEventListener('error', () => reject('Error loading script.'));
    scriptElement.addEventListener('abort', () => reject('Error loading aborted.'));
    document.body.appendChild(scriptElement);
  })
}
window.io_global_object_name = "IGLOO";
window.IGLOO = window.IGLOO || {
  "loader" : {
    "uri_hook" : process.env.BLACKBOX_JS_URL_HOOK,
    "subkey"  : "773e437e-3ab1",
    "version" : "general5",
  },
  // "trace_handler" can be used to debug iovation scripts
  // "trace_handler": function ( msg ) {
  //   console.log(msg);
  // },
  "bb_callback": function (bbString, isCompleted) {
    if (isCompleted) {
      blackboxId = bbString;
      // once we have the blackbox string, we need to use it in the Uown API, the latest version is posted above >>>>>>
      // for URI hook use sandbox/prod api url /iojs/ for the reverse proxy.  >>>>>>>>
      eventEmitter.emit('blackboxCaptured');
    }
  },
};
