import React, { Component } from "react";
import './styles.scss';
import Logo from 'assets/img/PayPair_Logo.png';

class ModalHeader extends Component {
  render() {
    return <div className="flex flex--column flex__align--center multistep-modal-header">
      <div className="multistep-modal-header__logo test">
        <img src={Logo} alt="tireagent logo" />
      </div>
      {/* <h1 className="multistep-modal-header__title">Shop Now, Pay Over Time</h1> */}
    </div>
  }
}

export default ModalHeader;
