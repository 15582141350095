import React, { Component } from "react";
import { setSnapSessionId } from "../../actions";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

class SnapSessionId extends Component {

  state = {
    snapSessionId: null
  }

  componentDidMount = () => {
    const snapSessionId = this.generateTmxId();
    this.appendTmxId(snapSessionId);
  }

  generateTmxId = () => {
    let rID = '', i, random = '';
    for (i = 0; i < 32; i++) {
        random = (Math.random() * 16) | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            rID += '-';
        }
        rID += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
    }
    return rID;
  }
  
  appendTmxId = (snapSessionId) => {
    const { setSnapSessionId } = this.props;

    this.setState({
      snapSessionId: snapSessionId
    })

    setSnapSessionId(snapSessionId);
  }


  render() {
    const { snapSessionId } = this.state

    return (
      <Helmet>
        <script>
          {`
              var snapSessionId = '${snapSessionId}';
              var tag = document.createElement('script');
              tag.src = 'https://content21.snapfinance.com/fp/tags.js?js=1&org_id=dbyn0zaq&session_id='+snapSessionId+'&pageid=4321';

              tag.setAttribute('nonce', 'CSP_NONCE');
              document.getElementsByTagName('head')[0].appendChild(tag);
          `}
        </script>
      </Helmet>
    )
  }
}

const mapDistpatchToProps = (dispatch) => {
  return {
    setSnapSessionId: (value) => dispatch(setSnapSessionId(value))
  }
}

SnapSessionId = connect(null, mapDistpatchToProps)(SnapSessionId)

export default SnapSessionId;
