import React, { Component } from "react";
import { connect } from "react-redux";
import { BlueBtn } from "components/Controls";
import {
  loadingSelector,
  activeStepDataSelector,
} from "containers/MultistepForm/selectors";
import { backToOffers } from "containers/MultistepForm/actions";
import ProgressiveLogo from "../../../../../assets/img/progressive-logo.png";
import { logEvent } from "../../../../EmbeddableWidget/actions";

class DeniedAppScreen extends Component {
  handleBackButton = () => {
    const { backToOffers, logEvent } = this.props;
    backToOffers();
    logEvent({ name: "view other options" });
  };
  render() {
    const { loading, offer } = this.props;

    return (
      <div className="expanded-block-component bank-data-form-container">
        <div className="bank-data-form-header">
          <img src={ProgressiveLogo} alt="Progressive Logo" />
          <h1>Your Application could not be approved</h1>
          {offer.lease_id && (
            <div className="gray-highlight">
              <h2>
                Lease ID: <span className="font-bold">{offer.lease_id}</span>
              </h2>
            </div>
          )}
        </div>
        <p>
          We're sorry, we were unable to approve your lease at this time. We'll
          send you a notice with additional information within 30 days.
        </p>
        <BlueBtn
          customClass="bank-data-form-submit-btn"
          loading={loading}
          onClick={this.handleBackButton}
        >
          Return to Checkout
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: loadingSelector()(state),
  offer: activeStepDataSelector()(state),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    backToOffers: () => dispatch(backToOffers()),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

DeniedAppScreen = connect(
  mapStateToProps,
  mapDistpatchToProps
)(DeniedAppScreen);

export default DeniedAppScreen;
