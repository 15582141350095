
import { getForms } from './forms';
import {
  ID_TYPE,
  ID_STATE,
  AGREEMENT,
  PAYOUT_FREQUENCY,
  ADDITIONAL_INFO_STEP__FEILDS
} from './collections';

const breadcrumbMap = [
  { key: "Application Details", number: 1 },
  { key: "Approval Decision", number: 2 },
  { key: "Select Payment Plan Option", number: 3 }
];

export {
  getForms,
  breadcrumbMap,
  ID_TYPE,
  ID_STATE,
  AGREEMENT,
  PAYOUT_FREQUENCY,
  ADDITIONAL_INFO_STEP__FEILDS
};


