import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectedOfferSelector } from '../../selectors';
import { finishApplication } from "../../actions";

import "./styles.scss";

class KoalafiContract extends Component {

  componentDidMount() {
    const { selectedOffer, appRequest, finishApplication } = this.props
    koalafiSdk.openModal(selectedOffer.order_id)
      .then((res) => {
        finishApplication();
      })
      .catch((err) => {
        console.log(err);
      });

    const koalafiIframe = document.getElementById('koalafi-iframe')
    const container = document.getElementById('iframe-container')
    container.appendChild(koalafiIframe)
    document.getElementById('koalafi-modal').remove()
  }

  render() {
    const { hidden } = this.props
    return (
      <div style={{display: hidden ? 'none' : 'inherit'}}>
        <div id="iframe-container"></div>
      </div>
    )
  }
}

KoalafiContract.propTypes = {
  contract: PropTypes.string
};

KoalafiContract.defaultProps = {
  contract: ""
};

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: (data) => dispatch(finishApplication(data))
  };
};

KoalafiContract = connect(mapStateToProps, mapDistpatchToProps)(KoalafiContract);

export default KoalafiContract;
