import React from "react";
import classNames from "classnames/bind";
import OfferItem from '../OfferItem';
import "./styles.scss";

const OfferBlock = ({ row, className=''}) => {
  const blockWidth = row.length === 1 ? 100 : 50;
  return (
    <div className={classNames(`offer-block-wrapper offer-block offer-block--100 flex ${className}`)}>
      {row.map((item, i) => (
        <div  
          key={i}
          className={classNames(`offer-block offer-block--${blockWidth}`)}>
          <OfferItem
            {...item}
          />
        </div>
      ))}
    </div>
  );
};

export default OfferBlock;
