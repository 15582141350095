import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

class SortBtn extends Component {
  constructor(props) {
    super(props);
  }

  clickMe = () => {
    this.props.clickMe()
  }

  render() {
    const { activate, asc, customClass, ...props } = this.props

    return (
      <button
        type="button"
        className={classNames(
          `${activate ? 'step-sorting-button is-active' : 'step-sorting-button'}`
        )}
        onClick={() => this.clickMe()}
        {...props}
      >
        {props.children}
      </button>
    )
  }
}

SortBtn.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  asc: PropTypes.bool,
};

export default SortBtn;
