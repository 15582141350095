import React, { Component } from "react";
import { LogoView } from "components/DataDisplay";
import { BlueBtn, IconBtn } from "components/Controls";
import "./styles.scss";
import ProviderHaveNotApprovedStep from "../ProviderHaveNotApprovedStep";

import { userInfoSelector, affiliatedProviderSelector } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { closeModal } from "../../MultistepModal/actions";
import {AnalyticAPI} from '../../../api/analytic';
import {OFFER_ORDER, UOWN_FIRST_OFFER_ORDER} from '../Step3/constants';
import {logEvent} from '../../EmbeddableWidget/actions';

class NobodyApprovedStep extends Component {
  componentDidMount() {
    const {logEvent} = this.props;
    logEvent({name: 'declined'})
  }

  render() {
    const { userInfo, closeModal, providerName } = this.props;

    if (providerName !== 'anybody'){
      return <ProviderHaveNotApprovedStep></ProviderHaveNotApprovedStep>
    }else {
      return (
          <div className="nobody-approved-step flex flex--column flex__align--center ">
            <span className="subtitle font-center">Hi, {userInfo.firstName}!</span>

            <span className="nobody-approved-step__text">
              Your application was not approved by some of the payment providers,
              but don’t worry - we have another option!
            </span>
            <div className="nobody-approved-step__option-wrapper">
              <div className="grid-block">
                  <LogoView provider="affirm"/>
                  <div className="nobody-approved-step__option-info">
                    <div className="item-bold-text">Pay over 3, 6, or 12 months</div>
                    <div className="list-item">
                      Rates from 10% to 30% APR
                    </div>
                    <div className="list-item">
                      Provide some basic information and get a real-time credit decision to split your purchase into monthly payments
                    </div>
                  </div>
              </div>
              <BlueBtn onClick={closeModal}> Apply to Affirm </BlueBtn>
            </div>
            <IconBtn customClass="nobody-approved-step__back-btn" onClick={closeModal}>
              <span className="left-arrow-icon">❮</span>
              Back to Payment Options
            </IconBtn>
          </div>
      );
    }
  }
}

const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector(),
  providerName: affiliatedProviderSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
    logEvent: (value) => dispatch(logEvent(value)),
  };
};

NobodyApprovedStep = connect(mapStateToProps, mapDistpatchToProps)(NobodyApprovedStep);

export default NobodyApprovedStep;
