export const PAYOUT_PERIOD = [
  { value: "6 months", id: "6 months" },
  { value: "12 Months", id: "12 Months" },
  { value: "24 Months", id: "24 Months" },
];

export const SECTIONS = {
  RECOMMENDED: {
    TITLE: "RECOMMENDED",
    TOOLTIP_TITLE: "Why we recommend this for you",
    TOOLTIP_TEXT: "This option covers the cost of your entire checkout total and is tailored to suit your payment schedule."
  },
  LOW_INITIAL_PAYMENT: {
    TITLE: "LOW INITIAL PAYMENT",
    TOOLTIP_TITLE: "Learn More",
    TOOLTIP_TEXT: "This option covers the cost of your entire checkout total and is tailored to suit your payment schedule."
  },
  UNAVAILABLE: {
    TITLE: "",
    TOOLTIP_TITLE: "",
    TOOLTIP_TEXT: ""
  }
}

export const EXCLUSIVE_BENEFIT = {
  paytomorrow: 'Lowest Interest Rate',
  acima: 'Builds Credit',
  flexshopper: '$0 Down',
}

export const OFFER_ORDER = ['bread', 'koalafi', 'ownlease', 'paytomorrow', 'aff', 'snap', 'katapult', 'kornerstone', 'uown', 'progressive'];
export const UOWN_FIRST_OFFER_ORDER = ['uown', 'paytomorrow', 'ownlease', 'aff', 'koalafi', 'progressive'];
