import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import { CustomTooltip } from "components/DataDisplay";
import classNames from "classnames/bind";

const BuyoutInfo = ({ text, title, titleTooltip, footnote }) => {
  const renderTitle = () => {
    if (titleTooltip) {
      return (
        <CustomTooltip
          title={title}
          titleBold={true}
          text={titleTooltip}
        />
      )
    } else {
      return (<h2 className="title">{title}</h2>)
    }
  }

  return (
    <div className="payment-option flex flex--column flex__align--center">
      {/* <h2 
       className={classNames(`title`,{
        'title--tooltip': titleTooltip
      })}>
      >{title}</h2> */}
      {renderTitle()}
      <div className="payment-option-text font-center">{text}</div>
      {footnote && <div className="footnote">{footnote}</div>}
    </div>
  );
};

BuyoutInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  footnote: PropTypes.string
};

BuyoutInfo.defaultProps = {
  title: `90-day* Payment Option`,
  text: `This option lets you purchase your merchandise for the cash price +
    the initial payment and a small buyout fee (varies state-to-state, but
    is generally $10).`,
  footnote: ''
};

export default BuyoutInfo;
