import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { IconCheck } from "components/Svgs";
import "./styles.scss";

const Breadcrumb = ({ data, activeBreadcrumb }) => {
  let passed = true;
  return (
    <div className="breadcrumb-wrapper flex flex__align--center">
      {data.map(function (item, index) {
        if (item.key === activeBreadcrumb) {
          passed = false;
        }

        return (

          <div
            key={item.key}
            className={classNames("breadcrumb-item", {
              passed: passed,
              renderLine: index !== 0,
              active: item.key === activeBreadcrumb
            })}
          >
            <div className="breadcrumb-line">
              <div className="breadcrumb-step">
                <div className="breadcrumb-step__number">{item.number}</div>
                <div className="breadcrumb-step__icon">
                  <IconCheck className="svg-icon" />
                </div>
              </div>
            </div>
            <div className="breadcrumb-text">{item.key}</div>
          </div>
        )
      })}
    </div>
  );
};

Breadcrumb.propTypes = {
  data: PropTypes.array,
  activeBreadcrumb: PropTypes.string
}

Breadcrumb.defaultPropTypes = {
  data: [],
}

export default Breadcrumb;
