import React, { Component } from "react";
import { BlueBtn } from "components/Controls";
import { RadioField } from "components/Fields";
import { selectedOfferSelector, loadingSelector } from "../selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { selectOffer } from "containers/MultistepForm/actions";
import { required } from "utils/validations";

import "./styles.scss";

class VerificationQuestionsStep extends Component {

  renderOptionsField = ({ input, meta, label, options }) => {
    options = options.map(option => {
      return { value: option.label, key: option.id };
    })

    return (<RadioField
      {...input}
      meta={meta}
      options={options}
      label={label}
    />);
  }

  submitForm = (values) => {
    const { selectedOffer } = this.props
    const answers = Object.keys(values).map(key => {
      return {
        questionId: key.replace(/^question_/, ""),
        answerId: values[key]
      }
    })

    const { selectOffer } = this.props;
    selectOffer({
      body :{
        answers: {
          id: selectedOffer.data.id,
          responses: answers
        }
      }
    })
  }

  render() { 
    const { selectedOffer, handleSubmit, valid, loading } = this.props

    return (
      <form className="paypair-step-content verification-questions-step"  onSubmit={handleSubmit(this.submitForm)}>
        <div className="paypair-form paypair-form-padding">
          <p>FlexShopper requires an extra verification step. Please answer the questions below.</p>

          {selectedOffer.data.questions.map((question, index) => {
            return (
              <div className="form-item" key={index}>
                <Field
                 validate={[required]}
                  name={'question_'+question.id}
                  label={question.label}
                  options={question.choices}
                  component={this.renderOptionsField}
                />
              </div>
            )
          })}
          <div className="submit-item">

            <BlueBtn 
              loading={loading} 
              disabled={!valid} 
              type="submit">
              Continue
            </BlueBtn>
          </div>
        </div>
      </form>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector(),
  loading: loadingSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};
VerificationQuestionsStep = connect(mapStateToProps, mapDistpatchToProps)(VerificationQuestionsStep);

VerificationQuestionsStep = reduxForm({
  form: "verification_question_form",
  destroyOnUnmount: true,
})(VerificationQuestionsStep);

export default VerificationQuestionsStep;

