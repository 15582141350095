import CONSTANTS from 'utils/constants';
import {fetch as fetchPolyfill} from 'whatwg-fetch';

const timeoutMilliseconds = 90000;

const post = (url, method, token, data = {}) => {
  return timeoutPromise(timeoutMilliseconds, fetchPolyfill(`${CONSTANTS.API_URL}/${url}`, {
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }, body: JSON.stringify(data)
  }).then(data => data.json()))
}

const get = (url, method, token, queryString) => {
  const urlWithParams = new URL(`${CONSTANTS.API_URL}/${url}`);
  urlWithParams.search = new URLSearchParams(queryString).toString();

  return timeoutPromise(timeoutMilliseconds, fetchPolyfill(urlWithParams, {
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    searchParams: queryString,
  }).then(data => data.json()))
}

const timeoutPromise = (ms, promise) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'))
    }, ms)

    promise
      .then(value => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch(reason => {
        clearTimeout(timer)
        reject(reason)
      })
  })
}

export const call = (url, method, token, data = {}) => {
  if(method === 'POST' || method === 'PATCH') {
    return post(url, method, token, data)
  } else {
    return get(url, method, token, data)
  }
}
